import { NavItemBlokProps } from '../components/NxPage/NxMenu';
import { PowerProTypes } from '../components/NxPage/NxPage';

/*
Please don't change the names of these two navigation links in global_navi in storyblok or the consequences will be catastrophic  
*/

export const DESIGN_MY_SYSTEM_TEXT = 'Design My System With PowerPro';
export const LEARNING_CENTER_TEXT = 'Learning Center';

const isProd =
  typeof window !== 'undefined' &&
  window.location.origin.indexOf('swellenergy.com') > -1 &&
  window.location.origin.indexOf('sbx') === -1;

export const getPowerProFields = (sbxFieldName, prodFieldName) =>
  isProd ? prodFieldName : sbxFieldName;

interface Props extends PowerProTypes {
  navItems: NavItemBlokProps[];
}

export const navItemsWithPowerProItems = (props: Props) => {
  const {
    show_design_my_system,
    design_my_system_url,
    sbx_show_design_my_system,
    sbx_design_my_system_url,
    show_learning_center,
    learning_center_url,
    sbx_show_learning_center,
    sbx_learning_center_url,
    navItems,
  } = props;

  let _navItems = [...navItems];
  _navItems = _navItems.filter((navItem) => {
    if (navItem.component === 'nav_item') return Boolean(navItem.enabled); // only returning enabled nav_items
    return navItem; // this is spacer
  });

  _navItems = _navItems.map((navItem) => ({
    ...navItem,
    link: getPowerProFields(navItem.sbx_link, navItem.link),
  }));

  if (getPowerProFields(sbx_show_design_my_system, show_design_my_system)) {
    _navItems = _navItems.map((nav) =>
      nav.name === DESIGN_MY_SYSTEM_TEXT
        ? {
            ...nav,
            link: getPowerProFields(sbx_design_my_system_url, design_my_system_url) ?? nav.link,
          }
        : nav
    );
  }

  if (getPowerProFields(sbx_show_learning_center, show_learning_center)) {
    _navItems = _navItems.map((nav) =>
      nav.name === LEARNING_CENTER_TEXT
        ? {
            ...nav,
            link: getPowerProFields(sbx_learning_center_url, learning_center_url) ?? nav.link,
          }
        : nav
    );
  }

  return _navItems;
};

export const getFooterPowerProAndLearningCenter = (
  props: Props,
  navItemName: typeof DESIGN_MY_SYSTEM_TEXT | typeof LEARNING_CENTER_TEXT
) => {
  const {
    show_design_my_system,
    design_my_system_url,
    sbx_show_design_my_system,
    sbx_design_my_system_url,
    show_learning_center,
    learning_center_url,
    sbx_show_learning_center,
    sbx_learning_center_url,
    navItems,
  } = props;
  const _navItems = [...navItems];

  let field = _navItems.find((navItem) => navItem.name === navItemName);
  if (!field?.enabled) return false;

  if (navItemName.trim() === DESIGN_MY_SYSTEM_TEXT) {
    if (getPowerProFields(sbx_show_design_my_system, show_design_my_system)) {
      field = {
        ...field,
        link: getPowerProFields(sbx_design_my_system_url, design_my_system_url) ?? field.link,
      };
    } else {
      field = {
        ...field,
        link: getPowerProFields(field.sbx_link, field.link) ?? field.link,
      };
    }
  }

  if (navItemName.trim() === LEARNING_CENTER_TEXT) {
    if (getPowerProFields(sbx_show_learning_center, show_learning_center)) {
      field = {
        ...field,
        link: getPowerProFields(sbx_learning_center_url, learning_center_url) ?? field.link,
      };
    } else {
      field = {
        ...field,
        link: getPowerProFields(field.sbx_link, field.link) ?? field.link,
      };
    }
  }

  return field;
};
