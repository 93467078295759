import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import tw, { css, styled } from 'twin.macro';

import { ElementHorizontalPosition, ElementHorizontalPositionMap } from '../NxCommon';

export interface ImageLinkProps {
  horizontalAlign: ElementHorizontalPosition;
  width: string;
}

const ImageLinkStyles = (props: ImageLinkProps) => [
  tw`block`,
  ElementHorizontalPositionMap[props.horizontalAlign],
  props.width
    ? css`
        width: ${props.width};
      `
    : '',
];

const InternalLink = styled(Link)<ImageLinkProps>((props) => ImageLinkStyles(props));
const ExternalLink = styled(OutboundLink)<ImageLinkProps>((props) => ImageLinkStyles(props));

export { ImageLinkStyles, InternalLink, ExternalLink };
