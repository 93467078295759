import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import tw, { css, styled } from 'twin.macro';

import {
  ButtonSize,
  ButtonTheme,
  ElementHorizontalPosition,
  ElementHorizontalPositionMap,
  ElementVerticalPosition,
  ElementVerticalPositionMap,
} from '../NxCommon';

const NormalStyle = tw`py-4 px-4`;
const SmallerStyle = tw`py-2 px-2 text-xs`;

const BlueTheme = tw`
  bg-swell-blue text-white border-2 border-solid border-swell-blue
  hover:bg-swell-gray hover:text-gray-100 hover:border-swell-gray hover:no-underline
`;

const WhiteTheme = tw`
  bg-white  text-swell-blue border-2 border-solid border-swell-blue
  hover:bg-gray-100 hover:text-swell-gray hover:border-swell-gray hover:no-underline
`;

export interface ButtonProps {
  theme: ButtonTheme;
  size: ButtonSize;
  horizontalAlign: ElementHorizontalPosition;
  veritcalAlign?: ElementVerticalPosition;
  width: string;
  disabled?: boolean;
  fontColor?: string;
  disabledFillColor?: string;
  baseFillColor?: string;
  disabledFontColor?: string;
}

const ButtonStyles = (props: ButtonProps) => [
  tw`rounded-full flex text-center justify-center font-sans`,
  props.baseFillColor
    ? css`
        background-color: ${props.baseFillColor};
      `
    : tw` bg-swell-blue`,
  props.fontColor
    ? css`
        color: ${props.fontColor};
      `
    : tw`text-white`,
  props.size === 'normal' ? NormalStyle : '',
  props.size === 'smaller' ? SmallerStyle : '',
  props.theme === 'blue' && !props.disabled ? BlueTheme : '',
  props.theme === 'white' && !props.disabled ? WhiteTheme : '',
  props.disabled ? tw` border-gray-200  border-2 border-solid cursor-not-allowed` : '',
  props.disabled
    ? props.disabledFillColor
      ? css`
          background-color: ${props.disabledFillColor};
        `
      : tw`bg-gray-200`
    : '',
  props.disabled
    ? props.disabledFontColor
      ? css`
          color: ${props.disabledFontColor};
        `
      : tw`text-gray-500`
    : '',
  props.width
    ? css`
        width: ${props.width};
      `
    : '',
  ElementHorizontalPositionMap[props.horizontalAlign],
  props.veritcalAlign ? ElementVerticalPositionMap[props.veritcalAlign] : '',
];

const InternalButtonContainer = styled(Link)<ButtonProps>((props) => ButtonStyles(props));
const ExternalButtonContainer = styled(OutboundLink)<ButtonProps>((props) => ButtonStyles(props));

export { ButtonStyles, InternalButtonContainer, ExternalButtonContainer };
