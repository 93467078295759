import React from 'react';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';

interface BlokProps extends BaseBlok {
  name: string;
}

export default function NxScrollTarget(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { name } = props.blok;
  return <a id={name} />; // eslint-disable-line jsx-a11y/anchor-has-content
}
