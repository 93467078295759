import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import SbEditable from 'storyblok-react';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentForBlok } from '../NxComponents';
import { QueryResult } from '../NxGraphQL';
import { StoryBlokLink } from '../StoryBlokLink';

import NxFooter from './NxFooter';
import { NavItemBlokProps } from './NxMenu';
import NxNavBar from './NxNavBar';
import NxStyles from './NxStyles';
import { Page, PageContainer } from './styles';

export interface PowerProTypes {
  design_my_system_url: StoryBlokLink;
  show_design_my_system: boolean;
  learning_center_url: StoryBlokLink;
  show_learning_center: boolean;
  sbx_design_my_system_url: StoryBlokLink;
  sbx_show_design_my_system: boolean;
  sbx_learning_center_url: StoryBlokLink;
  sbx_show_learning_center: boolean;
}

interface MenuItemJSON {
  content: string;
}
interface BlokProps extends BaseBlok, PowerProTypes {
  title: string;
  body: BaseBlok[];
  phone_number?: string;
  meta_description?: string;
  nav_bar_color: 'white' | 'black';
  nav_bar_treatment: boolean;
}

type Props = NxBaseProps<BlokProps> & { data: QueryResult<MenuItemJSON> };

const query = graphql`
  {
    allStoryblokEntry(filter: { slug: { eq: "global-navi" } }) {
      edges {
        node {
          content
        }
      }
    }
  }
`;

function NxPage(props: Props) {
  const {
    body,
    title,
    phone_number,
    meta_description,
    nav_bar_color,
    nav_bar_treatment,
    design_my_system_url,
    show_design_my_system,
    learning_center_url,
    show_learning_center,
    sbx_design_my_system_url,
    sbx_show_design_my_system,
    sbx_learning_center_url,
    sbx_show_learning_center,
  } = props.blok;
  const globalNaviContent = JSON.parse(props.data.allStoryblokEntry.edges[0].node.content);
  const navItems: NavItemBlokProps[] = globalNaviContent.nav_items;

  return (
    <SbEditable content={props.blok}>
      <Helmet>
        {props.blok.title && <title>{title} - Swell Energy</title>}
        {props.blok.title && <meta property="og:title" content={`${title} - Swell Energy`} />}
        {props.blok.title && <meta name="twitter:title" content={`${title} - Swell Energy`} />}
        {props.blok.meta_description && <meta name="description" content={meta_description} />}
      </Helmet>
      <NxStyles />
      <PageContainer>
        <NxNavBar
          phoneNumber={phone_number || '8884651784'}
          color={nav_bar_color}
          includeGradientTreatment={nav_bar_treatment}
          powerProData={{
            design_my_system_url,
            show_design_my_system,
            learning_center_url,
            show_learning_center,
            sbx_design_my_system_url,
            sbx_show_design_my_system,
            sbx_learning_center_url,
            sbx_show_learning_center,
          }}
        />
        <Page>{body && body.map((item) => getComponentForBlok(item))}</Page>
        <NxFooter {...props.blok} navItems={navItems} />
      </PageContainer>
    </SbEditable>
  );
}

// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <StaticQuery query={query} render={(data) => <NxPage data={data} {...props} />} />
);
