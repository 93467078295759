import React, { useContext, useMemo } from 'react';
import SbEditable from 'storyblok-react';

import { useLeadForm } from '../../hooks/useLeadForm';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { TextAlign } from '../NxCommon';
import { PartnerPersonalizationContext } from '../NxPartnerPersonalizedContent/PartnerPersonalizationContext';
import { StoryBlokColor } from '../StoryBlokColor';

import { NxRichTextLineHeight } from './NxRichTextLineHeight';
import { FontSizeSet, RichTextContainer } from './RichTextContainer';

export interface BlokProps extends BaseBlok {
  content: string;
  base_text_color: StoryBlokColor;
  strong_text_color: StoryBlokColor;
  line_height: NxRichTextLineHeight;
  text_align: TextAlign;
  font_size_set: FontSizeSet;
}

export default function NxRichText(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { content, base_text_color, strong_text_color, line_height, text_align, font_size_set } =
    props.blok;
  const { partnerName } = useContext(PartnerPersonalizationContext);
  const personalizedContent = useMemo(
    () => content.replace(/%PARTNER_NAME%/g, partnerName),
    [content, partnerName]
  );

  const {
    pageStyles: { primaryColor },
  } = useLeadForm();

  return (
    <SbEditable content={props.blok}>
      <RichTextContainer
        baseTextColor={base_text_color}
        strongTextColor={strong_text_color}
        fontSizeSet={font_size_set}
        lineHeight={line_height}
        textAlign={text_align}
        primaryColor={primaryColor}
        linkTarget={(link: string) => (link.includes('#') ? '' : '_blank')}
      >
        {personalizedContent}
      </RichTextContainer>
    </SbEditable>
  );
}
