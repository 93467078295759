import React from 'react';
import SbEditable from 'storyblok-react';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentForBlok } from '../NxComponents';

import Column from './Column';

interface BlokProps extends BaseBlok {
  body: BaseBlok[];
  width?: string;
  width_sm?: string;
  width_md?: string;
  width_lg?: string;
  width_xl?: string;
  padding_x?: number;
  padding_y?: number;
}

export default function NxColumn(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { body, ...styleProps } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Column {...styleProps}>{body && body.map((item) => getComponentForBlok(item))}</Column>
    </SbEditable>
  );
}
