/* eslint-disable react/no-unknown-property */
import * as Sentry from '@sentry/react';
import { Formik, Form } from 'formik';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';
import * as yup from 'yup';

import { leads, metrics } from '../../services';
import { useEnvironment } from '../EnvironmentContext';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ButtonStyles } from '../NxButtonLink/ButtonContainer';
import { ErrorMessage, SuccessMessage } from '../NxLeadFormComponents/Common';
import NxLeadFormCheckbox from '../NxLeadFormComponents/NxLeadFormCheckbox';
import NxLeadFormInput from '../NxLeadFormComponents/NxLeadFormInput';
import NxLeadFormSelectMulti from '../NxLeadFormComponents/NxLeadFormSelectMulti';
import NxLeadFormTextArea from '../NxLeadFormComponents/NxLeadFormTextArea';

import { FormData, InitialValues, ServiceAreaOptions, ValidationShape } from './LeadFormTypes';

const SubmitButton = styled.button((props) =>
  ButtonStyles({
    theme: 'blue',
    size: 'normal',
    horizontalAlign: 'center',
    width: '280px',
    disabled: props.disabled,
  })
);

const Container = tw.div`w-full`;
const StyledForm = styled(Form)(() => [tw`flex flex-col space-y-6`]);
const GroupHeader = tw.span`text-base text-swell-gray font-bold font-sans`;
const Muted = tw.span`font-normal opacity-50`;
const InputRow = tw.div`flex flex-col w-full md:flex-row`;
const Interests = tw.div`ml-2 flex flex-row flex-wrap`;
const InterestsColumn = tw.div`flex flex-col w-full lg:w-1/2 space-y-4 mt-4`;
const CheckboxLabelText = tw.span`ml-2 flex-grow text-xs leading-snug font-sans`;
const AgreeText = tw.span`ml-2 text-xs leading-snug`;
const AgreeNDALink = tw.a`text-swell-blue`;
const DefaultSuccessMessage = 'Thank you, we will be in touch soon.';

interface BlokProps extends BaseBlok {
  hide_interests: boolean;
  hide_mutual_nda: boolean;
  partnership_lead_source_account: string;
  success_message: string;
  content_id?: string;
  lead_form_type: 'partner' | 'commercial';
}

export default function NxPartnerLeadForm(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { environment } = useEnvironment();
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'failed' | 'successful'>('idle');
  const {
    hide_interests,
    hide_mutual_nda,
    partnership_lead_source_account,
    success_message,
    content_id,
    lead_form_type,
  } = props.blok;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ...ValidationShape,
        acceptTerms: hide_mutual_nda
          ? undefined
          : yup
              .bool()
              .required('Please read and agree to the Mutual NDA')
              .oneOf([true], 'Please read and agree to the Mutual NDA'),
      }),
    [hide_mutual_nda]
  );

  const submitLead = async (values: FormData) => {
    setSubmitStatus('idle');

    try {
      let referred_by = '';

      if (typeof window !== undefined) {
        const parsed = queryString.parse(window.location.search);

        if (typeof parsed.partnerId === 'string') {
          referred_by = parsed.partnerId;
        }
      }

      const partnerIdObj = partnership_lead_source_account || '';
      let partner_id = '';

      if (partnerIdObj) {
        try {
          partner_id = JSON.parse(partnerIdObj)[environment];
        } catch (err) {
          Sentry.captureException(err);
        }
      }
      if (lead_form_type === 'commercial') {
        await leads.submitLead({
          company: values.company,
          address: values.address,
          zip: values.zip,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          notes: `message:${values.description} \n service_area:${(values.serviceArea || '').split(
            ','
          )} \n interests:${values.interests ?? ''}`,
          customerType: 'commercial',
        });
      } else {
        await leads.submitPartner({
          ...values,
          service_area: (values.serviceArea || '').split(','),
          referred_by,
          partner_id,
          utility_type: null,
          content_id,
          type: 'partner',
        });
      }
      metrics.trackPartnerFormSubmit(
        window.location.origin + window.location.pathname,
        'NxPartnerLeadForm'
      );

      setSubmitStatus('successful');
    } catch (err) {
      Sentry.captureException(err);
      setSubmitStatus('failed');
    }
  };

  return (
    <SbEditable content={props.blok}>
      <Container>
        {submitStatus === 'successful' && (
          <SuccessMessage>{success_message || DefaultSuccessMessage}</SuccessMessage>
        )}
        {submitStatus !== 'successful' && (
          <>
            <Formik
              initialValues={InitialValues}
              validationSchema={validationSchema}
              onSubmit={submitLead}
            >
              {(formik) => (
                <StyledForm noValidate>
                  {submitStatus === 'failed' && (
                    <ErrorMessage>
                      We had a problem processing your request. Please try again.
                    </ErrorMessage>
                  )}
                  <div>
                    <GroupHeader>Company Info</GroupHeader>
                    <InputRow>
                      <NxLeadFormInput
                        id="company"
                        name="company"
                        formik={formik}
                        required
                        placeholder="Company name"
                      />
                      <NxLeadFormInput
                        id="website"
                        name="website"
                        formik={formik}
                        required
                        placeholder="Website"
                      />
                    </InputRow>
                    <InputRow>
                      <NxLeadFormInput
                        id="address"
                        name="address"
                        formik={formik}
                        required
                        placeholder="Street address"
                      />
                      <NxLeadFormInput
                        id="zip"
                        name="zip"
                        formik={formik}
                        required
                        placeholder="Zip code"
                        mask="99999"
                      />
                    </InputRow>
                    <InputRow>
                      <NxLeadFormSelectMulti
                        id="serviceArea"
                        name="serviceArea"
                        formik={formik}
                        required
                        label="Where does your company currently operate?"
                        placeholder="Please select one or more states"
                        options={ServiceAreaOptions}
                      />
                    </InputRow>
                  </div>
                  <div>
                    <GroupHeader>Primary Contact</GroupHeader>
                    <InputRow>
                      <NxLeadFormInput
                        id="firstName"
                        name="firstName"
                        formik={formik}
                        required
                        placeholder="First name"
                      />
                      <NxLeadFormInput
                        id="lastName"
                        name="lastName"
                        formik={formik}
                        required
                        placeholder="Last name"
                      />
                    </InputRow>
                    <InputRow>
                      <NxLeadFormInput
                        id="phone"
                        name="phone"
                        formik={formik}
                        required
                        placeholder="Phone"
                        mask="(999) 999-9999"
                        type="tel"
                      />
                      <NxLeadFormInput
                        id="email"
                        name="email"
                        formik={formik}
                        required
                        placeholder="Email"
                        type="email"
                      />
                    </InputRow>
                  </div>
                  {!hide_interests && (
                    <div>
                      <GroupHeader>
                        I&apos;m interested in: <Muted>(Check all that apply)</Muted>
                      </GroupHeader>
                      <Interests>
                        <InterestsColumn>
                          <NxLeadFormCheckbox
                            name="interests"
                            value="Selling Energy Storage"
                            formik={formik}
                            id="interests-offer-swell-services"
                          >
                            <CheckboxLabelText>
                              Offering Swell&apos;s energy storage and solar services to my
                              customers
                            </CheckboxLabelText>
                          </NxLeadFormCheckbox>
                          <NxLeadFormCheckbox
                            name="interests"
                            value="Partnering for Virtual Power Plants"
                            formik={formik}
                            id="interests-create-vpp"
                          >
                            <CheckboxLabelText>
                              Partnering with Swell to create Virtual Power Plants
                            </CheckboxLabelText>
                          </NxLeadFormCheckbox>
                          <NxLeadFormCheckbox
                            name="interests"
                            value="Providing Energy Storage"
                            formik={formik}
                            id="interests-provide-swell"
                          >
                            <CheckboxLabelText>
                              Providing additional products and services to Swell
                            </CheckboxLabelText>
                          </NxLeadFormCheckbox>
                        </InterestsColumn>
                        <InterestsColumn>
                          <NxLeadFormCheckbox
                            name="interests"
                            value="Installing Energy Storage"
                            formik={formik}
                            id="interests-installer"
                          >
                            <CheckboxLabelText>
                              Installing energy storage and solar projects for Swell
                            </CheckboxLabelText>
                          </NxLeadFormCheckbox>
                          <NxLeadFormCheckbox
                            name="interests"
                            value="Enrolling In GridRewards"
                            formik={formik}
                            id="interests-gridrewards"
                          >
                            <CheckboxLabelText>
                              Enrolling my customers in Swell&apos;s GridRewards Virtual Power Plant
                              Programs
                            </CheckboxLabelText>
                          </NxLeadFormCheckbox>
                          {lead_form_type === 'commercial' && (
                            <>
                              {' '}
                              <NxLeadFormCheckbox
                                name="interests"
                                value="Receiving a custom solar and/or storage proposal"
                                formik={formik}
                                id="interests-receiving-custom-proposal"
                              >
                                <CheckboxLabelText>
                                  Receiving a custom solar and/or storage proposal
                                </CheckboxLabelText>
                              </NxLeadFormCheckbox>
                              <NxLeadFormCheckbox
                                name="interests"
                                value="Speak with a Swell representative"
                                formik={formik}
                                id="interests-speak-with-representative"
                              >
                                <CheckboxLabelText>
                                  Speak with a Swell representative
                                </CheckboxLabelText>
                              </NxLeadFormCheckbox>
                            </>
                          )}
                          <NxLeadFormCheckbox
                            name="interests"
                            value="Other"
                            formik={formik}
                            id="interests-other"
                          >
                            <CheckboxLabelText>Other, please explain below</CheckboxLabelText>
                          </NxLeadFormCheckbox>
                        </InterestsColumn>
                      </Interests>
                    </div>
                  )}
                  <div>
                    <GroupHeader>Message</GroupHeader>
                    <NxLeadFormTextArea
                      id="description"
                      name="description"
                      formik={formik}
                      rows={4}
                    />
                  </div>
                  {!hide_mutual_nda && (
                    <>
                      <div tw="mx-auto">
                        <NxLeadFormCheckbox name="acceptTerms" id="acceptTerms" formik={formik}>
                          <AgreeText>
                            I have read and agree to the{' '}
                            <AgreeNDALink
                              href="https://a.storyblok.com/f/89720/x/ba62a39102/swell_mutual_nda.pdf"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Mutual Non-Disclosure Agreement
                            </AgreeNDALink>
                          </AgreeText>
                        </NxLeadFormCheckbox>
                      </div>
                      {formik.errors.acceptTerms && (
                        <div tw="mx-auto text-sm text-red-600">{formik.errors.acceptTerms}</div>
                      )}
                    </>
                  )}
                  <SubmitButton disabled={formik.isSubmitting} type="submit">
                    Submit
                  </SubmitButton>
                </StyledForm>
              )}
            </Formik>
          </>
        )}
      </Container>
    </SbEditable>
  );
}
