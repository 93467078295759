import * as Sentry from '@sentry/gatsby';
import React, { useCallback, useState } from 'react';
import SbEditable from 'storyblok-react';

import { leads, metrics } from '../../services';
import { useEnvironment } from '../EnvironmentContext';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ButtonTheme } from '../NxCommon';
import { getComponentsForBlokArray } from '../NxComponents';
import { ErrorMessage } from '../NxLeadFormComponents/Common';
import { StoryBlokAsset } from '../StoryBlokAsset';

import AgreementModal from './AgreementModal';
import LeadFormContent from './LeadFormContent';
import { FormData } from './LeadFormTypes';
import { Container } from './styles';

interface BlokProps extends BaseBlok {
  // General
  success_content: BaseBlok[];
  preview_success_content: boolean;

  // Style
  theme: 'dark' | 'light';
  button_theme: ButtonTheme;
  submit_button_text: string;
  submitting_button_text: string;

  // Campaign
  wave_partner_id: string;
  wave_install_partner_id: string;
  wave_utility_id?: string | undefined;
  wave_utility_program_id: string;
  sbx_wave_install_partner_id?: string;
  sbx_wave_partner_id?: string;
  sbx_wave_utility_id?: string;
  sbx_wave_utility_program_id?: string;

  // Analytics
  analytics_bing_event: boolean;
  analytics_fb_event: boolean;
  analytics_gtag_event: boolean;
  analytics_gtag_target: string;

  // VPP Easy Enroll
  vpp_ee_enable: boolean;
  vpp_ee_agreement?: StoryBlokAsset | undefined;
  vpp_ee_agreement_text: string;
  vpp_ee_template: string;
}

type SubmitStatus = 'idle' | 'submitting' | 'success' | 'error';

export default function NxWaveGSLeadForm(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { isInEditor, environment } = useEnvironment();
  const [status, setStatus] = useState('idle' as SubmitStatus);
  const [showTerms, setShowTerms] = useState(false);
  const [leadFormData, setLeadFormData] = useState<FormData>();
  const [waveOppId, setWaveOppId] = useState('');
  const {
    wave_partner_id,
    wave_install_partner_id,
    wave_utility_id,
    wave_utility_program_id,
    vpp_ee_enable,
    vpp_ee_agreement,
    vpp_ee_agreement_text,
    vpp_ee_template,
    analytics_bing_event,
    analytics_fb_event,
    analytics_gtag_event,
    analytics_gtag_target,
    sbx_wave_install_partner_id,
    sbx_wave_partner_id,
    sbx_wave_utility_id,
    sbx_wave_utility_program_id,
  } = props.blok;

  const isVPPEasyEnroll =
    vpp_ee_enable && !!vpp_ee_agreement?.id && !!vpp_ee_agreement_text && !!vpp_ee_template;

  const vpp_ee_agreement_filename = vpp_ee_agreement?.filename;

  const submitLead = useCallback(
    async (values: FormData) => {
      try {
        const firstName = values.name.substring(0, values.name.lastIndexOf(' '));
        const lastName = values.name.substring(values.name.lastIndexOf(' ') + 1);

        const { success, data } = await leads.submitWaveGSLead({
          ...values,
          firstName,
          lastName,
          salesPartnerId: environment === 'prod' ? wave_partner_id : sbx_wave_partner_id,
          installationPartnerId:
            environment === 'prod' ? wave_install_partner_id : sbx_wave_install_partner_id,
          utilityId: environment === 'prod' ? wave_utility_id : sbx_wave_utility_id,
          utilityProgramId:
            environment === 'prod' ? wave_utility_program_id : sbx_wave_utility_program_id,
          isVPPEasyEnroll,
          vppEasyEnrollContractPDF: vpp_ee_agreement_filename,
          vppEasyEnrollTemplate: vpp_ee_template,
          pageUrl: window.location.origin + window.location.pathname,
        });

        if (!success) {
          return { success: false };
        }

        metrics.trackLeadFormSubmit(
          null,
          'NxWaveGSLeadForm',
          environment === 'prod' ? wave_partner_id : sbx_wave_partner_id
        );

        if (analytics_bing_event) metrics.trackBingLeadEvent();
        if (analytics_fb_event) metrics.trackFacebookLeadEvent();
        if (analytics_gtag_event && analytics_gtag_target)
          metrics.trackGoogleTagConversion(analytics_gtag_target);

        return { success: true, id: data.id as string };
      } catch (err) {
        Sentry.captureException(err);
        return { success: false };
      }
    },
    [
      wave_partner_id,
      sbx_wave_partner_id,
      wave_install_partner_id,
      sbx_wave_install_partner_id,
      wave_utility_id,
      sbx_wave_utility_id,
      wave_utility_program_id,
      sbx_wave_utility_program_id,
      analytics_bing_event,
      analytics_fb_event,
      analytics_gtag_event,
      analytics_gtag_target,
      isVPPEasyEnroll,
      vpp_ee_agreement_filename,
      vpp_ee_template,
      environment,
    ]
  );

  const submitAccept = useCallback(
    async (id: string, values: FormData) => {
      try {
        const firstName = values.name.substring(0, values.name.lastIndexOf(' '));
        const lastName = values.name.substring(values.name.lastIndexOf(' ') + 1);

        const success = await leads.acceptWaveGSLeadVppTerms({
          id,
          ...values,
          firstName,
          lastName,
          utilityProgramId:
            environment === 'prod' ? wave_utility_program_id : sbx_wave_utility_program_id,
          vppEasyEnrollContractPDF: vpp_ee_agreement_filename,
          vppEasyEnrollTemplate: vpp_ee_template,
        });

        return success;
      } catch (err) {
        Sentry.captureException(err);
        return false;
      }
    },
    [
      wave_utility_program_id,
      vpp_ee_agreement_filename,
      vpp_ee_template,
      sbx_wave_utility_program_id,
      environment,
    ]
  );

  const handleSubmit = useCallback(
    async (values: FormData) => {
      setStatus('submitting');

      const { success, id } = await submitLead(values);

      if (isVPPEasyEnroll && id) {
        setStatus('idle');
        setWaveOppId(id);
        setLeadFormData(values);
        setShowTerms(true);
      } else {
        setStatus(success ? 'success' : 'error');
      }
    },
    [submitLead, isVPPEasyEnroll]
  );

  const handleTCAccept = useCallback(async () => {
    setShowTerms(false);
    setStatus('submitting');

    metrics.trackLeadFormAcceptTerms(
      'NxWaveGSLeadForm',
      environment === 'prod' ? wave_partner_id : sbx_wave_partner_id
    );
    const success = await submitAccept(waveOppId, leadFormData);
    setStatus(success ? 'success' : 'error');
  }, [submitAccept, leadFormData, waveOppId, wave_partner_id, sbx_wave_partner_id, environment]);

  const handleTCCancel = useCallback(() => {
    metrics.trackLeadFormRejectTerms(
      'NxWaveGSLeadForm',
      environment === 'prod' ? wave_partner_id : sbx_wave_partner_id
    );
    setShowTerms(false);
  }, [wave_partner_id, sbx_wave_partner_id, environment]);

  const buttonText =
    status === 'submitting' ? props.blok.submitting_button_text : props.blok.submit_button_text;
  const showSuccess = status === 'success' || (isInEditor && props.blok.preview_success_content);
  const showLeadForm = !showSuccess;

  return (
    <SbEditable content={props.blok}>
      <Container textColor={props.blok.theme}>
        {isInEditor && props.blok.vpp_ee_enable && !props.blok.vpp_ee_agreement?.id && (
          <ErrorMessage>
            EDITOR WARNING: VPP T&amp;C Agreement required when VPP Easy Enroll is enabled.
          </ErrorMessage>
        )}
        {isInEditor && props.blok.vpp_ee_enable && !props.blok.vpp_ee_template && (
          <ErrorMessage>
            EDITOR WARNING: VPP Email Template required when VPP Easy Enroll is enabled.
          </ErrorMessage>
        )}
        {showSuccess && <>{getComponentsForBlokArray(props.blok.success_content)}</>}
        {showLeadForm && (
          <LeadFormContent
            buttonTheme={props.blok.button_theme}
            buttonText={buttonText}
            handleSubmit={handleSubmit}
            isError={status === 'error'}
            isSubmitting={status === 'submitting'}
          />
        )}
        {showTerms && (
          <AgreementModal
            content={props.blok.vpp_ee_agreement_text}
            onAccept={handleTCAccept}
            onCancel={handleTCCancel}
          />
        )}
      </Container>
    </SbEditable>
  );
}
