import tw, { styled, css } from 'twin.macro';

import { BorderRounding, BorderRoundingMap, TextAlign, TextAlignMap } from '../NxCommon';

import { Padding, Width } from './PropTypes';

interface SectionStyledProps {
  width: Width;
  height: string;
  verticalPadding: Padding;
  horizontalPadding: Padding;
  textAlign?: TextAlign;
  borderEnable: boolean;
  borderColor: string;
  borderWidth: number;
  borderRounding: BorderRounding;
}

const getVerticalPadding = (padding: Padding) => {
  switch (padding) {
    case 'box':
      return tw`py-4`;
    case 'less':
      return tw`py-16`;
    case 'normal':
      return tw`py-24 md:py-32`;
  }
};

const getHorizontalPadding = (padding: Padding) => {
  switch (padding) {
    case 'less':
      return tw`px-2`;
    case 'normal':
      return tw`px-4`;
  }
};

const getWidth = (width: Width) => {
  switch (width) {
    case 'fixed':
      return [tw`mx-auto container`, tw`px-4 md:px-8 lg:px-16 xl:px-28 2xl:px-48`];
  }

  return [];
};

const getBorder = (props: SectionStyledProps) => {
  if (!props.borderEnable) {
    return [];
  }

  return [
    tw`border-solid`,
    BorderRoundingMap[props.borderRounding],
    css`
      border-width: ${props.borderWidth}px;
    `,
    css`
      border-color: ${props.borderColor};
    `,
  ];
};

const Section = styled.div((props: SectionStyledProps) => [
  ...getWidth(props.width),
  getVerticalPadding(props.verticalPadding),
  getHorizontalPadding(props.horizontalPadding),
  ...getBorder(props),
  props.textAlign ? TextAlignMap[props.textAlign] : '',
  props.height
    ? css`
        height: ${props.height};
      `
    : '',
  tw`flex flex-col items-center`,
]);

export default Section;
