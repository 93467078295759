import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { ButtonTheme } from '../NxCommon';
import { ErrorMessage } from '../NxLeadFormComponents/Common';
import NxLeadFormInput from '../NxLeadFormComponents/NxLeadFormInput';
import NxLeadFormSelect from '../NxLeadFormComponents/NxLeadFormSelect';

import { FormData, InitialValues, ValidationSchemaShape } from './LeadFormTypes';
import { Disclaimer, InputsContainer, StyledForm, SubmitButton } from './styles';
import { WaveStates } from './WaveStates';

interface Props {
  buttonTheme: ButtonTheme;
  buttonText: string;
  handleSubmit: (values: FormData) => void | Promise<void>;
  isError: boolean;
  isSubmitting: boolean;
}

export default function LeadFormContent({
  buttonTheme,
  buttonText,
  handleSubmit,
  isError,
  isSubmitting,
}: Props) {
  const validationSchema = yup.object().shape(ValidationSchemaShape);

  return (
    <Formik
      initialValues={InitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isInitialValid={false}
    >
      {(formik) => (
        <StyledForm noValidate>
          <InputsContainer>
            <NxLeadFormInput
              id="name"
              name="name"
              label="Full Name"
              formik={formik}
              required
              placeholder="First Last"
              autoComplete="name"
            />
            <NxLeadFormInput
              id="address"
              name="address"
              label="Street Address"
              formik={formik}
              required
              placeholder="123 Main St"
              autoComplete="street-address"
            />
            <NxLeadFormInput
              id="city"
              name="city"
              label="City"
              formik={formik}
              required
              placeholder="Anytown"
              autoComplete="address-level2"
            />
            <NxLeadFormSelect
              id="state"
              name="state"
              label="State"
              formik={formik}
              required
              options={WaveStates}
            />
            <NxLeadFormInput
              id="zip"
              name="zip"
              label="Zip Code"
              formik={formik}
              required
              placeholder="90210"
              mask="99999"
              autoComplete="postal-code"
            />
            <NxLeadFormInput
              id="email"
              name="email"
              label="Email Address"
              formik={formik}
              required
              placeholder="hello@swellenergy.com"
              type="email"
              autoComplete="email"
            />
            <NxLeadFormInput
              id="cellPhone"
              name="cellPhone"
              label="Phone Number"
              formik={formik}
              required
              placeholder="(888) 465-1784"
              mask="(999) 999-9999"
              type="tel"
              autoComplete="tel-national"
            />
          </InputsContainer>
          {isError && (
            <ErrorMessage>We had a problem processing your request. Please try again.</ErrorMessage>
          )}
          <Disclaimer>
            By submitting this form, I agree to the <a href="/privacy-policy">Privacy Policy</a> and
            consent to be contacted by or on behalf of Swell Energy, including by email, calls, and
            text messages to any telephone number that I provide, about my interest in Swell Energy
            products and services. I understand my consent is not required to purchase.
          </Disclaimer>
          <SubmitButton
            theme={buttonTheme}
            disabled={formik.isSubmitting || isSubmitting}
            type="submit"
          >
            {buttonText}
          </SubmitButton>
        </StyledForm>
      )}
    </Formik>
  );
}
