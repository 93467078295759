import { Formik } from 'formik';
import React from 'react';
import tw from 'twin.macro';
import * as yup from 'yup';

import { ButtonTheme } from '../NxCommon';
import { ErrorMessage } from '../NxLeadFormComponents/Common';
import NxLeadFormCheckbox from '../NxLeadFormComponents/NxLeadFormCheckbox';
import NxLeadFormInput from '../NxLeadFormComponents/NxLeadFormInput';
import NxLeadFormSelect, { NameValue } from '../NxLeadFormComponents/NxLeadFormSelect';

import {
  FormData,
  InitialValues,
  InterestOptions,
  NumberOfPowerwallsOptions,
  ValidationSchemaShape,
} from './LeadFormTypes';
import {
  CheckboxContainer,
  CheckboxLabel,
  CheckboxLabelText,
  Disclaimer,
  FieldError,
  InputsContainer,
  StyledForm,
  SubmitButton,
} from './styles';

interface Props {
  buttonTheme: ButtonTheme;
  buttonText: string;
  showInterestField: boolean;
  isInterestRequired: boolean;
  showPowerwallField: boolean;
  showOriginalInstallerField: boolean;
  installerOptions: NameValue[];
  handleSubmit: (values: FormData) => void | Promise<void>;
  isError: boolean;
  theme: 'light' | 'dark';

  additionalInformation?: boolean;
  iOwnMyHome?: boolean;
  additionalInformationInputPlaceholderText: string;
  additionalInformationInputTitleText: string;
}

const Link = tw.a`text-[#007bff]`;

export default function LeadFormContent({
  buttonTheme,
  buttonText,
  showInterestField,
  isInterestRequired,
  showPowerwallField,
  showOriginalInstallerField,
  installerOptions,
  handleSubmit,
  isError,
  theme,
  additionalInformation,
  iOwnMyHome,
  additionalInformationInputPlaceholderText,
  additionalInformationInputTitleText,
}: Props) {
  const validationObjectShape: any = { ...ValidationSchemaShape };

  if (showInterestField && isInterestRequired) {
    validationObjectShape.interest = yup.string().required('Please select your interests.');
  }

  if (showPowerwallField) {
    validationObjectShape.numberOfPowerwalls = yup.string().required('Please select a value.');
  }
  if (iOwnMyHome) {
    validationObjectShape.isHomeowner = yup.bool().required('You must be the homeowner.').isTrue();
  }

  const validationSchema = yup.object().shape(validationObjectShape);

  return (
    <Formik
      initialValues={InitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isInitialValid={false}
    >
      {(formik) => (
        <StyledForm noValidate>
          <InputsContainer>
            <NxLeadFormInput
              theme={theme}
              id="name"
              name="name"
              label="Full Name"
              formik={formik}
              required
              placeholder="First Last"
              autoComplete="name"
            />

            <NxLeadFormInput
              theme={theme}
              id="email"
              name="email"
              label="Email Address"
              formik={formik}
              required
              placeholder="hello@swellenergy.com"
              type="email"
              autoComplete="email"
            />
            <NxLeadFormInput
              theme={theme}
              id="phone"
              name="phone"
              label="Phone Number"
              formik={formik}
              required
              placeholder="(888) 465-1784"
              mask="(999) 999-9999"
              type="tel"
              autoComplete="tel-national"
            />
            <NxLeadFormInput
              theme={theme}
              id="zip"
              name="zip"
              label="Zip Code"
              formik={formik}
              required
              placeholder="90210"
              mask="99999"
              autoComplete="postal-code"
            />
            {showInterestField && (
              <NxLeadFormSelect
                id="interest"
                name="interest"
                label="I am interested in..."
                formik={formik}
                options={InterestOptions}
                required={isInterestRequired}
              />
            )}
            {showPowerwallField && (
              <NxLeadFormSelect
                id="numberOfPowerwalls"
                name="numberOfPowerwalls"
                label="How many batteries do you currently have installed?"
                formik={formik}
                options={NumberOfPowerwallsOptions}
                required
              />
            )}
            {showPowerwallField &&
              showOriginalInstallerField &&
              installerOptions.length > 0 &&
              formik.values.numberOfPowerwalls > '0' && (
                <NxLeadFormSelect
                  id="originalInstaller"
                  name="originalInstaller"
                  label="Who is your installer?"
                  formik={formik}
                  options={installerOptions}
                />
              )}
            {additionalInformation && (
              <NxLeadFormInput
                theme={theme}
                id="additionalInformation"
                name="additionalInformation"
                label={additionalInformationInputTitleText || 'Additional Information'}
                formik={formik}
                placeholder={additionalInformationInputPlaceholderText || 'Additional Information'}
                autoComplete="additionalInformation"
              />
            )}
            {iOwnMyHome && (
              <CheckboxContainer>
                <NxLeadFormCheckbox id="isHomeowner" name="isHomeowner" formik={formik}>
                  <CheckboxLabel>
                    <CheckboxLabelText>I own my home</CheckboxLabelText>
                    {formik.errors.isHomeowner && (
                      <FieldError theme={theme}>You must be the homeowner.</FieldError>
                    )}
                  </CheckboxLabel>
                </NxLeadFormCheckbox>
              </CheckboxContainer>
            )}
          </InputsContainer>
          {isError && (
            <ErrorMessage>We had a problem processing your request. Please try again.</ErrorMessage>
          )}
          <Disclaimer>
            By submitting this form, I agree to the{' '}
            <Link href="/privacy-policy">Privacy Policy</Link> and consent to be contacted by or on
            behalf of Swell Energy, including by email, calls, and text messages to any telephone
            number that I provide, about my interest in Swell Energy products and services. I
            understand my consent is not required to purchase.
          </Disclaimer>
          <SubmitButton theme={buttonTheme} disabled={formik.isSubmitting} type="submit">
            {buttonText}
          </SubmitButton>
        </StyledForm>
      )}
    </Formik>
  );
}
