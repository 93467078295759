const ApiBaseUrlLocal = 'http://localhost:3000';
const ApiBaseUrlDev = 'https://www-api.dev.swellenergy.com';
const ApiBaseUrlProd = 'https://www-api.swellenergy.com';

export const getApiBaseUrl = () => {
  const isProd =
    window.location.origin.indexOf('swellenergy.com') > -1 &&
    window.location.origin.indexOf('sbx') === -1;

  const isLocal = window.location.origin.indexOf('localhost') > -1;

  if (isLocal) return ApiBaseUrlLocal;
  if (isProd) return ApiBaseUrlProd;

  return ApiBaseUrlDev;
};
