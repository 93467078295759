import tw, { styled, theme } from 'twin.macro';

import {
  ContainerItemsBehavior,
  ContainerItemsBehaviorMap,
  ElementHorizontalPosition,
  ElementHorizontalPositionMap,
  ElementVerticalPosition,
  ElementVerticalPositionMap,
  GutterSize,
  GutterSizeHorizontalMap,
  GutterSizeVerticalMap,
  WrapBreakpoint,
} from '../NxCommon';

interface Props {
  itemsBehavior: ContainerItemsBehavior;
  wrapOnBreakpoint: boolean;
  wrapBreakpoint: WrapBreakpoint;
  spaceX?: GutterSize;
  spaceY?: GutterSize;
  horizontalPosition?: ElementHorizontalPosition;
  verticalPosition?: ElementVerticalPosition;
}

const getWrapSpacingBehavior = (props: Props) => {
  const result = [];

  if (props.wrapOnBreakpoint) {
    if (props.spaceX) {
      result.push(tw`space-x-0`);
      props.wrapBreakpoint === 'md' &&
        result.push({
          [`@media (min-width: ${theme`screens.md`})`]: GutterSizeHorizontalMap[props.spaceX],
        });
      props.wrapBreakpoint === 'lg' &&
        result.push({
          [`@media (min-width: ${theme`screens.lg`})`]: GutterSizeHorizontalMap[props.spaceX],
        });
      props.wrapBreakpoint === 'xl' &&
        result.push({
          [`@media (min-width: ${theme`screens.xl`})`]: GutterSizeHorizontalMap[props.spaceX],
        });
    }

    if (props.spaceY) {
      result.push(GutterSizeVerticalMap[props.spaceY]);
      props.wrapBreakpoint === 'md' && result.push(tw`md:space-y-0`);
      props.wrapBreakpoint === 'lg' && result.push(tw`lg:space-y-0`);
      props.wrapBreakpoint === 'xl' && result.push(tw`xl:space-y-0`);
    }
  } else {
    if (props.spaceX) {
      result.push(GutterSizeHorizontalMap[props.spaceX]);
    }

    if (props.spaceY) {
      result.push(GutterSizeVerticalMap[props.spaceY]);
    }
  }

  return result;
};

const Container = styled.div((props: Props) => [
  props.wrapOnBreakpoint && props.wrapBreakpoint === 'md'
    ? tw`flex flex-row flex-wrap md:flex-nowrap`
    : '',
  props.wrapOnBreakpoint && props.wrapBreakpoint === 'lg'
    ? tw`flex flex-row flex-wrap lg:flex-nowrap`
    : '',
  props.wrapOnBreakpoint && props.wrapBreakpoint === 'xl'
    ? tw`flex flex-row flex-wrap xl:flex-nowrap`
    : '',
  !props.wrapOnBreakpoint ? tw`flex flex-row` : '',
  ContainerItemsBehaviorMap[props.itemsBehavior],
  ...getWrapSpacingBehavior(props),
  props.horizontalPosition ? ElementHorizontalPositionMap[props.horizontalPosition] : '',
  props.verticalPosition ? ElementVerticalPositionMap[props.verticalPosition] : '',
  tw`w-full`,
]);

export default Container;
