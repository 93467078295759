import React from 'react';
import tw from 'twin.macro';

import { getComponentsForBlokArray } from '../../NxComponents';
import { ContentProps } from '../LeadFormTypes';

const Wrapper = tw.div`bg-white max-w-[548px] rounded-md m-auto px-8 py-10 md:px-16 md:py-20 flex flex-col max-w-[456px] items-center justify-center mt-8 mb-24`;
const Row = tw.div`mb-10`;

export const DupeMessage = ({ body_of_dupe_check_message, dupe_check_title }: ContentProps) => {
  return (
    <Wrapper>
      <Row>{getComponentsForBlokArray(dupe_check_title)}</Row>

      <Row>{getComponentsForBlokArray(body_of_dupe_check_message)}</Row>
    </Wrapper>
  );
};
