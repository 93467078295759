import tw, { css, styled } from 'twin.macro';

interface Props {
  active: boolean;
  width: string;
}

export const ItemButton = styled.button<Props>((props) => [
  tw`border-solid outline-none font-sans hocus:outline-none active:outline-none`,
  tw`border-2 rounded-xl font-bold transition-all`,
  tw`py-2 text-xs md:text-sm lg:py-4 lg:text-base`,
  props.active
    ? tw`border-swell-gray text-swell-gray block`
    : tw`border-gray-400 text-gray-400 hidden lg:block`,
  props.width
    ? css`
        width: ${props.width};
      `
    : '',
]);
