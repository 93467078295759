import React from 'react';
import { ChevronLeft } from 'react-feather';
import tw, { styled } from 'twin.macro';

import { useLeadForm } from '../../../hooks/useLeadForm';

const BackIcon = styled(ChevronLeft)(() => [
  tw`absolute -left-8 md:-left-12 cursor-pointer min-w-[32px] md:min-w-[42px] min-h-[32px] md:min-h-[42px]`,
]);

export const BackButton = () => {
  const { setJourneyFlowConfig } = useLeadForm();

  return (
    <BackIcon
      size={'32px'}
      onClick={() => {
        setJourneyFlowConfig({ type: 'HANDLE_BACK' });
      }}
    />
  );
};
