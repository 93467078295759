import React from 'react';
import SbEditable from 'storyblok-react';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import {
  ButtonSize,
  ButtonTheme,
  ElementHorizontalPosition,
  ElementVerticalPosition,
} from '../NxCommon';
import withLinkWrap from '../withLinkWrap';

import { ButtonProps, ExternalButtonContainer, InternalButtonContainer } from './ButtonContainer';

interface BlokProps extends BaseBlok {
  target: string;
  text: string;
  theme: ButtonTheme;
  style: ButtonSize;
  horizontal_align: ElementHorizontalPosition;
  vertical_align?: ElementVerticalPosition;
  width: string;
}

const Button = withLinkWrap<ButtonProps>(InternalButtonContainer, ExternalButtonContainer);

export default function NxButtonLink(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { target, text, theme, style, horizontal_align, vertical_align, width } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Button
        to={target}
        theme={theme}
        size={style}
        width={width}
        horizontalAlign={horizontal_align}
        veritcalAlign={vertical_align}
      >
        {text}
      </Button>
    </SbEditable>
  );
}
