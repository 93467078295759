'use client';

import GoogleMapReact, { Coords } from 'google-map-react';
import React, { useState, useEffect } from 'react';

const DEFAULT_MAP_CONFIG = {
  key: 'AIzaSyBBGmn4VTP8dKOL-AS296CwFl3dht7S2IA',
  libraries: ['drawing', 'places', 'geometry'].join(','),
  mapTypeId: 'satellite',
  zoom: 20,
  tilt: 0,
  maxZoom: 22,
  center: { lat: 34.0563559, lng: -118.4014543 },
};

export interface UserAddress {
  address: string;
  appt?: string | null;
  city?: string;
  state?: string;
  zip?: number | null;
  country?: string;
  lat: number;
  lng: number;
  streetNumber: string;
  unit: string;
  placeId: string;
}

const Map = ({ inputRef, formik }: any) => {
  const [mapsInstance, setMapsInstance] = useState<any | null>(null);

  const addressBox = inputRef?.current;
  const mapCenter: Coords = {
    lat: DEFAULT_MAP_CONFIG.center.lat,
    lng: DEFAULT_MAP_CONFIG.center.lng,
  };

  useEffect(() => {
    if (!addressBox || !mapsInstance) {
      return;
    }

    const searchBox = new mapsInstance.maps.places.Autocomplete(addressBox, {
      types: ['address'],
      fields: ['address_components', 'geometry', 'place_id'],
    });

    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();

      if (!place) return;
      const findComponent = (name: string) =>
        place.address_components?.find((c: any) => c.types[0] === name);

      const payload: UserAddress = {
        address: `${findComponent('route')?.short_name}`,
        city: findComponent('locality')?.long_name,
        state: findComponent('administrative_area_level_1')?.short_name,
        zip: findComponent('postal_code')?.long_name,
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        country: findComponent('country')?.short_name,
        streetNumber: findComponent('street_number')?.long_name,
        unit: findComponent('subpremise')?.short_name,
        placeId: place.place_id,
      };

      if (
        payload.address &&
        !payload.address.startsWith('undefined') &&
        payload.city &&
        payload.state &&
        payload.zip &&
        payload.lat &&
        payload.lng &&
        payload.streetNumber &&
        payload.placeId
      ) {
        const { address, city, lat, lng, state, zip, streetNumber, unit, placeId, country } =
          payload;

        addressBox.value = payload.address;
        formik.setFieldValue('streetNumber', streetNumber);
        formik.setFieldValue('city', city);
        formik.setFieldValue('state', state);
        formik.setFieldValue('zip', zip);
        formik.setFieldValue('lat', lat);
        formik.setFieldValue('lng', lng);
        formik.setFieldValue('streetAddress', address);
        formik.setFieldValue('placeId', placeId);
        formik.setFieldValue('unit', unit);
        formik.setFieldValue('country', country);

        if (unit) formik.setFieldValue('unit', unit);

        setTimeout(() => formik.setFieldTouched('streetAddress', true));
        formik.setFieldValue(
          'address',
          `${streetNumber} ${address}${unit ? ` ${unit}` : ''}, ${city}, ${state} ${zip}`
        );
      } else {
        formik.setFieldValue('streetAddress', '');
        formik.setFieldValue('address', '');
        addressBox.value = '';
      }
    });

    // eslint-disable-next-line consistent-return
    return () => mapsInstance.maps.event.clearInstanceListeners(searchBox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressBox, mapsInstance]);

  return (
    <div className="invisible">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: DEFAULT_MAP_CONFIG.key,
          libraries: DEFAULT_MAP_CONFIG.libraries,
        }}
        center={mapCenter}
        zoom={DEFAULT_MAP_CONFIG.zoom}
        options={{
          mapTypeId: DEFAULT_MAP_CONFIG.mapTypeId,
          tilt: DEFAULT_MAP_CONFIG.tilt,
          maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
          scaleControl: true,
          rotateControl: false,
          fullscreenControl: false,
          scrollwheel: false,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(googleInstance: any) => {
          setMapsInstance(googleInstance);
        }}
        style={{ height: '0px', width: '0px' }}
      />
    </div>
  );
};

export { Map };
