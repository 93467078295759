import React, { ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

interface ButtonItemProps {
  children: ReactNode;
  selected?: boolean;
  onClick: () => void;
}

const ButtonItemWrapper = styled.div<{ selected?: boolean }>(() => [
  tw`text-swell-gray-light text-sm border border-[#797979] rounded-[5px] p-2 w-full cursor-pointer mt-2 `,
]);

export const ButtonItem = ({ onClick, children }: ButtonItemProps) => {
  return <ButtonItemWrapper onClick={onClick}>{children}</ButtonItemWrapper>;
};
