import { FormikHelpers, useFormik } from 'formik';
import GoogleMapReact, { Coords } from 'google-map-react';
import human from 'humanparser';
import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import * as yup from 'yup';

import { useEnvironment } from '../../../components/EnvironmentContext';
import { emailRegex } from '../../../constants';
import { useLeadForm } from '../../../hooks/useLeadForm';
import { getComponentsForBlokArray } from '../../NxComponents';
import { SubmitButton } from '../../NxLeadForm/styles';
import NxLeadFormInput from '../../NxLeadFormComponents/NxLeadFormInput';
import { FormData, ContentProps, LeadFormResult, UserAddress } from '../LeadFormTypes';

import { AlternativeFlow } from './AlternativeFlow';

const FormContainer = tw.form` mt-4 md:mb-24 bg-white max-w-[548px] rounded-md m-auto px-8 pt-10 md:px-16 md:py-20 max-w-[456px] flex flex-col items-center justify-center`;
const InputsContainer = tw.div`flex flex-col w-full`;
const TitleWrapper = tw.div`mb-8`;
const LegalWrapper = tw.div`mt-8`;
const MapWrapper = tw.div`invisible`;

const ValidationSchemaShape = {
  name: yup
    .string()
    .required('Please enter first and last name')
    .test('name', 'Please enter first and last name', (value) => !!value?.trim().split(' ')?.[1]),
  email: yup
    .string()
    .required('Please enter a valid email address')
    .matches(emailRegex, 'Please enter a valid email address'),

  phone: yup
    .string()
    .required('Please enter a valid phone number')
    .test(
      'phone',
      'Please enter a valid phone number',
      (value) => value?.replace(/\D/g, '').length >= 10
    ),
  address: yup.string().required('Please enter a valid address'),
  streetAddress: yup.string().required('Please enter a valid address'),
  streetNumber: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string(),
};

const initialValues: FormData = {
  name: '',
  email: '',
  phone: '',
  address: '',
  streetAddress: '',
  streetNumber: '',
  city: '',
  state: '',
  zip: '',
  unit: '',
};

const DEFAULT_MAP_CONFIG = {
  key: process.env.GATSBY_GOOGLE_MAP_KEY || '',
  libraries: ['drawing', 'places', 'geometry'].join(','),
  mapTypeId: 'satellite',
  zoom: 20,
  tilt: 0,
  maxZoom: 22,
  center: { lat: 34.0563559, lng: -118.4014543 },
};

export const LeadForm = ({
  legal_verbiage,
  buttonText,
  handleSubmit,
  title,
  success_content,
  wave_utility_program_id,
  customer_type,
  journey_start_title,
  integration_content,
  email_from,
  email_bcc,
  email_cc,
  email_subject,
  t_c_agreement_pdf,
  sbx_wave_utility_program_id,
  gs_opportunity_type,
}: ContentProps) => {
  const validationObjectShape: any = { ...ValidationSchemaShape };
  const { journeyFlowConfig, setJourneyFlowConfig } = useLeadForm();
  const { storyName, environment } = useEnvironment();
  const validationSchema = yup.object().shape(validationObjectShape);
  const [mapsInstance, setMapsInstance] = useState<any | null>(null);
  const [succesContent, setSuccesContent] = useState<boolean>(false);
  const inputEl = useRef<HTMLInputElement | null>(null);

  const addressBox = inputEl?.current;
  const mapCenter: Coords = {
    lat: DEFAULT_MAP_CONFIG.center.lat,
    lng: DEFAULT_MAP_CONFIG.center.lng,
  };

  const formik = useFormik({
    initialValues: journeyFlowConfig?.information?.formData
      ? {
          name: journeyFlowConfig?.information?.formData.firstName
            ? `${journeyFlowConfig?.information?.formData.firstName} ${journeyFlowConfig?.information?.formData.lastName}`
            : '',

          email: journeyFlowConfig?.information?.formData.email,
          phone: journeyFlowConfig?.information?.formData.phone,
          address: journeyFlowConfig?.information?.formData.streetName
            ? `${journeyFlowConfig?.information?.formData.streetNumber} ${journeyFlowConfig?.information?.formData.streetName} ${journeyFlowConfig?.information?.formData.unit} ${journeyFlowConfig?.information?.formData.city} ${journeyFlowConfig?.information?.formData.state} ${journeyFlowConfig?.information?.formData.zip}`
            : '',
          streetAddress: journeyFlowConfig?.information?.formData.streetName,
          streetNumber: journeyFlowConfig?.information?.formData.streetNumber,
          city: journeyFlowConfig?.information?.formData.city,
          state: journeyFlowConfig?.information?.formData.state,
          zip: journeyFlowConfig?.information?.formData.zip,
          unit: journeyFlowConfig?.information?.formData.unit,
        }
      : initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
      if (formik.values.streetAddress) {
        try {
          const res: LeadFormResult = await handleSubmit(values);

          if (success_content) {
            setSuccesContent(true);
          } else if (res?.id) {
            setJourneyFlowConfig({
              payload: { type: 'JOURNEY_START', payload: '1' },
              type: 'INFORMATION',
            });
            setJourneyFlowConfig({ type: 'SET_PAGE', payload: { page: 'JOURNEY_START' } });

            setJourneyFlowConfig({
              payload: { type: 'JOURNEY_START', payload: '1' },
              type: 'INFORMATION',
            });
            setJourneyFlowConfig({
              payload: { type: 'ENROLLMENT_ID', payload: res?.id },
              type: 'INFORMATION',
            });

            setJourneyFlowConfig({ type: 'SET_PAGE', payload: { page: 'JOURNEY_START' } });
          } else if (res.statusCode === 400) {
            setJourneyFlowConfig({ type: 'SET_PAGE', payload: { page: 'DUPE_CHECK' } });
          }

          const { firstName, lastName, middleName = '' } = human.parseName(values.name);

          const temp = {
            firstName: `${firstName} ${middleName}`.trim(),
            lastName: lastName,
            email: values.email,
            phone: values.phone,
            city: values.city,
            state: values.state,
            address: `${values.streetNumber} ${values.streetAddress}`,
            streetNumber: values.streetNumber,
            streetName: values.streetAddress,
            zip: values.zip,
            unit: values.unit,
            utilityProgramName:
              environment === 'prod' ? wave_utility_program_id : sbx_wave_utility_program_id,
            pageUrl: window.location.origin + window.location.pathname,
            storyblokStory: storyName,
          };

          setJourneyFlowConfig({
            payload: { type: 'LEAD_FORM', payload: { ...temp } },
            type: 'INFORMATION',
          });
          setJourneyFlowConfig({
            payload: {
              customer_type,
              journey_start_title,
              integration_content,
              email_from,
              email_bcc,
              email_cc,
              email_subject,
              t_c_agreement_pdf: t_c_agreement_pdf.filename,
              gs_opportunity_type,
            },
            type: 'UPDATE',
          });
        } catch (error) {
          setJourneyFlowConfig({ type: 'SET_PAGE', payload: { page: 'DUPE_CHECK' } });
        }
      } else {
        formik.setFieldError('address', 'Please enter a valid address.');
      }
      formikHelpers.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!addressBox || !mapsInstance) {
      return;
    }

    const searchBox = new mapsInstance.maps.places.Autocomplete(addressBox, {
      types: ['address'],
      fields: ['address_components', 'geometry'],
    });

    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();

      if (!place) return;
      const findComponent = (name: string) =>
        place.address_components?.find((c: any) => c.types[0] === name);

      const payload: UserAddress = {
        address: `${findComponent('route')?.short_name}`,
        city: findComponent('locality')?.long_name,
        state: findComponent('administrative_area_level_1')?.short_name,
        zip: findComponent('postal_code')?.long_name,
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        country: findComponent('country')?.short_name,
        streetNumber: findComponent('street_number')?.long_name,
        unit: findComponent('subpremise')?.short_name,
      };

      if (
        payload.address &&
        !payload.address.startsWith('undefined') &&
        payload.city &&
        payload.state &&
        payload.zip &&
        payload.lat &&
        payload.lng &&
        payload.streetNumber
      ) {
        const { address, city, lat, lng, state, zip, streetNumber, unit } = payload;

        addressBox.value = payload.address;
        formik.setFieldValue('streetNumber', streetNumber);
        formik.setFieldValue('city', city);
        formik.setFieldValue('state', state);
        formik.setFieldValue('zip', zip);
        formik.setFieldValue('lat', lat);
        formik.setFieldValue('long', lng);
        formik.setFieldValue('streetAddress', address);
        if (unit) formik.setFieldValue('unit', unit);

        setTimeout(() => formik.setFieldTouched('streetAddress', true));
        formik.setFieldValue(
          'address',
          `${streetNumber} ${address} ${unit || ''} ${city} ${state} ${zip}`
        );
      } else {
        addressBox.value = '';
      }
    });

    // eslint-disable-next-line consistent-return
    return () => mapsInstance.maps.event.clearInstanceListeners(searchBox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressBox, mapsInstance]);

  return (
    <>
      {succesContent ? (
        <AlternativeFlow succesContent={success_content} />
      ) : (
        <FormContainer onSubmit={formik.handleSubmit}>
          <MapWrapper>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: DEFAULT_MAP_CONFIG.key,
                libraries: DEFAULT_MAP_CONFIG.libraries,
              }}
              center={mapCenter}
              zoom={DEFAULT_MAP_CONFIG.zoom}
              options={{
                mapTypeId: DEFAULT_MAP_CONFIG.mapTypeId,
                tilt: DEFAULT_MAP_CONFIG.tilt,
                maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
                scaleControl: true,
                rotateControl: false,
                fullscreenControl: false,
                scrollwheel: false,
              }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={(googleInstance: any) => {
                setMapsInstance(googleInstance);
              }}
              style={{ height: '0px', width: '0px' }}
            />
          </MapWrapper>
          <TitleWrapper>{getComponentsForBlokArray(title)}</TitleWrapper>

          <InputsContainer>
            <NxLeadFormInput<typeof formik.values>
              id="name"
              name="name"
              label="Full Name"
              formik={formik}
              required
              placeholder=""
              autoComplete="name"
            />
            <NxLeadFormInput<typeof formik.values>
              id="email"
              name="email"
              label="Email"
              formik={formik}
              required
              placeholder=""
              type="email"
              autoComplete="email"
            />
            <NxLeadFormInput<typeof formik.values>
              id="phone"
              name="phone"
              label="Phone"
              formik={formik}
              required
              placeholder=""
              mask="(999) 999-9999"
              type="tel"
              autoComplete="tel-national"
            />

            <NxLeadFormInput<typeof formik.values>
              ref={inputEl}
              onKeyDown={formik.setFieldValue.bind('streetAddress', '')}
              placeholder=""
              label="Street Address"
              id="address"
              name="address"
              formik={formik}
            />
          </InputsContainer>

          <SubmitButton disabled={formik.isSubmitting || !formik.isValid}>
            {buttonText}
          </SubmitButton>
          <LegalWrapper>{getComponentsForBlokArray(legal_verbiage)}</LegalWrapper>
        </FormContainer>
      )}
    </>
  );
};
