import React from 'react';
import tw, { styled } from 'twin.macro';

const SVG = tw.svg`w-full h-full`;
const PercentCircle = tw.circle`text-[#053873] stroke-current w-4 stroke-[10px] `;

const OuterCircle = styled.circle(({ active }: { active: boolean }) => [
  tw`stroke-current`,
  tw`text-[#E9E8ED]`,
  active && tw`text-transparent`,
]);

const ResultCircleWrapper = tw.div`relative w-20 h-20 md:w-24 md:h-24 ml-4 absolute right-0 md:right-[-128px]`;

export const CircleLoader = ({ percent }: { percent: number }) => {
  return (
    <ResultCircleWrapper>
      <SVG viewBox="0 0 100 100">
        <OuterCircle
          className=""
          strokeWidth="16"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          active={percent > 0}
        ></OuterCircle>

        <PercentCircle
          style={{
            strokeDasharray: '400, 400',
            transition: 'stroke-dashoffset 0.35s',
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
          stroke-width="80"
          stroke-linecap="round"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          strokeDashoffset={`calc(400 - (400 * ${percent * 0.6}) / 100)`}
        ></PercentCircle>

        <text x="50" y="28" fontSize="14" textAnchor="middle" alignmentBaseline="middle">
          *Est.
        </text>
        <text x="50" y="46" fontSize="14" textAnchor="middle" alignmentBaseline="middle">
          Accuracy
        </text>
        <text x="50" y="66" fontSize="14" textAnchor="middle" alignmentBaseline="middle">
          {percent ? `${percent}%` : 'After Input'}
        </text>
      </SVG>
    </ResultCircleWrapper>
  );
};
