import * as Sentry from '@sentry/react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const metrics = {
  trackNavigationPhoneClick(utmContent?: string) {
    trackCustomEvent({
      category: 'Phonecall',
      action: 'Click_Navi_Phone_Link',
      label: utmContent || 'n/a',
    });
  },

  trackCallNowButton(utmContent?: string) {
    trackCustomEvent({
      category: 'Phonecall',
      action: 'Click_Call_Now_Button',
      label: utmContent || 'n/a',
    });
  },

  trackLeadFormTouch(_url, leadComponentName: string, utmContent?: string) {
    trackCustomEvent({
      category: 'Lead Form',
      action: 'Touch',
      label: leadComponentName + ' - ' + (utmContent || 'n/a'),
    });
  },

  trackLeadFormStep(stepNumber, leadComponentName: string, utmContent?: string) {
    trackCustomEvent({
      category: 'Lead Form',
      action: 'Step ' + stepNumber,
      label: leadComponentName + ' - ' + (utmContent || 'n/a'),
    });
  },

  trackLeadFormSubmit(_url, leadComponentName: string, utmContent?: string) {
    trackCustomEvent({
      category: 'Lead Form',
      action: 'Submit',
      label: leadComponentName + ' - ' + (utmContent || 'n/a'),
    });
  },

  trackLeadFormAcceptTerms(leadComponentName: string, utmContent?: string) {
    trackCustomEvent({
      category: 'Lead Form',
      action: 'Accept Terms',
      label: leadComponentName + ' - ' + (utmContent || 'n/a'),
    });
  },

  trackLeadFormRejectTerms(leadComponentName: string, utmContent?: string) {
    trackCustomEvent({
      category: 'Lead Form',
      action: 'Reject Terms',
      label: leadComponentName + ' - ' + (utmContent || 'n/a'),
    });
  },

  trackPartnerFormSubmit(_url, leadComponentName: string) {
    trackCustomEvent({
      category: 'Lead Form',
      action: 'Submit',
      label: leadComponentName,
    });
  },

  trackBingLeadEvent() {
    if (typeof window !== 'undefined') {
      window['uetq'] = window['uetq'] || [];
      try {
        window['uetq'].push('event', 'Lead', {
          event_category: 'Lead',
          event_label: 'Button',
          event_value: 1,
        });
      } catch (uee) {
        Sentry.captureException(uee);
      }
    }
  },

  trackFacebookLeadEvent() {
    if (typeof window !== 'undefined' && typeof window['fbq'] === 'function') {
      try {
        window['fbq']('track', 'Lead');
      } catch (fberr) {
        Sentry.captureException(fberr);
      }
    }
  },

  trackGoogleTagConversion(eventTarget: string) {
    if (typeof window !== 'undefined' && typeof window['gtag'] === 'function') {
      try {
        window['gtag']('event', 'conversion', { send_to: eventTarget });
      } catch (gee) {
        Sentry.captureException(gee);
      }
    }
  },
};

export default metrics;
