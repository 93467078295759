import { FormikProps } from 'formik';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

import { useLeadForm } from '../../hooks/useLeadForm';
import { FieldError } from '../NxLeadForm/styles';

import { InputContainer, Label } from './Common';

interface SelectProps {
  isError: boolean;
  errorColor: string;
  primaryColor: string;
}

const Select = styled.select<SelectProps>((props) => [
  tw`w-full rounded-lg p-3 lg:(rounded-xl p-2) font-sans`,
  props.isError
    ? props.errorColor
      ? css`
          border: 1px solid ${props.errorColor};
        `
      : tw`border border-red-500`
    : css`
        border: 1px solid rgba(57, 60, 65, 0.3);
      `,
  props.primaryColor
    ? css`
        &:focus {
          border-color: ${props.primaryColor};
        }
      `
    : tw`focus:border-swell-blue hocus:outline-none`,
  css`
    &:required:invalid {
      color: rgba(57, 60, 65, 0.5);
    }
  `,
  css`
    option[value=''][disabled] {
      display: none;
    }
  `,
  css`
    option {
      color: #393c41;
    }
  `,
]);

export interface NameValue {
  value: string;
  name: string;
}

interface Props<TFormData> {
  formik: FormikProps<TFormData>;
  id: string;
  name: string;
  required?: boolean;
  options: NameValue[];
  label?: string;
  placeholder?: string;
}

export default function NxLeadFormSelect<TFormData>(props: Props<TFormData>) {
  const { formik, id, name, required, options, label, placeholder } = props;
  const hasError = Boolean(formik.errors[name]);
  const showError = formik.touched[name] || formik.submitCount > 0;

  const {
    pageStyles: { errorColor, primaryColor },
  } = useLeadForm();

  return (
    <InputContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Select
        id={id}
        name={name}
        value={formik.values[name] || ''}
        required={required}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={formik.isSubmitting}
        isError={hasError && showError}
        errorColor={errorColor}
        primaryColor={primaryColor}
      >
        <option value="" disabled>
          {placeholder || 'Select answer'}
        </option>
        {options.map((option, index) => (
          <option value={option.value} key={`${option.value}-${index}`}>
            {option.name}
          </option>
        ))}
      </Select>
      {hasError && showError && (
        <FieldError errorColor={errorColor}>
          {hasError && showError ? formik.errors[name] : ' '}
        </FieldError>
      )}
    </InputContainer>
  );
}
