import * as Sentry from '@sentry/react';

import { getApiBaseUrl } from './config';

export const getIsZipInProgramTerritory = async (zipCode: string) => {
  const data = { zipCode };
  const url = `${getApiBaseUrl()}/programs/get-zip-eligibility`;

  try {
    const response = await fetch(url, {
      headers: { 'content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(data),
      method: 'POST',
      mode: 'cors',
    });

    if (response.status !== 200) {
      return false;
    }

    const responseObj = await response.json();
    return responseObj?.hasEligibleProgram === true;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};
