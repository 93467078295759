import * as Sentry from '@sentry/react';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { leads, metrics } from '../../services';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ButtonStyles } from '../NxButtonLink/ButtonContainer';
import { ErrorMessage, SuccessMessage } from '../NxLeadFormComponents/Common';
import NxLeadFormCheckbox from '../NxLeadFormComponents/NxLeadFormCheckbox';
import NxLeadFormInput from '../NxLeadFormComponents/NxLeadFormInput';
import NxLeadFormSelect from '../NxLeadFormComponents/NxLeadFormSelect';
import NxLeadFormTextArea from '../NxLeadFormComponents/NxLeadFormTextArea';

import { FormData, InitialValues, UtilityTypes, ValidationSchema } from './LeadFormTypes';

const SubmitButton = styled.button((props) =>
  ButtonStyles({
    theme: 'blue',
    size: 'normal',
    horizontalAlign: 'center',
    width: '280px',
    disabled: props.disabled,
  })
);

const Container = tw.div`w-full`;
const StyledForm = styled(Form)(() => [tw`flex flex-col space-y-6`]);
const GroupHeader = tw.span`text-base text-swell-gray font-bold`;
const InputRow = tw.div`flex flex-col w-full md:flex-row`;
const AgreeText = tw.span`ml-2 text-xs leading-snug`;
const AgreeNDALink = tw.a`text-swell-blue`;

export default function NxUtilityLeadForm(props: NxBaseProps<BaseBlok>): React.ReactNode {
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'failed' | 'successful'>('idle');
  const submitLead = async (values: FormData) => {
    setSubmitStatus('idle');

    try {
      await leads.submitPartner({
        ...values,
        interests: null,
        website: null,
        address: null,
        zip: null,
        referred_by: '',
        partner_id: '',
        type: 'utility',
      });

      metrics.trackPartnerFormSubmit(
        window.location.origin + window.location.pathname,
        'NxUtilityLeadForm'
      );

      setSubmitStatus('successful');
    } catch (err) {
      Sentry.captureException(err);
      setSubmitStatus('failed');
    }
  };

  return (
    <SbEditable content={props.blok}>
      <Container>
        {submitStatus === 'successful' && (
          <SuccessMessage>Thank you, we will be in touch soon.</SuccessMessage>
        )}
        {submitStatus !== 'successful' && (
          <>
            <Formik
              initialValues={InitialValues}
              validationSchema={ValidationSchema}
              onSubmit={submitLead}
            >
              {(formik) => (
                <StyledForm noValidate>
                  {submitStatus === 'failed' && (
                    <ErrorMessage>
                      We had a problem processing your request. Please try again.
                    </ErrorMessage>
                  )}
                  <div>
                    <GroupHeader>General Information</GroupHeader>
                    <InputRow>
                      <NxLeadFormInput
                        id="firstName"
                        name="firstName"
                        formik={formik}
                        required
                        placeholder="First name"
                      />
                      <NxLeadFormInput
                        id="lastName"
                        name="lastName"
                        formik={formik}
                        required
                        placeholder="Last name"
                      />
                    </InputRow>
                    <InputRow>
                      <NxLeadFormInput
                        id="phone"
                        name="phone"
                        formik={formik}
                        required
                        placeholder="Phone"
                        mask="(999) 999-9999"
                        type="tel"
                      />
                      <NxLeadFormInput
                        id="email"
                        name="email"
                        formik={formik}
                        required
                        placeholder="Email"
                        type="email"
                      />
                    </InputRow>
                    <InputRow>
                      <NxLeadFormInput
                        id="company"
                        name="company"
                        formik={formik}
                        required
                        placeholder="Company name"
                      />
                      <NxLeadFormSelect
                        id="utility_type"
                        name="utility_type"
                        formik={formik}
                        required
                        placeholder="Utility type"
                        options={UtilityTypes.map((v) => ({ name: v, value: v }))}
                      />
                    </InputRow>
                  </div>
                  <div>
                    <GroupHeader>Message</GroupHeader>
                    <NxLeadFormTextArea
                      id="description"
                      name="description"
                      formik={formik}
                      rows={4}
                    />
                  </div>
                  <div tw="mx-auto">
                    <NxLeadFormCheckbox name="acceptTerms" id="acceptTerms" formik={formik}>
                      <AgreeText>
                        I have read and agree to the{' '}
                        <AgreeNDALink
                          href="https://a.storyblok.com/f/89720/x/ba62a39102/swell_mutual_nda.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Mutual Non-Disclosure Agreement
                        </AgreeNDALink>
                      </AgreeText>
                    </NxLeadFormCheckbox>
                  </div>
                  {formik.errors.acceptTerms && (
                    <div tw="mx-auto text-sm text-red-600">{formik.errors.acceptTerms}</div>
                  )}
                  <SubmitButton disabled={formik.isSubmitting} type="submit">
                    Submit
                  </SubmitButton>
                </StyledForm>
              )}
            </Formik>
          </>
        )}
      </Container>
    </SbEditable>
  );
}
