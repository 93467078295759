import { FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { components, default as ReactSelect, MultiValue, OptionProps } from 'react-select';
import tw, { css, styled, theme } from 'twin.macro';

import { FieldError } from '../NxLeadForm/styles';

import { InputContainer, Label } from './Common';

export interface LabelValue {
  value: string;
  label: string;
}

interface Props<TFormData> {
  formik: FormikProps<TFormData>;
  id: string;
  name: string;
  required?: boolean;
  options: LabelValue[];
  label?: string;
  placeholder?: string;
}

const RSContainer = styled.div`
  .rs__control {
    ${tw`font-sans rounded-xl px-2 bg-white`};
    border: 1px solid rgba(57, 60, 65, 0.3);
  }
  .rs__control--is-focused {
    ${tw`border-swell-blue`}
  }
  .rs__placeholder {
    color: #9ca3af;
  }
  .rs__menu {
    ${tw`bg-white mx-2`}
  }
  .rs__value-container {
    ${tw`space-x-2`}
  }
  .rs__multi-value {
    ${tw`bg-gray-100 border-gray-200 border px-1`}
  }
  .rs__option {
    ${tw`p-2`}
  }
  .rs__option--is-focused {
    ${tw`bg-blue-100`}
  }
  .rs__multi-value__remove {
    ${tw`hover:text-red-700`}
  }
`;

const OptionCheckbox = styled.input(() => [
  tw`rounded-xl mt-0 focus:border-swell-blue hocus:outline-none`,
  css`
    border: 1px solid rgba(57, 60, 65, 0.3);
    accent-color: ${theme`colors.swell-blue`};
  `,
]);

const OptionLabel = tw.label`mb-0`;

function Option(props: OptionProps) {
  return (
    <div>
      <components.Option {...props}>
        <OptionCheckbox type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <OptionLabel>{props.label}</OptionLabel>
      </components.Option>
    </div>
  );
}

export default function NxLeadFormSelectMulti<TFormData>(props: Props<TFormData>) {
  const [selectedOptions, setSelectedOptions] = useState<LabelValue[]>([]);
  const { formik, id, name, required, options, label, placeholder } = props;
  const { handleChange, handleBlur } = formik;
  const hasError = Boolean(formik.errors[name]);
  const showError = formik.touched[name] || formik.submitCount > 0;
  const handleReactSelectChange = useCallback((value: MultiValue<LabelValue>) => {
    setSelectedOptions([...value]);
  }, []);
  useEffect(() => {
    const newValue = selectedOptions
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((v) => v.value)
      .join(',');
    handleChange(name)(newValue);
  }, [handleChange, name, selectedOptions]);

  return (
    <InputContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      <RSContainer>
        <ReactSelect
          id={id}
          name={name}
          options={options}
          components={{ Option }}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          value={selectedOptions}
          required={required}
          placeholder={placeholder}
          onChange={handleReactSelectChange}
          onBlur={handleBlur}
          isDisabled={formik.isSubmitting}
          unstyled
          classNamePrefix="rs"
        />
      </RSContainer>
      <FieldError>{hasError && showError ? formik.errors[name] : ' '}</FieldError>
    </InputContainer>
  );
}
