import { css, styled } from 'twin.macro';

import { getScreenWidths } from '../NxCommon';

interface Props {
  width?: string;
  width_sm?: string;
  width_md?: string;
  width_lg?: string;
  width_xl?: string;
  padding_x?: number;
  padding_y?: number;
}

const Column = styled.div((props: Props) => [
  ...getScreenWidths(props),
  props.padding_x
    ? css`
        padding-left: ${props.padding_x}rem;
        padding-right: ${props.padding_x}rem;
      `
    : '',
  props.padding_y
    ? css`
        padding-top: ${props.padding_y}rem;
        padding-bottom: ${props.padding_y}rem;
      `
    : '',
]);

export default Column;
