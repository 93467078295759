import { useState, useCallback, useEffect } from 'react';

const useScrollBottom = (defaultScroll?: boolean, element?: any) => {
  const [reachedEnd, setReachedEnd] = useState(defaultScroll || false);

  useEffect(() => {
    //if div is not scrollable
    const hasVerticalScrollbar =
      (element.current?.scrollHeight || 0) <= (element.current?.clientHeight || 0);
    if (hasVerticalScrollbar && element?.current) setReachedEnd(true);
  }, [element]);

  const handleScroll = useCallback((e: Event) => {
    const bottom =
      (e.target as HTMLElement).scrollHeight -
        (e.target as HTMLElement).scrollTop -
        (e.target as HTMLElement).clientHeight <=
      1;

    if (bottom) {
      setReachedEnd(true);
    } else {
      setReachedEnd(false);
    }
  }, []);

  useEffect(() => {
    const div = element?.current;
    div.addEventListener('scroll', handleScroll);
    return () => div.removeEventListener('scroll', handleScroll);
  }, [handleScroll, element]);

  return { reachedEnd };
};

export default useScrollBottom;
