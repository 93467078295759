import React from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ElementHorizontalPosition, ElementHorizontalPositionMap } from '../NxCommon';
import { StoryBlokColor } from '../StoryBlokColor';

interface BlokProps extends BaseBlok {
  color: StoryBlokColor;
  thickness: number;
  horizontal_position?: ElementHorizontalPosition;
}

interface StyleProps {
  color: string;
  thickness: number;
  horizontalPosition?: ElementHorizontalPosition;
}

const Separator = styled.div((props: StyleProps) => [
  `background-color: ${props.color};`,
  `width: ${props.thickness}px;`,
  tw`h-full`,
  props.horizontalPosition ? ElementHorizontalPositionMap[props.horizontalPosition] : '',
]);

export default function NxVerticalSeparator(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { color, thickness, horizontal_position } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Separator
        color={color.color}
        thickness={thickness}
        horizontalPosition={horizontal_position}
      />
    </SbEditable>
  );
}
