import React, { createContext, useContext, useReducer } from 'react';

import { PageStylesValues } from '../components/EnvironmentContext';
import { BaseBlok } from '../components/NxBaseProps';
import {
  DupeMessage,
  JourneyStart,
  LeadForm,
  ProgramOverview,
  Question,
  SuccessMessage,
  TermsAndConditions,
  RequiredFieldsMessage,
} from '../components/NxJourneyLeadForm/components';
import {
  ContentProps,
  JourneyOptionBlokProps,
  JourneyOptionProps,
  SubmitType,
} from '../components/NxJourneyLeadForm/LeadFormTypes';
import { leads } from '../services';
import { GenericLeadJourneyOption } from '../services/leads';

const components = {
  LEAD_FORM: LeadForm,
  JOURNEY_START: JourneyStart,
  PROGRAM_OVERVIEW: ProgramOverview,
  TERMS_AND_CONDITIONS: TermsAndConditions,
  SUCCESS_MESSAGE: SuccessMessage,
  DUPE_CHECK: DupeMessage,
  QUESTION: Question,
  REQUIREDFIELDSMESSAGE: RequiredFieldsMessage,
};

type FormDataType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  state: string;
  address: string;
  streetNumber: string;
  streetName: string;
  zip: string;
  unit: string;
  utilityProgramName: string;
  pageUrl: string;
  storyblokStory: string;
};

type QuestionType = { answer: string; question: string; type: 'DropdownField' | 'TextField' };
type PayloadTypes = boolean | string | JOURNEY_PATH | FormDataType | QuestionType;
type JourneyActionTypes = 'UPDATE' | 'HANDLE_BACK' | 'HANDLE_NEXT' | 'SET_PAGE' | 'INFORMATION';

interface StateTypes {
  journeyPath: JOURNEY_PATH;
  termsAndConditions: boolean;
  formData: FormDataType;
  question: { answer: string; question: string; type: 'TextField' | 'DropdownField' };
  enrollmentId?: string;
}

type JourneyOptionsType = Array<GenericLeadJourneyOption>;

type ActionType = { type: PageTypes; payload: PayloadTypes };
type ActionTypeJounery = {
  type: JourneyActionTypes;
  payload?:
    | (Partial<JourneyOptionProps> & { page: PageTypes; _uid?: string })
    | ActionType
    | JourneyOptionBlokProps
    | { journeyOptions: JourneyOptionsType }
    | {
        customer_type: BaseBlok[];
        journey_start_title: BaseBlok[];
        integration_content?: string;
        email_from?: string;
        email_bcc?: string;
        email_cc?: string;
        email_subject?: string;
        t_c_agreement_pdf?: string;
        gs_opportunity_type?: string;
      };
};
type JourneyFlowType = JourneyOptionProps & {
  page: PageTypes;
  information?: StateTypes;
  journeyOptions: JourneyOptionsType;
  customer_type?: BaseBlok[];
  journey_start_title?: BaseBlok[];
  integration_content?: string;
  email_from?: string;
  email_bcc?: string;
  email_cc?: string;
  email_subject?: string;
  t_c_agreement_pdf?: string;
  gs_opportunity_type?: string;
};

interface IPageConfigContext {
  page: (props: ContentProps) => JSX.Element;
  setJourneyFlowConfig: (action: ActionTypeJounery) => void;
  journeyFlowConfig: JourneyFlowType;
  storyName: string;
  pageStyles?: PageStylesValues;
}

interface Props {
  children: React.ReactNode;
  storyName?: string;
  pageStyles?: PageStylesValues;
}

export enum JOURNEY_PATH {
  JOURNEY_PATH_1 = '1',
  JOURNEY_PATH_2 = '2',
  JOURNEY_PATH_3 = '3',
}

export type PageTypes = keyof typeof components | 'ENROLLMENT_ID';

const initialInfo: StateTypes = {
  journeyPath: JOURNEY_PATH.JOURNEY_PATH_1,
  termsAndConditions: false,
  question: { answer: '', question: '', type: null },
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    address: '',
    streetNumber: '',
    streetName: '',
    zip: '',
    unit: '',
    utilityProgramName: '',
    pageUrl: '',
    storyblokStory: '',
  },
  enrollmentId: '',
};

const initialInfoJourneyOption = {
  success_message_title: null,
  success_message_body: null,
  show_program_overview: false,
  show_terms_and_conditions: false,
  send_email: false,
  submit_type: SubmitType['NONE'],
  journey_option_title: '',
  _uid: '',
  page: 'LEAD_FORM' as PageTypes,
  question_type_options: null,
  information: initialInfo,
  journeyOptions: [
    {
      name: '',
      label: '',
      value: '',
    },
  ],
};

const LeadFormConfigContextInitial: IPageConfigContext = {
  page: components['LEAD_FORM'],
  journeyFlowConfig: initialInfoJourneyOption,
  setJourneyFlowConfig: () => {},
  storyName: '',
  pageStyles: {
    primaryColor: '',
    errorColor: '',
    disabledFillColor: '',
    disabledFontColor: '',
    baseFillColor: '',
    fontColor: '',
  },
};

const handleBack = (props: JourneyFlowType) => {
  switch (props.page) {
    case 'TERMS_AND_CONDITIONS':
      if (props.show_program_overview) return { ...props, page: 'PROGRAM_OVERVIEW' as PageTypes };
      else if (props.question_type_options?.length !== 0)
        return { ...props, page: 'QUESTION' as PageTypes };
      else return { ...props, page: 'JOURNEY_START' as PageTypes };

    case 'PROGRAM_OVERVIEW':
      if (props.question_type_options?.length !== 0)
        return { ...props, page: 'QUESTION' as PageTypes };
      else return { ...props, page: 'JOURNEY_START' as PageTypes };

    case 'QUESTION':
      return { ...props, page: 'JOURNEY_START' as PageTypes };

    case 'JOURNEY_START':
      return { ...props, page: 'LEAD_FORM' as PageTypes };

    default:
      return { ...props, page: 'DUPE_CHECK' as PageTypes };
  }
};

const callApi = async (props: JourneyFlowType) => {
  const payload = props.information.formData;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { streetName, streetNumber, unit, ...rest } = payload;

  const journeyOptions = props.journeyOptions;

  await leads.upsertGenericLead({
    lead: rest,
    journeyId: props.submit_type,
    journeyOptions,
  });
};

const callApiComplete = async (props: JourneyFlowType) => {
  await leads.completeGenericLead(props.information.enrollmentId);
};

const handleNext = (props: JourneyFlowType) => {
  switch (props.page) {
    case 'JOURNEY_START':
      callApi(props);
      if (props.question_type_options?.length !== 0)
        return { ...props, page: 'QUESTION' as PageTypes };
      else if (props.show_program_overview)
        return { ...props, page: 'PROGRAM_OVERVIEW' as PageTypes };
      else if (props.show_terms_and_conditions)
        return { ...props, page: 'TERMS_AND_CONDITIONS' as PageTypes };
      else {
        callApiComplete(props);
        return { ...props, page: 'SUCCESS_MESSAGE' as PageTypes };
      }

    case 'QUESTION':
      callApi(props);
      if (props.show_program_overview) return { ...props, page: 'PROGRAM_OVERVIEW' as PageTypes };
      else if (props.show_terms_and_conditions)
        return { ...props, page: 'TERMS_AND_CONDITIONS' as PageTypes };
      else {
        callApiComplete(props);
        return { ...props, page: 'SUCCESS_MESSAGE' as PageTypes };
      }

    case 'PROGRAM_OVERVIEW':
      if (props.show_terms_and_conditions)
        return { ...props, page: 'TERMS_AND_CONDITIONS' as PageTypes };
      else {
        callApiComplete(props);
        return { ...props, page: 'SUCCESS_MESSAGE' as PageTypes };
      }
    case 'TERMS_AND_CONDITIONS':
      callApiComplete(props);
      return { ...props, page: 'SUCCESS_MESSAGE' as PageTypes };

    default:
      callApi(props);
      return { ...props, page: 'SUCCESS_MESSAGE' as PageTypes };
  }
};

const handleInformation = (state, action) => {
  switch (action.type) {
    case 'QUESTION':
      return { ...state.information, question: action.payload };
    case 'TERMS_AND_CONDITIONS':
      return { ...state.information, termsAndConditions: action.payload };
    case 'JOURNEY_START':
      return { ...state.information, journeyPath: action.payload };
    case 'LEAD_FORM':
      return { ...state.information, formData: action.payload };
    case 'ENROLLMENT_ID':
      return { ...state.information, enrollmentId: action.payload };
    default:
      return state.information;
  }
};

const journeyFlowReducer = (state: JourneyFlowType, action: ActionTypeJounery) => {
  const updatedData = { ...state, ...(action.payload || {}) };

  let alternedState = state;

  switch (action.type) {
    case 'UPDATE':
      return { ...updatedData };
    case 'SET_PAGE':
      return { ...updatedData };
    case 'HANDLE_BACK':
      alternedState = handleBack(updatedData);
      return alternedState;

    case 'HANDLE_NEXT':
      alternedState = handleNext(updatedData);
      return alternedState;

    case `INFORMATION`:
      alternedState = handleInformation(state, action.payload);

      return { ...state, information: alternedState };

    default:
      return state;
  }
};

const LeadFormContext = createContext(LeadFormConfigContextInitial);

export const LeafFormContextProvider = ({ children, storyName, pageStyles }: Props) => {
  const [journeyFlowConfig, setJourneyFlowConfig] = useReducer(
    journeyFlowReducer,
    initialInfoJourneyOption
  );

  return (
    <LeadFormContext.Provider
      value={{
        page: components[journeyFlowConfig?.page || 'LEAD_FORM'],
        journeyFlowConfig: journeyFlowConfig as JourneyOptionProps & {
          page: PageTypes;
          information?: StateTypes;
          journeyOptions: JourneyOptionsType;
        },
        setJourneyFlowConfig,
        storyName,
        pageStyles,
      }}
    >
      {children}
    </LeadFormContext.Provider>
  );
};

export const useLeadForm = () => {
  const context = useContext(LeadFormContext);

  if (context === undefined) {
    throw new Error('useLeadForm must be used within a LeafFormContextProvider');
  }

  return context;
};
