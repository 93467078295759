import tw, { TwStyle } from 'twin.macro';

export type NxRichTextLineHeight = 'none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose';

const NxLineHeightMap: Record<NxRichTextLineHeight, TwStyle> = {
  none: tw`leading-none md:leading-none lg:leading-none`,
  tight: tw`leading-tight md:leading-tight lg:leading-tight`,
  snug: tw`leading-snug md:leading-snug lg:leading-snug`,
  normal: tw`leading-normal md:leading-normal lg:leading-normal`,
  relaxed: tw`leading-relaxed md:leading-relaxed lg:leading-relaxed`,
  loose: tw`leading-loose md:leading-loose lg:leading-loose`,
};

export default NxLineHeightMap;
