import {
  ExternalResourceType,
  QuestionProps,
  SourceField,
} from '../components/NxJourneyLeadForm/LeadFormTypes';
import { getAssetProductName } from '../services/leads';

import { toCamelCase } from './toCamelCase';

interface Props extends Partial<QuestionProps> {
  answer: string;
  gateway_asset_product_id: QuestionProps['sbx_gateway_asset_product_id'];
  isProd: boolean;
}

export const getSourceSystemValues = async ({
  label,
  answer,
  map_value_to_source_system,
  source,
  source_field,
  gateway_asset_product_id,
  isProd,
}: Props) => {
  const data = [
    {
      name: map_value_to_source_system
        ? `${source}:${source_field}`
        : `Question-${toCamelCase(label)}`,
      label,
      value: answer,
    },
  ];

  // adding product id along with gatway serial number
  if (
    map_value_to_source_system &&
    source === ExternalResourceType.WAVEGS &&
    source_field === SourceField.GATEWAY_ASSET_SERIAL_NUMBER
  ) {
    const gatewayProductName = await getAssetProductName(isProd, gateway_asset_product_id);
    data.push({
      name: `${source}:${SourceField.GATEWAY_ASSET_PRODUCT_ID}`,
      value: gateway_asset_product_id,
      label: gatewayProductName,
    });
  }
  return data;
};
