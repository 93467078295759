import React from 'react';

import { StoryBlokLinkStory } from './StoryBlokLink';

const GetUrl = (input: string) => {
  if (
    input.startsWith('http://') ||
    input.startsWith('https://') ||
    input.startsWith('tel:') ||
    input.startsWith('sms:') ||
    input.startsWith('//') ||
    input.startsWith('#') ||
    input.startsWith('/')
  ) {
    return input;
  }

  return '/' + input;
};

interface Props {
  to?: string | StoryBlokLinkStory;
  handleClick?: (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  openInNewWindow?: boolean;
  children: React.ReactNode;
}

const withLinkWrap =
  <P extends Record<string, unknown>>(InternalHandler, ExternalHandler) =>
  (props: Props & P) => {
    const { to, handleClick, openInNewWindow, ...elementProps } = props;

    let linkTo = '#';

    if (typeof to === 'string') {
      linkTo = GetUrl(to);
    } else if (to && typeof to === 'object') {
      linkTo = GetUrl(to.cached_url || '#');
    }

    if (linkTo === '/home') {
      linkTo = '/';
    }

    if (linkTo.startsWith('/_LANDING_PAGE_')) {
      linkTo = linkTo.replace('/_LANDING_PAGE_', '');
    }

    const handleClickWrap = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (
        evt.currentTarget &&
        evt.currentTarget.attributes &&
        evt.currentTarget.attributes?.getNamedItem('href')?.value &&
        evt.currentTarget.attributes?.getNamedItem('href')?.value?.startsWith('#')
      ) {
        const targetId = evt.currentTarget.attributes.getNamedItem('href').value.substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          const headerOffset = 150;
          const targetPosition = targetElement.getBoundingClientRect().top;

          const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

          if (isSmoothScrollSupported) {
            window.scrollBy({
              top: targetPosition - headerOffset,
              behavior: 'smooth',
            });
          } else {
            window.scrollBy(0, targetPosition - headerOffset);
          }

          evt.preventDefault();
        }
      }

      if (handleClick) {
        return handleClick(evt);
      }
    };

    if (linkTo.startsWith('/') && !linkTo.startsWith('//')) {
      return <InternalHandler onClick={handleClickWrap} to={linkTo} {...elementProps} />;
    } else {
      if (openInNewWindow) {
        return (
          <ExternalHandler
            onClick={handleClickWrap}
            href={linkTo}
            target="_blank"
            {...elementProps}
          />
        );
      } else {
        return <ExternalHandler onClick={handleClickWrap} href={linkTo} {...elementProps} />;
      }
    }
  };

export default withLinkWrap;
