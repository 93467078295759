import { Form } from 'formik';
import tw, { css, styled } from 'twin.macro';

import { useLeadForm } from '../../hooks/useLeadForm';
import { ButtonStyles } from '../NxButtonLink/ButtonContainer';
import { ButtonTheme } from '../NxCommon';

interface ContainerProps {
  textColor: 'light' | 'dark';
}

export const Container = styled.div(({ textColor }: ContainerProps) => [
  textColor === 'light' ? tw`text-white` : tw`text-swell-gray`,
]);

export const StyledForm = styled(Form)(() => [tw`flex flex-col space-y-6`]);
export const CheckboxContainer = tw.div`ml-3`;
export const CheckboxLabel = tw.div`flex flex-col pl-2`;
export const CheckboxLabelText = tw.span`flex-grow text-xs leading-snug font-sans font-bold`;
export const InputsContainer = tw.div`flex flex-col`;
export const FieldError = styled.span<{ theme?: 'light' | 'dark'; errorColor?: string }>(
  (props) => [
    tw`font-sans text-xs`,
    props.errorColor
      ? css`
          color: ${props.errorColor};
        `
      : props.theme === 'light'
      ? tw`text-swell-error-light-theme font-normal`
      : tw`text-red-800 font-bold`,
  ]
);

interface SubmitButtonProps {
  theme: ButtonTheme;
  disabled: boolean;
}

export const SubmitButton = styled.button(({ theme, disabled }: SubmitButtonProps) => {
  const {
    pageStyles: { baseFillColor, fontColor, disabledFillColor, disabledFontColor },
  } = useLeadForm();

  return ButtonStyles({
    theme,
    size: 'normal',
    horizontalAlign: 'center',
    width: '280px',
    disabled,
    ...{ baseFillColor, fontColor, disabledFillColor, disabledFontColor },
  });
});

export const Disclaimer = tw.div`text-xs text-gray-400 w-72 mx-auto text-justify md:(w-auto max-w-xl)`;
