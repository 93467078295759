import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'react-feather';
import tw, { styled } from 'twin.macro';

import useScrollBottom from '../../../hooks/useScrollBottom';

const Backdrop = styled.div(() => [
  tw`fixed bottom-0 left-0 h-screen w-screen bg-white flex items-center justify-center z-20`,
  'height: -webkit-fill-available;', //Use -webkit-fill-available to handle safe areas if the browser supports it
  'overscroll-behavior: contain;',
  'overflow-y: scroll;',
]);
const ModalShim = tw.div`w-[1px]  bg-transparent absolute top-0 left-0`;
const Modal = tw.div`bg-white flex flex-col items-stretch h-full flex-grow-0 w-full xl:(w-full h-full rounded-lg border border-gray-200 max-w-[98%] max-h-[96%])`;
const Content = tw.div`overflow-y-auto flex md:m-4 items-stretch flex-grow p-4 md:p-0`;
const CloseWrapper = tw.div`text-xl border-b border-b-gray-200 px-4 py-2 text-center flex flex-row-reverse justify-between items-center `;
const CloseIcon = styled(X)(() => [tw`cursor-pointer`]);

interface Props {
  content: string;
  onSubmit: () => void;
  onClose: () => void;
}

export default function TermsAndConditionsModal({ content, onSubmit, onClose }: Props) {
  const parentRef = useRef();

  const { reachedEnd } = useScrollBottom(false, parentRef);

  useEffect(() => {
    if (reachedEnd) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reachedEnd]);

  return createPortal(
    <Backdrop>
      <ModalShim />
      <Modal data-modal>
        <CloseWrapper>
          <CloseIcon onClick={onClose} />
          Terms & Conditions
        </CloseWrapper>
        <Content ref={parentRef}>{content}</Content>
      </Modal>
    </Backdrop>,
    document.body
  );
}
