import React from 'react';
import tw from 'twin.macro';

import { useLeadForm } from '../../../hooks/useLeadForm';
import { getComponentsForBlokArray } from '../../NxComponents';
import { ContentProps, JourneyOptionProps } from '../LeadFormTypes';

const Wrapper = tw.div`bg-white max-w-[548px] rounded-md m-auto px-8 py-10 md:px-16 md:py-20 flex flex-col max-w-[456px] items-center justify-center mt-8 mb-24`;
const MessageTitle = tw.div`mb-10`;
const MessageBody = tw.div`mb-8`;
const ErrorText = tw.p`text-red-500`;

export const SuccessMessage = ({ journey_option }: ContentProps) => {
  const {
    journeyFlowConfig: { _uid },
  } = useLeadForm();

  const selectedJourney = journey_option?.find((el) => el._uid === _uid) as JourneyOptionProps;

  return (
    <Wrapper>
      {selectedJourney ? (
        <>
          <MessageTitle>
            {!!selectedJourney?.success_message_title &&
              getComponentsForBlokArray(selectedJourney?.success_message_title)}
          </MessageTitle>

          <MessageBody>
            {!!selectedJourney?.success_message_body &&
              getComponentsForBlokArray(selectedJourney?.success_message_body)}
          </MessageBody>
        </>
      ) : (
        <ErrorText>Journey option not found.</ErrorText>
      )}
    </Wrapper>
  );
};
