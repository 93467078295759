import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import tw, { styled } from 'twin.macro';

import { AgreementTextContainer } from './AgreementTextContainer';

const BlueTheme = tw`
  bg-swell-blue text-white border-2 border-solid border-swell-blue
  hover:(bg-swell-gray text-gray-100 border-swell-gray)
  disabled:(bg-swell-gray-light text-gray-100 border-swell-gray-light cursor-not-allowed)
`;

const WhiteTheme = tw`
  bg-white  text-swell-blue border-2 border-solid border-swell-blue
  hover:bg-gray-100 hover:text-swell-gray hover:border-swell-gray hover:no-underline
`;

const Backdrop = styled.div(() => [
  tw`fixed bottom-0 left-0 h-screen w-screen bg-white flex items-center justify-center z-20`,
  'height: -webkit-fill-available;', //Use -webkit-fill-available to handle safe areas if the browser supports it
  'overscroll-behavior: contain;',
  'overflow-y: scroll;',
]);
// Give the backdrop something >screen size to scroll so we don't overscroll the body
const ModalShim = tw.div`w-[1px]  bg-transparent absolute top-0 left-0`;
const Modal = tw.div`bg-white flex flex-col items-stretch h-full flex-grow-0 w-full xl:(w-1/2 h-1/2 rounded-sm border border-black)`;
const Content = tw.div`overflow-y-auto flex md:m-4 items-stretch flex-grow`;
const ScrollCallout = tw.div`bg-gray-200 px-4 py-1 text-xs text-center flex-shrink-0`;
const Footer = tw.div`flex flex-col space-y-4 md:(flex-row space-y-0 space-x-4) bg-gray-200 p-4 items-stretch flex-shrink-0`;
const TermsCheckContainer = tw.label`md:w-1/2 flex flex-row items-start space-x-2`;
const TermsCheckCaption = tw.span`text-xs`;
const AcceptButton = styled.button(() => [tw`md:w-1/4`, BlueTheme]);
const CancelButton = styled.button(() => [tw`md:w-1/4`, WhiteTheme]);
const PrivacyLink = styled(Link)(() => [
  tw`text-swell-blue cursor-pointer transition-colors hover:(text-swell-blue-light underline)`,
]);
const CheckBox = tw.input`h-4 w-4 min-w-[1rem] min-h-[1rem]`;

interface Props {
  content: string;
  onAccept: () => void;
  onCancel: () => void;
}

export default function AgreementModal({ content, onAccept, onCancel }: Props) {
  const [readTerms, setReadTerms] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleScroll = useCallback((evt: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = evt.currentTarget;

    const distanceFromBottom = Math.abs(scrollTop + clientHeight - scrollHeight);

    if (distanceFromBottom < 100) {
      setReadTerms(true);
    }
  }, []);
  // Portal the modal out
  return createPortal(
    <Backdrop>
      <ModalShim />
      <Modal data-modal>
        <Content onScroll={handleScroll}>
          <AgreementTextContainer>{content}</AgreementTextContainer>
        </Content>
        <ScrollCallout>
          {!readTerms && (
            <span>You must read this entire agreement and scroll to the bottom to continue.</span>
          )}
          {readTerms && !agree && (
            <span>You must agree to the terms and privacy policy to continue.</span>
          )}
        </ScrollCallout>
        <Footer>
          <TermsCheckContainer>
            <CheckBox
              disabled={!readTerms}
              type="checkbox"
              onChange={(e) => setAgree(e.target.checked)}
              checked={agree}
            />
            <TermsCheckCaption>
              Please acknowledge that you&apos;ve read the Terms and Conditions. By clicking
              &lsquo;Accept&rsquo;, you agree to these <strong>TERMS AND CONDITIONS</strong> and
              acknowledge the <PrivacyLink to="/privacy-policy">PRIVACY POLICY</PrivacyLink>. A copy
              of these Terms and Conditions will be emailed to the address you provided.
            </TermsCheckCaption>
          </TermsCheckContainer>
          <AcceptButton disabled={!agree} type="button" onClick={onAccept}>
            Accept
          </AcceptButton>
          <CancelButton type="button" onClick={onCancel}>
            Cancel
          </CancelButton>
        </Footer>
      </Modal>
    </Backdrop>,
    document.body
  );
}
