import React from 'react';
import SbEditable from 'storyblok-react';

import { LeafFormContextProvider } from '../../hooks/useLeadForm';
import { useEnvironment } from '../EnvironmentContext';
import { NxBaseProps } from '../NxBaseProps';

import { BlokProps } from './LeadFormTypes';
import LeadFormContent from './LeadFormWrapper';

export default function NxJourneyLeadForm(props: NxBaseProps<BlokProps>) {
  const { storyName, pageStyles } = useEnvironment();

  return (
    <LeafFormContextProvider storyName={storyName} pageStyles={pageStyles}>
      <SbEditable content={props.blok}>
        <LeadFormContent blok={{ ...props.blok }} />
      </SbEditable>
    </LeafFormContextProvider>
  );
}
