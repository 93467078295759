import { graphql, Link, StaticQuery } from 'gatsby';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { navItemsWithPowerProItems } from '../../utils/getPowerProFields';
import { BaseBlok } from '../NxBaseProps';
import { QueryResult } from '../NxGraphQL';
import { StoryBlokLink } from '../StoryBlokLink';
import withLinkWrap from '../withLinkWrap';

import { PowerProTypes } from './NxPage';

interface MenuItemJSON {
  content: string;
}

export interface NavItemBlokProps extends BaseBlok {
  component: 'nav_item' | 'spacer';
  name: string;
  link?: StoryBlokLink;
  height?: string;
  accent?: boolean;
  sbx_link?: StoryBlokLink;
  enabled: boolean;
}

const query = graphql`
  {
    allStoryblokEntry(filter: { slug: { eq: "global-navi" } }) {
      edges {
        node {
          content
        }
      }
    }
  }
`;

interface ElementProps extends PowerProTypes {
  data: QueryResult<MenuItemJSON>;
}

const MenuContainer = tw.ul`
flex flex-col w-screen h-full fixed top-0 left-0 bg-swell-gray pt-16 overscroll-contain
md:(w-96 py-16 left-auto top-auto static shadow)`;
const MenuItemStyles = tw`px-6 py-5 w-full text-white font-sans  hover:( bg-swell-blue text-white no-underline)`;
const InternalMenuItem = styled(Link)(() => [MenuItemStyles]);
const ExternalMenuItem = styled.a(() => [MenuItemStyles]);
const MenuItem = withLinkWrap(InternalMenuItem, ExternalMenuItem);
const MenuSpacer = styled.div((props: { height: string }) => [`height: ${props.height}rem;`]);
const ListItem = tw.li`flex`;

function NxMenu(props: ElementProps) {
  const globalNaviContent = JSON.parse(props.data.allStoryblokEntry.edges[0].node.content);
  const navItemsInitial: NavItemBlokProps[] = globalNaviContent.nav_items;

  const navItems = useMemo(
    () => navItemsWithPowerProItems({ navItems: navItemsInitial, ...props }),
    [navItemsInitial, props]
  );

  return (
    <MenuContainer onClick={(evt) => evt.stopPropagation()}>
      {navItems.map((v) => {
        if (v.link) {
          return (
            <ListItem key={v._uid}>
              <MenuItem to={v.link.cached_url || v.link.url}>{v.name}</MenuItem>
            </ListItem>
          );
        } else if (v.height) {
          return <MenuSpacer key={v._uid} height={v.height} />;
        } else {
          return <></>;
        }
      })}
    </MenuContainer>
  );
}

// eslint-disable-next-line react/display-name
export default (props: PowerProTypes) => (
  <StaticQuery query={query} render={(data) => <NxMenu data={data} {...props} />} />
);
