import useScrollPosition from '@react-hook/window-scroll';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import tw, { css, styled } from 'twin.macro';

import { ReactComponent as HamburgerMenu } from './assets/bars-solid.svg';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as CloseMenu } from './assets/times-solid.svg';
import NxMenu from './NxMenu';
import { PowerProTypes } from './NxPage';

interface Props {
  phoneNumber: string;
  color: 'white' | 'black';
  includeGradientTreatment: boolean;
  powerProData: PowerProTypes;
}

interface NavBarProps {
  isScrolledDown: boolean;
  isMenuOpen?: boolean;
  includeGradientTreatment?: boolean;
  color: 'white' | 'black';
}

const NavBarContainer = styled.div((props: NavBarProps) => [
  tw`transition-all fixed w-full z-10 top-0`,
  props.includeGradientTreatment
    ? css`
        background: linear-gradient(180deg, rgba(0, 18, 29, 0.2) 0%, rgba(202, 211, 217, 0) 100%);
      `
    : tw`bg-none`,
  props.color === 'white' ? tw`text-white` : '',
  props.color === 'black' ? tw`text-swell-gray` : '',
  props.isScrolledDown
    ? tw`bg-none bg-white shadow-xl border-0 border-b border-solid border-gray-200`
    : '',
]);

const NavBar = tw.div`py-5 left-0 right-0 flex flex-row justify-between transition-all relative px-4`;

const NavLogoLink = styled(Link)((props: NavBarProps) => [
  tw`hover:text-swell-blue z-40`,
  props.color === 'white' ? tw`text-white` : '',
  props.color === 'black' ? tw`text-swell-gray` : '',
  props.isScrolledDown ? tw`text-swell-blue hover:text-swell-blue-dark` : '',
  props.color === 'white' && !props.isScrolledDown ? tw`filter drop-shadow-sm` : '',
]);

const NavLogo = styled(Logo)(() => [tw`h-7 fill-current transition-all`]);

const MenuContainer = tw.div`relative`;
const MenuOverlay = tw.div`z-20 bg-black bg-opacity-75 w-screen h-screen fixed top-0 left-auto right-0`;
const MenuOverlayInner = tw.div`w-full h-full px-4 pr-0 flex justify-end`;

const NavMenuButton = styled.button((props: NavBarProps) => [
  tw`absolute right-0 h-5 w-5 fill-current transition-all outline-none border-0 z-40 hocus:(outline-none border-0)`,
  props.color === 'white' ? tw`text-white hover:text-swell-blue` : '',
  props.color === 'black' ? tw`text-swell-gray hover:text-swell-blue` : '',
  props.isScrolledDown ? tw`text-swell-gray hover:text-swell-blue` : '',
  props.isMenuOpen ? tw`text-white hover:text-swell-blue` : '',
  props.color === 'white' && !props.isScrolledDown ? tw`filter drop-shadow-sm` : '',
]);

const CallLink = styled.a((props: NavBarProps) => [
  tw`mr-8 md:mr-12 filter font-sans hover:text-swell-blue`,
  props.color === 'white' ? tw`text-white` : '',
  props.color === 'black' ? tw`text-swell-gray` : '',
  props.isScrolledDown ? tw`text-swell-gray` : '',
  props.color === 'white' && !props.isScrolledDown ? tw`filter drop-shadow-sm` : '',
]);

export default function NxNavBar(props: Props): React.ReactElement {
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollY = useScrollPosition();
  const phoneNumberParts = /(\d{3})(\d{3})(\d{4})/.exec(props.phoneNumber);
  const phoneNumberFormatted = `(${phoneNumberParts[1]}) ${phoneNumberParts[2]}-${phoneNumberParts[3]}`;

  useEffect(() => {
    setIsScrolledDown(scrollY > 30);
  }, [scrollY]);

  return (
    <NavBarContainer
      isScrolledDown={isScrolledDown}
      color={props.color}
      includeGradientTreatment={props.includeGradientTreatment}
    >
      <NavBar>
        <NavLogoLink
          to="/"
          isScrolledDown={isScrolledDown}
          aria-label="Swell Home"
          color={props.color}
        >
          <NavLogo />
        </NavLogoLink>
        <MenuContainer>
          <CallLink
            isScrolledDown={isScrolledDown}
            href={`tel:${props.phoneNumber}`}
            color={props.color}
          >
            {phoneNumberFormatted}
          </CallLink>
          <NavMenuButton
            isScrolledDown={isScrolledDown}
            isMenuOpen={isMenuOpen}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Menu toggle"
            color={props.color}
          >
            {!isMenuOpen && <HamburgerMenu />}
            {isMenuOpen && <CloseMenu />}
          </NavMenuButton>
          {isMenuOpen && (
            <MenuOverlay onClick={() => setIsMenuOpen(false)}>
              <MenuOverlayInner>
                <NxMenu {...props.powerProData} />
              </MenuOverlayInner>
            </MenuOverlay>
          )}
        </MenuContainer>
      </NavBar>
    </NavBarContainer>
  );
}
