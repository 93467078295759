import { FormikProps } from 'formik';
import React from 'react';
import InputMask from 'react-input-mask';
import tw, { css, styled } from 'twin.macro';

import { InputContainer, Label } from './Common';

interface InputProps {
  isError: boolean;
}

const InputStyles = (props: InputProps) => [
  tw`rounded-xl p-2 w-full font-sans`,
  props.isError
    ? tw`border border-solid border-red-500`
    : css`
        border: 1px solid rgba(57, 60, 65, 0.3);
      `,
  tw`focus:border-swell-blue hocus:outline-none`,
  tw`disabled:opacity-50`,
];

const Input = styled.textarea<InputProps>((props) => InputStyles(props));
const MaskedInput = styled(InputMask)<InputProps>((props) => InputStyles(props));

interface Props<TFormData> {
  formik: FormikProps<TFormData>;
  id: string;
  name: string;
  required?: boolean;
  label?: string;
  type?: string;
  placeholder?: string;
  mask?: string;
  rows?: number;
}

export default function NxLeadFormTextArea<TFormData>(props: Props<TFormData>) {
  const { formik, id, name, required, label, type, placeholder, rows } = props;
  const inputProps = {
    id,
    name,
    value: formik.values[name] || '',
    required,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    isError: formik.touched[name] && Boolean(formik.errors[name]),
    disabled: formik.isSubmitting,
    type,
    placeholder,
    rows,
  };

  return (
    <InputContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      {props.mask ? (
        <MaskedInput mask={props.mask} alwaysShowMask={false} {...inputProps} />
      ) : (
        <Input {...inputProps} />
      )}
    </InputContainer>
  );
}
