import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { GlobalStyles } from 'twin.macro';

import GothamBookEot from '../../fonts/Gotham-Book.eot';
import GothamBookSvg from '../../fonts/Gotham-Book.svg';
import GothamBookTtf from '../../fonts/Gotham-Book.ttf';
import GothamBookWoff from '../../fonts/Gotham-Book.woff';
import GothamBookWoff2 from '../../fonts/Gotham-Book.woff2';
import SharpSansEot from '../../fonts/SharpSansNo1-Bold.eot';
import SharpSansSvg from '../../fonts/SharpSansNo1-Bold.svg';
import SharpSansTtf from '../../fonts/SharpSansNo1-Bold.ttf';
import SharpSansWoff from '../../fonts/SharpSansNo1-Bold.woff';
import SharpSansWoff2 from '../../fonts/SharpSansNo1-Bold.woff2';

const CustomStyles = createGlobalStyle`
@font-face {
  font-family: "Gotham Book";
  src: url("${GothamBookEot}");
  src: url("${GothamBookEot}?#iefix") format("embedded-opentype"),
       url("${GothamBookWoff2}") format("woff2"),
       url("${GothamBookWoff}") format("woff"),
       url("${GothamBookTtf}") format("truetype"),
       url("${GothamBookSvg}#Gotham-Book") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: fallback;
  unicode-range: U+0020-00FE;
}
@font-face {
  font-family:'sharpsans-bold';
  src: url("${SharpSansEot}");
  src: url("${SharpSansEot}?#iefix") format("embedded-opentype"),
       url("${SharpSansWoff2}") format("woff2"),
       url("${SharpSansWoff}") format("woff"),
       url("${SharpSansTtf}") format("truetype"),
       url("${SharpSansSvg}#SharpSansNo1-Bold") format("svg");
  font-display: fallback;
 }
`;

export default function NxStyles() {
  return (
    <>
      <GlobalStyles />
      <CustomStyles />
    </>
  );
}
