import React from 'react';
import tw from 'twin.macro';

import { useLeadForm } from '../../../hooks/useLeadForm';
import { getComponentsForBlokArray } from '../../NxComponents';
import { ContentProps, JourneyOptionProps } from '../LeadFormTypes';

import { BackButton } from './BackButton';

const Wrapper = tw.div`bg-white max-w-[548px] rounded-md m-auto px-8 py-10 md:px-16 md:py-20 flex flex-col max-w-[456px] justify-center mt-8 mb-24`;
const HeadWrapper = tw.div`mb-14 flex  items-center justify-center relative`;
const ButtonItem = tw.div`border-swell-blue bg-[#0092FF20]  border rounded-xl p-2 w-full cursor-pointer `;
const ErrorText = tw.p`text-red-500`;

export const Question = ({ journey_option }: ContentProps) => {
  const {
    journeyFlowConfig: { _uid },
  } = useLeadForm();

  const selectedJourney = journey_option?.find((el) => el._uid === _uid) as JourneyOptionProps;

  return (
    <Wrapper>
      <HeadWrapper>
        <BackButton />
        {!!selectedJourney.question_title &&
          getComponentsForBlokArray(selectedJourney.question_title)}
      </HeadWrapper>
      {!!selectedJourney.question_input_title &&
        getComponentsForBlokArray(selectedJourney.question_input_title)}

      {selectedJourney ? (
        <>
          <ButtonItem>{selectedJourney?.journey_option_title}</ButtonItem>
          {selectedJourney?.question_type_options &&
            getComponentsForBlokArray(selectedJourney?.question_type_options)}
        </>
      ) : (
        <ErrorText>Journey option not found.</ErrorText>
      )}
    </Wrapper>
  );
};
