import React, { useEffect, useRef, useState } from 'react';
import { ExternalLink } from 'react-feather';
import tw, { styled } from 'twin.macro';

import { useLeadForm } from '../../../hooks/useLeadForm';
import useScrollBottom from '../../../hooks/useScrollBottom';
import { getComponentsForBlokArray } from '../../NxComponents';
import { SubmitButton } from '../../NxLeadForm/styles';
import { ContentProps } from '../LeadFormTypes';

import { BackButton } from './BackButton';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const Wrapper = tw.form`bg-white max-w-[548px] rounded-md m-auto px-8 pt-10 md:px-16 md:py-20 flex flex-col max-w-[456px] justify-center mt-8 md:mb-24`;
const ErrorMessage = tw.div`p-2 text-center text-red-500 lg:w-96`;
const HeadWrapper = tw.div`mb-10 flex  items-center justify-center relative`;
const Title = tw.p`text-gray-600 mb-4 flex items-center`;
const ScrollableDiv = tw.div`bg-gray-100  pb-0 rounded-lg max-h-[256px] overflow-auto relative `;
const TransparentDiv = tw.div`sticky bottom-0 w-full h-12 left-0 from-[#f2f2f2] bg-gradient-to-t`;
const ContentWrapper = tw.div`p-4 pb-2 overflow-x-hidden`;
const HintText = tw.div`pt-2 flex items-end justify-center text-xs text-gray-400`;
const CheckWrapper = tw.div`flex mt-6 mb-8 `;
const Checkbox = tw.input`mr-2 min-w-[1rem] min-h-[1rem] w-[1rem] h-[1rem] mt-[4px]`;
const ExternalLinkIcon = styled(ExternalLink)(() => [tw`ml-1 cursor-pointer`]);

export const TermsAndConditions = ({
  isError,
  t_c_content,
  t_c_legal_verbiage,
  t_c_title,
  buttonText,
  submitting_button_text,
}: ContentProps) => {
  const { setJourneyFlowConfig, journeyFlowConfig } = useLeadForm();
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const parentRef = useRef<null | HTMLDivElement>(null);
  const { reachedEnd } = useScrollBottom(
    journeyFlowConfig?.information?.termsAndConditions,
    parentRef
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    setJourneyFlowConfig({ type: 'HANDLE_NEXT' });
  };

  const handleReachEnd = () => {
    setDisabled(false);

    setJourneyFlowConfig({
      payload: { type: 'TERMS_AND_CONDITIONS', payload: true },
      type: 'INFORMATION',
    });
  };

  useEffect(() => {
    if (reachedEnd) {
      handleReachEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reachedEnd]);

  return (
    <>
      <Wrapper onSubmit={onSubmit}>
        <HeadWrapper>
          <BackButton />
          {getComponentsForBlokArray(t_c_title)}
        </HeadWrapper>
        <Title>
          Terms & conditions <ExternalLinkIcon width={16} onClick={() => setShowTerms(true)} />
        </Title>
        <ScrollableDiv ref={parentRef} id="content">
          <ContentWrapper>{getComponentsForBlokArray(t_c_content)}</ContentWrapper>

          {!reachedEnd && <TransparentDiv />}
        </ScrollableDiv>
        <HintText>Scroll through to proceed</HintText>
        <CheckWrapper onClick={() => (disabled ? {} : setChecked((pre) => !pre))}>
          <Checkbox disabled={disabled} checked={checked} type="checkbox" />
          {getComponentsForBlokArray(t_c_legal_verbiage)}
        </CheckWrapper>
        {isError && (
          <ErrorMessage>We had a problem processing your request. Please try again.</ErrorMessage>
        )}

        <SubmitButton disabled={!checked || isLoading}>
          {isLoading ? submitting_button_text : buttonText}
        </SubmitButton>
      </Wrapper>
      {showTerms && (
        <TermsAndConditionsModal
          content={getComponentsForBlokArray(t_c_content) as unknown as string}
          onClose={() => setShowTerms(false)}
          onSubmit={handleReachEnd}
        />
      )}
    </>
  );
};
