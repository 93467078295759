import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import { CustomerNotesOrUTMReferrer } from '../components/NxLeadForm/NxLeadForm';

import { getApiBaseUrl } from './config';

const LeadSubmitUrlProd = 'https://0elsany8nl.execute-api.us-east-1.amazonaws.com/prod/';
const LeadSubmitUrlDev = 'https://flq29n1ytk.execute-api.us-east-1.amazonaws.com/default/';
const ApiUrlDev = 'https://www-api.dev.swellenergy.com/products/';
const ApiUrlProd = 'https://www-api.swellenergy.com/products/';
const assetProductUrlDev = ApiUrlDev + 'gateways';
const assetProductUrlProd = ApiUrlProd + 'gateways';
const RecordTypeResidentialDev = '0124M000000MeAM';
const RecordTypeResidentialProd = '0124M000000MeAM';
const RecordTypeCommercialDev = '0124M000000MeAb';
const RecordTypeCommercialProd = '0124M000000MeAb';
const RecordTypeCXDev = '0124M000000MeAW';
const RecordTypeCXProd = '0124M000000MeAW';
const LeadSourceDev = 'Swell Marketing';
const LeadSourceProd = 'Swell Marketing';

interface AllLeadFields {
  firstName?: string;
  lastName?: string;
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  email?: string;
  utility?: string;
  customerType?: 'commercial' | 'cx' | 'residential';
  existingSolar?: string | boolean;
  homeSize?: string;
  tribe?: string;
  company?: string;
  notes?: string;
  isHomeowner?: string | boolean;
  hasSmartMeter?: string | boolean;
  hasPowerwall?: string | boolean;
  utmContent?: string;
  leadSourceAccount?: string;
  owner?: string;
  referralFirstName?: string;
  referralLastName?: string;
  referralPhone?: string;
  referralEmail?: string;
  promotion?: string;
  isUtilityCustomer?: string | boolean;
  isInterestedInBattery?: string | boolean;
  isInterestedInSolarBattery?: string | boolean;
  hasReceivedUtilityEmail?: string | boolean;
  interest?: string;
  originalInstaller?: string;
  customerNotesOrUtmReferrer?: CustomerNotesOrUTMReferrer;
}

interface WaveGSLead {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  cellPhone: string;
  salesPartnerId: string;
  installationPartnerId: string;
  utilityId: string | undefined;
  utilityProgramId: string;
  isVPPEasyEnroll: boolean;
  vppEasyEnrollContractPDF?: string | undefined;
  vppEasyEnrollTemplate?: string | undefined;
  pageUrl?: string | undefined;
}

interface WaveGSAcceptVPPTerms {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  utilityProgramId: string;
  vppEasyEnrollContractPDF?: string | undefined;
  vppEasyEnrollTemplate?: string | undefined;
}

interface PartnerLead {
  interests?: string[];
  company?: string;
  website?: string;
  address?: string;
  zip?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  description?: string;
  acceptTerms?: string;
  utility_type?: string;
  referred_by?: string;
  partner_id?: string;
  type?: string;
  service_area?: string;
  content_id: string;
}

interface GenericLead {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city?: string;
  state?: string;
  zip: string;
  utilityProgramName: string;
  utilityName?: string;
  salesPartnerId?: string;
  installationPartnerId?: string;
  pageUrl?: string;
  storyblokStory?: string;
}

export interface GenericLeadJourneyOption {
  name: string;
  value: string | number | boolean;
  label?: string;
}

const leads = {
  async submitLead(input: AllLeadFields) {
    const submitData: any = {};

    if (input.name) {
      submitData.name = [input.name];
    } else if (input.firstName || input.lastName) {
      submitData.name = [`${input.firstName || ''} ${input.lastName || ''}`];
    }

    const gen = [...Array(32)].map(() => Math.random().toString(36)[2]).join('');

    try {
      if (window.localStorage) {
        window.localStorage.setItem('__seafid', window.localStorage.getItem('__seafid') || gen);
        Cookies.set('__seafid', window.localStorage.getItem('__seafid') || gen, { expires: 365 });
      } else {
        Cookies.set('__seafid', Cookies.get('__seafid') || gen, { expires: 365 });
      }
    } catch {
      try {
        Cookies.set('__seafid', Cookies.get('__seafid') || gen, { expires: 365 });
      } catch {
        // eslint-disable-line no-empty
        // Doesn't matter. It was best effort.
      }
    }

    // Hotfix: spaces causing parse errors in the Lambda
    submitData.name[0] = submitData.name[0].trim();

    const isProd =
      window.location.origin.indexOf('swellenergy.com') > -1 &&
      window.location.origin.indexOf('sbx') === -1;
    const now = new Date();
    const ampm = now.getUTCHours() > 12 ? 'PM' : 'AM';
    submitData.date_submitted = [now.toISOString().substring(0, 10)];
    submitData.time_submitted = [
      now.getUTCHours() -
        (ampm === 'PM' ? 12 : 0) +
        ':' +
        now.getUTCMinutes() +
        ' ' +
        ampm +
        ' UTC',
    ];
    submitData.company = [input.company || 'N/A'];
    submitData.utmContent = [input.utmContent];
    submitData.lead_source_account = [input.leadSourceAccount];
    submitData.lead_source_medium = [''];
    submitData.lead_source = [isProd ? LeadSourceProd : LeadSourceDev];
    submitData.page_url = [window.location.origin + window.location.pathname];
    submitData.street_address = [input.address || ''];
    submitData.city = [input.city || ''];
    submitData.state = [input.state || ''];
    submitData.zip_code = [input.zip || ''];
    submitData.phone_number = [input.phone || ''];
    submitData.email = [input.email || ''];
    submitData.utility_company = [''];
    submitData.home_size = [input.homeSize || ''];
    submitData.property_owner = [
      input.isHomeowner === true || input.isHomeowner === 'Yes' ? 'true' : 'false',
    ];
    submitData.ip_address = [''];
    submitData.energy_program = [''];
    submitData.variant = [''];
    submitData.utility_customer = [!!input.isUtilityCustomer];
    submitData.existing_solar = [!!input.existingSolar];
    submitData.interested_in_solar_and_battery = [!!input.isInterestedInSolarBattery];
    submitData.interested_in_battery = [!!input.isInterestedInBattery];
    submitData.interest = [input.interest || ''];
    submitData.email_from_utility = [!!input.hasReceivedUtilityEmail];
    submitData.audit_url_full = [document.location.toString()];
    submitData.audit_url_referrer = [document.referrer];
    submitData.audit_user_agent = [window.navigator?.userAgent || ''];

    const deviceType = /iPad/.test(navigator.userAgent)
      ? 'Tablet'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
      ? 'Mobile'
      : 'Desktop';

    submitData.utm_device_type = [deviceType];

    let seafid = 'unsupported';

    try {
      seafid = window.localStorage.getItem('__seafid');
    } catch {
      try {
        seafid = Cookies.get('__seafid');
      } catch {} // eslint-disable-line no-empty
    }

    submitData.audit_seafid = [seafid];

    if (typeof input.hasPowerwall === 'boolean') {
      submitData.existing_powerwall = [input.hasPowerwall];
    }

    if (typeof input.hasSmartMeter === 'boolean') {
      submitData.has_smart_meter = [input.hasSmartMeter];
    }

    if (input.tribe) {
      submitData.notes = ['TribeName: ' + input.tribe + ' Notes: ' + (input.notes || '')];
    } else {
      if (input.customerNotesOrUtmReferrer === CustomerNotesOrUTMReferrer.UTM_Referrer)
        submitData.audit_url_referrer = [input.notes || ''];
      else submitData.notes = [input.notes || ''];
    }

    if (input.existingSolar === false || input.existingSolar === 'No') {
      submitData.want_solar = [true];
    } else {
      submitData.want_solar = [false];
    }

    if (input.owner) {
      submitData.owner = [input.owner];
    }

    if (input.customerType) {
      const resiType = isProd ? RecordTypeResidentialProd : RecordTypeResidentialDev;
      const commType = isProd ? RecordTypeCommercialProd : RecordTypeCommercialDev;
      const cxType = isProd ? RecordTypeCXProd : RecordTypeCXDev;

      switch (input.customerType) {
        case 'commercial':
          submitData.record_type = [commType];
          break;

        case 'cx':
          submitData.record_type = [cxType];
          break;

        case 'residential':
        default:
          submitData.record_type = [resiType];
          break;
      }
    }

    if (input.referralFirstName || input.referralLastName) {
      let referralName = '';

      if (input.referralFirstName) {
        referralName += input.referralFirstName;
      }

      if (input.referralLastName) {
        if (referralName.length > 0) {
          referralName += ' ';
        }

        referralName += input.referralLastName;
      }

      submitData.referral_name = [referralName];
    }

    if (input.referralPhone) {
      submitData.referral_phone = [input.referralPhone];
    }

    if (input.referralEmail) {
      submitData.referral_email = [input.referralEmail];
    }

    if (input.promotion) {
      submitData.promotion = [input.promotion];
    }

    if (input.originalInstaller) {
      submitData.original_installer = [input.originalInstaller];
    }

    submitData.utmCampaign = [''];

    // Extract UTM fields from the current location if present
    if (window && window.location && window.location.search && window.location.search.length > 0) {
      // Copied from https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript because I am lazy :)
      const queryString = window.location.search;
      const query: any = {};
      const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }

      if (query.utm_medium) {
        submitData.utm_medium = [query.utm_medium];
      }

      if (query.utm_source) {
        submitData.utm_source = [query.utm_source];
      }

      if (query.utm_campaign) {
        submitData.utm_campaign = [query.utm_campaign];
      }

      if (query.utm_term) {
        submitData.utm_term = [query.utm_term];
      }

      if (query.utm_content) {
        submitData.utm_content = [query.utm_content];
      }

      if (query.utm_adgroup) {
        submitData.utm_adgroup = [query.utm_adgroup];
      }

      if (query.utm_area) {
        submitData.utm_area = [query.utm_area];
      }

      if (query.gclid || query.fbclid || query.msclkid) {
        submitData.utm_click_origin = [
          query.gclid
            ? 'Google'
            : '' || query.fbclid
            ? 'Facebook'
            : '' || query.msclkid
            ? 'Microsoft'
            : '',
        ];

        submitData.utm_click_id = [query.gclid || query.fbclid || query.msclkid];
      }
    }

    const requestArgs: RequestInit = {
      headers: { 'content-type': 'application/json; charset=UTF-8' },
      body: 'data.json=' + JSON.stringify(submitData),
      method: 'POST',
      mode: 'cors',
    };

    const fullUrl = (isProd ? LeadSubmitUrlProd : LeadSubmitUrlDev) + 'lead';
    const response = await fetch(fullUrl, requestArgs);

    if (!response.ok || response.status !== 200) {
      throw new Error(`Lead submit failed, OK: ${response.ok}, Status: ${response.status}`);
    }

    const responseObj = await response.json();
    if (!responseObj || !responseObj.id || !responseObj.success) {
      throw new Error(
        `Lead submit failed, ${responseObj} ${responseObj.id} ${
          responseObj.success
        } ${JSON.stringify(responseObj)}`
      );
    }
  },

  async submitPartner({
    interests,
    company,
    website,
    address,
    zip,
    firstName,
    lastName,
    email,
    phone,
    description,
    acceptTerms,
    utility_type,
    referred_by,
    partner_id,
    type,
    service_area,
    content_id,
  }: PartnerLead) {
    const submitData: any = {};

    submitData.company = company || '';
    submitData.website = website || '';
    submitData.street = address || '';
    submitData.zip = zip || '';
    submitData.first_name = firstName || '';
    submitData.last_name = lastName || '';
    submitData.email = email || '';
    submitData.phone = phone || '';
    submitData.description = description || '';
    submitData.nda_signed = !!acceptTerms;
    submitData.partner_interests = (interests || []).join(';');
    submitData.type = type || '';
    submitData.service_area = service_area || [];
    submitData.content_id = content_id;

    if (referred_by) {
      submitData.referred_by = referred_by;
    }

    if (partner_id) {
      submitData.partner_id = partner_id;
    }

    if (utility_type) {
      submitData.description = `Utility type: ${utility_type} | ${submitData.description}`;
    }

    submitData.landing_url = window.location.origin + window.location.pathname;

    const requestArgs: RequestInit = {
      headers: { 'content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(submitData),
      method: 'POST',
      mode: 'cors',
    };

    const url = `${getApiBaseUrl()}/leads/partner`;
    const response = await fetch(url, requestArgs);

    if (!response.ok || response.status > 299) {
      throw new Error(`Partner submit failed, OK: ${response.ok}, Status: ${response.status}`);
    }

    const responseObj = await response.json();
    if (!responseObj || !responseObj.id || !responseObj.success) {
      throw new Error(
        `Partner submit failed, ${responseObj} ${responseObj.id} ${
          responseObj.success
        } ${JSON.stringify(responseObj)}`
      );
    }
  },

  async getPartnerInfo(partnerId) {
    try {
      const cacheKey = 'partnerName_' + partnerId;
      if (window.sessionStorage && window.sessionStorage.getItem(cacheKey)) {
        return window.sessionStorage.getItem(cacheKey);
      }

      const isProd =
        window.location.origin.indexOf('swellenergy.com') > -1 &&
        window.location.origin.indexOf('sbx') === -1;
      const requestArgs: RequestInit = {
        headers: { 'content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({ partnerID: partnerId }),
        method: 'POST',
        mode: 'cors',
      };

      const fullUrl = (isProd ? LeadSubmitUrlProd : LeadSubmitUrlDev) + 'partnerinfo';
      const response = await fetch(fullUrl, requestArgs);
      const data = await response.json();

      if (data.partnerName) {
        window.sessionStorage.setItem(cacheKey, data.partnerName);
      }

      return data.partnerName;
    } catch (e) {
      Sentry.captureException(e);
      return '';
    }
  },

  async submitWaveGSLead(lead: WaveGSLead) {
    const url = `${getApiBaseUrl()}/leads/wave-gs`;

    try {
      const response = await fetch(url, {
        headers: { 'content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify(lead),
        method: 'POST',
        mode: 'cors',
      });

      return {
        success: response.status >= 200 && response.status < 300,
        data: await response.json(),
      };
    } catch (err) {
      Sentry.captureException(err);
      return {
        success: false,
      };
    }
  },

  async acceptWaveGSLeadVppTerms(lead: WaveGSAcceptVPPTerms) {
    const url = `${getApiBaseUrl()}/leads/wave-gs-accept`;

    try {
      const response = await fetch(url, {
        headers: { 'content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify(lead),
        method: 'POST',
        mode: 'cors',
      });

      return response.status >= 200 && response.status < 300;
    } catch (err) {
      Sentry.captureException(err);
      return false;
    }
  },

  async upsertGenericLead({
    lead,
    journeyId,
    journeyOptions,
  }: {
    lead: GenericLead;
    journeyId?: string;
    journeyOptions?: GenericLeadJourneyOption[];
  }) {
    const url = `${getApiBaseUrl()}/leads/generic`;

    try {
      const response = await fetch(url, {
        headers: { 'content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({ journeyId, journeyOptions, lead }),
        method: 'POST',
        mode: 'cors',
      });

      return {
        success: response.status >= 200 && response.status < 300,
        data: await response.json(),
      };
    } catch (err) {
      Sentry.captureException(err);
      return {
        success: false,
      };
    }
  },

  async completeGenericLead(id: string) {
    const url = `${getApiBaseUrl()}/leads/generic/${id}/complete`;

    try {
      const response = await fetch(url, {
        headers: { 'content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({}),
        method: 'POST',
        mode: 'cors',
      });

      return {
        success: response.status >= 200 && response.status < 300,
        data: {},
      };
    } catch (err) {
      Sentry.captureException(err);
      return {
        success: false,
      };
    }
  },
};

export const assetProductUrl = (isProd: boolean) =>
  isProd ? assetProductUrlProd : assetProductUrlDev;

export const getAssetProductName = async (isProd: boolean, value: string) => {
  try {
    const url = assetProductUrl(isProd);
    const res = await fetch(url);
    const data = await res.json();

    if (data && Array.isArray(data)) {
      const name = data.find((el) => el.value === value)?.name;

      return `ProductName:${name}`;
    }
    return `ErrorFetchingProductName:${data.message}`;
  } catch (error) {
    return `ErrorFetchingProductName:${error.message}`;
  }
};

export default leads;
