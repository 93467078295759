import React from 'react';
import tw from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';

interface BlokProps extends BaseBlok {
  message: string;
}

const SuccessMessage = tw.div`bg-swell-gray bg-opacity-10 w-full p-4 m-2 text-sm text-center leading-loose rounded-md font-sans space-y-4`;

export default function NxLeadFormSuccessMessage(props: NxBaseProps<BlokProps>): React.ReactNode {
  return (
    <SuccessMessage>
      {props.blok.message.split('\n').map((paraText, index) => (
        <p key={`para-${index}`}>{paraText}</p>
      ))}
    </SuccessMessage>
  );
}
