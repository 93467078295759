import * as Sentry from '@sentry/gatsby';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { leads, metrics } from '../../services';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ButtonStyles } from '../NxButtonLink/ButtonContainer';
import { ErrorMessage, SuccessMessage } from '../NxLeadFormComponents/Common';
import NxLeadFormInput from '../NxLeadFormComponents/NxLeadFormInput';
import NxLeadFormSelect from '../NxLeadFormComponents/NxLeadFormSelect';

import {
  ExistingSolar,
  HomeSizes,
  IsHomeowner,
  Step1FormData,
  Step1InitialValues,
  Step1ValidationSchema,
  Step2FormData,
  Step2InitialValues,
  Step2ValidationSchema,
} from './LeadFormTypes';

interface BlokProps extends BaseBlok {
  utm_content: string;
}

const StyledForm = styled(Form)(() => [tw`flex flex-col space-y-6`]);

const InputsContainer = tw.div`flex flex-col lg:flex-row space-y-2 lg:space-y-0`;

const SubmitButton = styled.button((props) =>
  ButtonStyles({
    theme: 'blue',
    size: 'normal',
    horizontalAlign: 'center',
    width: '280px',
    disabled: props.disabled,
  })
);

const Disclaimer = tw.div`text-xs text-gray-400 w-72 mx-auto text-center md:(w-auto max-w-xl)`;

export default function NxLeadFormHorizontal(props: NxBaseProps<BlokProps>): React.ReactNode {
  const [step1Values, setStep1Values] = useState(Step1InitialValues);
  const [isOnStep2, setIsOnStep2] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'failed' | 'successful'>('idle');

  const advanceStep2 = (values: Step1FormData) => {
    setStep1Values(values);
    setIsOnStep2(true);
    metrics.trackLeadFormStep(2, 'NxLeadFormHorizontal', props.blok.utm_content);
  };

  const submitLead = async (values: Step2FormData) => {
    setSubmitStatus('idle');

    try {
      // If the user selects "I own a business", let's assume the record type to be
      // commercial. We can also _not_ pass this value to SFDC for lead homeSize attribute.
      const homeSize =
        step1Values.homeSize === 'I own a business' ? undefined : step1Values.homeSize;

      await leads.submitLead({
        ...step1Values,
        ...values,
        homeSize,
        customerType: !homeSize ? 'commercial' : 'residential',
        utmContent: props.blok.utm_content,
      });

      metrics.trackLeadFormSubmit(null, 'NxLeadFormHorizontal', props.blok.utm_content);
      setSubmitStatus('successful');
    } catch (err) {
      Sentry.captureException(err);
      setSubmitStatus('failed');
    }
  };

  return (
    <SbEditable content={props.blok}>
      <div>
        {submitStatus === 'successful' && (
          <SuccessMessage>
            Thank you! You are one step closer to having affordable and reliable energy.
            <br />A Swell Energy representative will contact you shortly to discuss your renewable
            energy options.
          </SuccessMessage>
        )}
        {submitStatus !== 'successful' && (
          <>
            {!isOnStep2 && (
              <Formik
                initialValues={Step1InitialValues}
                validationSchema={Step1ValidationSchema}
                onSubmit={(values) => advanceStep2(values)}
                isInitialValid={false}
                validateOnMount
              >
                {(formik) => (
                  <StyledForm>
                    <InputsContainer>
                      <NxLeadFormSelect
                        id="isHomeowner"
                        name="isHomeowner"
                        label="Do you own your home or business property?"
                        formik={formik}
                        required
                        options={IsHomeowner.map((v) => ({ name: v, value: v }))}
                      />
                      <NxLeadFormSelect
                        id="homeSize"
                        name="homeSize"
                        label="What is your square footage?"
                        formik={formik}
                        required
                        options={HomeSizes.map((v) => ({ name: v, value: v }))}
                      />
                      <NxLeadFormSelect
                        id="existingSolar"
                        name="existingSolar"
                        label="Do you have solar panels?"
                        formik={formik}
                        required
                        options={ExistingSolar.map((v) => ({ name: v, value: v }))}
                      />
                    </InputsContainer>
                    <SubmitButton disabled={!formik.isValid} type="submit">
                      Continue
                    </SubmitButton>
                  </StyledForm>
                )}
              </Formik>
            )}
            {isOnStep2 && (
              <Formik
                initialValues={Step2InitialValues}
                validationSchema={Step2ValidationSchema}
                onSubmit={submitLead}
                validateOnMount
              >
                {(formik) => (
                  <StyledForm>
                    <InputsContainer>
                      <NxLeadFormInput
                        id="name"
                        name="name"
                        label="Full Name"
                        formik={formik}
                        required
                        placeholder="First Last"
                      />
                      <NxLeadFormInput
                        id="email"
                        name="email"
                        label="Email Address"
                        formik={formik}
                        required
                        placeholder="hello@swellenergy.com"
                        type="email"
                      />
                      <NxLeadFormInput
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        formik={formik}
                        required
                        placeholder="(888) 465-1784"
                        mask="(999) 999-9999"
                        type="tel"
                      />
                      <NxLeadFormInput
                        id="zip"
                        name="zip"
                        label="Zip Code"
                        formik={formik}
                        required
                        placeholder="90210"
                        mask="99999"
                      />
                    </InputsContainer>
                    {submitStatus === 'failed' && (
                      <ErrorMessage>
                        We had a problem processing your request. Please try again.
                      </ErrorMessage>
                    )}
                    <Disclaimer>
                      By submitting this form, I agree to the{' '}
                      <a href="/privacy-policy">Privacy Policy</a> and consent to be contacted by or
                      on behalf of Swell Energy, including by email, calls, and text messages to any
                      telephone number that I provide, about my interest in Swell Energy products
                      and services. I understand my consent is not required to purchase.
                    </Disclaimer>
                    <SubmitButton disabled={!formik.isValid || formik.isSubmitting} type="submit">
                      Submit
                    </SubmitButton>
                  </StyledForm>
                )}
              </Formik>
            )}
          </>
        )}
      </div>
    </SbEditable>
  );
}
