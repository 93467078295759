import React from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import {
  ContainerItemsBehavior,
  ElementHorizontalPosition,
  ElementVerticalPosition,
  getScreenHeights,
  getScreenWidths,
  GutterSize,
  ScreenHeights,
  ScreenWidths,
  WrapBreakpoint,
} from '../NxCommon';
import { getComponentForBlok } from '../NxComponents';

import Container from './Container';

interface BlokProps extends BaseBlok {
  columns: BaseBlok[];
  columns_height_behavior: ContainerItemsBehavior;
  width?: string;
  width_sm?: string;
  width_md?: string;
  width_lg?: string;
  width_xl?: string;
  horizontal_position: ElementHorizontalPosition;
  height?: string;
  height_sm?: string;
  height_md?: string;
  height_lg?: string;
  height_xl?: string;
  vertical_position: ElementVerticalPosition;
  wrap_on_breakpoint: boolean;
  wrap_breakpoint: WrapBreakpoint;
  space_x?: GutterSize;
  space_y?: GutterSize;
}

const RowWrapper = styled.div((props: ScreenWidths & ScreenHeights) => [
  tw`flex mx-auto`,
  ...getScreenWidths(props),
  ...getScreenHeights(props),
]);

export default function NxColumns(props: NxBaseProps<BlokProps>): React.ReactNode {
  const {
    columns,
    columns_height_behavior,
    width,
    width_sm,
    width_md,
    width_lg,
    width_xl,
    horizontal_position,
    height,
    height_sm,
    height_md,
    height_lg,
    height_xl,
    vertical_position,
    wrap_on_breakpoint,
    wrap_breakpoint,
    space_x,
    space_y,
  } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <RowWrapper
        height={height}
        height_sm={height_sm}
        height_md={height_md}
        height_lg={height_lg}
        height_xl={height_xl}
        width={width}
        width_sm={width_sm}
        width_md={width_md}
        width_lg={width_lg}
        width_xl={width_xl}
      >
        <Container
          itemsBehavior={columns_height_behavior}
          wrapOnBreakpoint={wrap_on_breakpoint}
          wrapBreakpoint={wrap_breakpoint}
          spaceX={space_x}
          spaceY={space_y}
          horizontalPosition={horizontal_position}
          verticalPosition={vertical_position}
        >
          {columns && columns.map((column) => getComponentForBlok(column))}
        </Container>
      </RowWrapper>
    </SbEditable>
  );
}
