import { BaseBlok } from '../NxBaseProps';
import { StoryBlokLink } from '../StoryBlokLink';

export interface BlokProps extends BaseBlok {
  link: StoryBlokLink;
  title: BaseBlok[];
  subtext: BaseBlok[];
  disclaimer_text: BaseBlok[];
}

export interface SystemDetail {
  name: 'Tesla Powerwall' | 'Enphase Inverter' | 'REC410AA Pure Black PV Modules';
  quantity: number;
}

export interface SystemDetails {
  battery: SystemDetail;
  inverter: SystemDetail;
  panel: SystemDetail;
}

export interface DefaultSystemValues {
  panelInverterCount: number;
  batteryCount: number;
}

export interface FinancialProduct {
  type: FinancialProductEnum;
  projectTotal?: number;
  federalTaxCredits?: number;
  discounts?: number;
  cost?: number;
  monthlyPayment?: number;
  rateEscalator?: number;
  savings: number;
  contractTerms?: string;
  batteryWarranty?: boolean;
  annualRateEscalator?: string;
  productionGuarantee?: boolean;
  earnWithGridRevenue?: boolean;
  systemMonitor?: boolean;
  downPayment?: string;
  newPricePerKwh: string;
  solarWarranty?: boolean;
  inverterWarranty?: boolean;
  transferability?: boolean;
  postSolarUtilityBill?: number;
  maintenanceCost?: number;
  replacementCost?: number;
}

export interface SystemDesignDetails {
  financialProducts: FinancialProduct[];
  systemDetails: SystemDetails;
  utilityCost25Years: number;
  systemDesignId: string;
}
const ProductsSelectionEnum = {
  '1': 'Solar Only',
  '2': 'Storage Only',
  '3': 'Solar & Storage',
  '4': 'Solar & Storage+',
};

export type ProductsSelectionType = keyof typeof ProductsSelectionEnum;
export enum FinancialProductEnum {
  cash = 'cash',
  esa = 'esa',
}

export interface UtilityBill {
  month: string;
  amount: string;
}

export interface SystemDesignResponse {
  id: string;
  partnerId: string;
  addressString: string;
  buildingArea?: string;
  productSelection?: ProductsSelectionType;
  lseId?: number;
  lseName?: string;
  masterTariffId?: number;
  tariffName?: string;
  utilityBills?: UtilityBill[];
  annualEnergyConsumptionKwh: string;
  systemSizeKw: string;
  solarGenerationKwh: string;
  offsetPct: number;
  details: SystemDesignDetails;
  financialProduct: FinancialProductEnum;
  availableFinancialProducts: FinancialProductEnum[];
  newPricePerKwh?: string;
  utilityPricePerKwh?: string;
  costOfDoingNothing?: number;
  autopanelJobId?: string;
  maintenanceCost?: number;
  replacementCost?: number;
}

export enum FluxMapTypes {
  'satellite',
  'nearmap',
  'solar',
}

export interface ContextValuesType extends Partial<SystemDesignResponse> {
  address: string;
  lat: number;
  lng: number;
  streetAddress: string;
  financeType: FinancialProductEnum;
  systemDesignId?: string;
  leadId?: string;
  zip: string;
  country: string;
  placeId: string;
  autoPanelSystemDetails?: AutoPanelSystem;
  systemDesignDetails?: SystemDesignDetails;
  productSelection?: ProductsSelectionType;
  defaultLseId?: number;
  defaultMasterTariffId?: number;
  costOfDoingNothing?: number;
  autopanelJobId?: string;
  defaultSystemValues?: DefaultSystemValues;
  selectedPanelQty?: number;
  selectedBatteryQty?: number;
  refineTab: 1 | 2; //1 :Refine with utility bill //2:Refine with home size
  streetNumber: string;
  unit: string;
  state: string;
  city: string;
  userSelectedPanelQty: number;
  userSelectedBatteryQty: number;
  mapType: FluxMapTypes;
}

export interface Segment {
  id: string;
  outline: Array<LatLng>;
  panels: Array<Panel>;
  holes: Array<{ outline: Array<LatLng> }>;
  orientation: string;
  pitch: number;
  azimuth: number;
}

export interface PanelsResult {
  segments?: Array<Segment>;
}

export interface AutoPanelSystem {
  jobId?: string;
  autopanelsSystemId?: string;
  panels?: PanelsResult;
  autoPanelLoading?: boolean;
}

export interface JobStatusResult {
  status: 'completed' | 'waiting' | 'active' | 'delayed' | 'failed' | 'paused' | 'stuck';
  progress: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  placeId: string;
  systemId: string;
  isSunroofAvailable: boolean;
  have3DModel: boolean;
  haveMapRGB: boolean;
  resources: AutoPanelResource;
}

interface AutoPanelResource {
  rgbPng?: string;
  dsmPng?: string;
  maskPng?: string;
  rgbBoundsPng?: string;
  pcdModel?: string;
  solarMaskPng?: string;
  normalsNoisyPng?: string;
  normalsPng?: string;
  segmentsPng?: string;
  segmentOutlinesPng?: string;
  polygonsPng?: string;
  rgbPanelsPng?: string;
  rgbPanelsSequenceGif?: string;
  rgbPanelsIrradiancePng?: string;
}

export interface Panel {
  id: string;
  order: number;
  estimatedGeneration: number;
  outline: Array<{ outline: Array<LatLng> }>;
}

export interface LatLng {
  lng: number;
  lat: number;
}
