import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import tw from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentsForBlokArray } from '../NxComponents';

import { ReactComponent as LeftArrow } from './assets/left_arrow.svg';
import { ReactComponent as RightArrow } from './assets/right_arrow.svg';
import { ItemButton } from './ItemButton';
import { SlideContent } from './SlideContent';

interface ItemBlokProps extends BaseBlok {
  title: string;
  content: BaseBlok[];
}

interface BlokProps extends BaseBlok {
  items: ItemBlokProps[];
  button_width: string;
}

const CarouselContainer = tw.div`flex flex-col w-full items-center`;
const CarouselButtons = tw.div`flex items-center space-x-4`;
const SwipeButton = tw.button`border-none outline-none hocus:(border-none outline-none) active:(border-none outline-none) lg:hidden`;
const SlideWrapper = tw.div`relative w-full`;

export default function NxButtonCarousel(props: NxBaseProps<BlokProps>): React.ReactNode {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { items, button_width } = props.blok;

  const setSlide = (slideIndex: number) => {
    if (slideIndex < 0) {
      setCurrentSlide(items.length - 1);
    } else if (slideIndex >= items.length) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(slideIndex);
    }
  };

  const slideGo = (delta: number) => {
    const nextSlide = currentSlide + delta;
    setSlide(nextSlide);
  };

  return (
    <SbEditable content={props.blok}>
      <CarouselContainer>
        <CarouselButtons>
          <SwipeButton onClick={() => slideGo(-1)} aria-label="Previous slide">
            <LeftArrow />
          </SwipeButton>
          {items.map((item, idx) => (
            <ItemButton
              key={`${item._uid}`}
              width={button_width}
              active={idx === currentSlide}
              onClick={() => setSlide(idx)}
            >
              {item.title}
            </ItemButton>
          ))}
          <SwipeButton onClick={() => slideGo(1)} aria-label="Next slide">
            <RightArrow />
          </SwipeButton>
        </CarouselButtons>
        <SlideWrapper>
          {items.map((item, index) => (
            <SlideContent active={currentSlide === index} key={item._uid}>
              {getComponentsForBlokArray(item.content)}
            </SlideContent>
          ))}
        </SlideWrapper>
      </CarouselContainer>
    </SbEditable>
  );
}
