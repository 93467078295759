import { FormikProps } from 'formik';
import React, { Ref } from 'react';
import InputMask from 'react-input-mask';
import tw, { css, styled } from 'twin.macro';

import { useLeadForm } from '../../hooks/useLeadForm';
import { FieldError } from '../NxLeadForm/styles';

import { InputContainer, Label } from './Common';

type Theme = 'light' | 'dark';
interface InputProps {
  isError: boolean;
  theme: Theme;
  errorColor: string;
  primaryColor: string;
}

const InputStyles = (props: InputProps) => [
  tw`rounded-xl p-2  w-full font-sans !outline-[none]`,
  props.isError
    ? props.errorColor
      ? css`
          border: 1px solid ${props.errorColor};
        `
      : props.theme === 'light'
      ? tw`border border-solid border-swell-error-light-theme `
      : tw`border border-solid border-red-500 `
    : css`
        border: 1px solid rgba(57, 60, 65, 0.3);
      `,

  props.primaryColor
    ? css`
        &:focus {
          border-color: ${props.primaryColor};
        }
      `
    : tw`focus:border-swell-blue`,
  tw`disabled:opacity-50`,
  props.theme === 'light' ? tw`text-black font-normal` : tw` `,
];

const Input = styled.input<InputProps>((props) => InputStyles(props));
const MaskedInput = styled(InputMask)<InputProps>((props) => InputStyles(props));

interface Props<TFormData> {
  formik: FormikProps<TFormData>;
  id: string;
  name: string;
  required?: boolean;
  label?: string;
  type?: string;
  placeholder?: string;
  mask?: string;
  maxLength?: number;
  autoComplete?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  theme?: Theme;
}

function NxLeadFormInput<TFormData>(
  props: Props<TFormData>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    formik,
    id,
    name,
    required,
    label,
    type,
    placeholder,
    maxLength,
    autoComplete,
    onKeyDown,
    theme,
  } = props;
  const hasError = Boolean(formik.errors[name]);
  const showError = formik.touched[name] || formik.submitCount > 0;

  const {
    pageStyles: { errorColor, primaryColor },
  } = useLeadForm();

  const inputProps = {
    id,
    name,
    value: formik.values[name] || '',
    required,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    isError: hasError && showError,
    disabled: formik.isSubmitting,
    type,
    placeholder,
    maxLength,
    autoComplete,
    theme,
    errorColor,
    primaryColor,
  };

  return (
    <InputContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      {props.mask ? (
        <MaskedInput mask={props.mask} alwaysShowMask={false} {...inputProps} />
      ) : (
        <Input {...inputProps} ref={ref} onKeyDown={onKeyDown} />
      )}
      {hasError && showError && (
        <FieldError errorColor={errorColor} theme={theme}>
          {hasError && showError ? formik.errors[name] : ' '}
        </FieldError>
      )}
    </InputContainer>
  );
}

export default React.forwardRef(NxLeadFormInput) as unknown as <T>(
  p: Props<T> & { ref?: Ref<HTMLElement> },
  ref?: Ref<HTMLElement>
) => JSX.Element;
