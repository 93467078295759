import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import SbEditable from 'storyblok-react';

import { leads } from '../../services';
import { useEnvironment } from '../EnvironmentContext';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentsForBlokArray } from '../NxComponents';

import { PartnerPersonalizationContext } from './PartnerPersonalizationContext';

interface BlokProps extends BaseBlok {
  valid_content: BaseBlok[];
  invalid_content: BaseBlok[];
  preview_valid_content: boolean;
}

export default function NxPartnerPersonalizedContent(
  props: NxBaseProps<BlokProps>
): React.ReactNode {
  const { valid_content, invalid_content, preview_valid_content } = props.blok;
  const [partnerName, setPartnerName] = useState('');
  const { isInEditor } = useEnvironment();

  useEffect(() => {
    async function fetchData() {
      if (typeof window !== undefined) {
        const parsed = queryString.parse(window.location.search);

        if (parsed && parsed.partnerId) {
          const apiName = await leads.getPartnerInfo(parsed.partnerId);
          setPartnerName(apiName || '');
        } else if (isInEditor) {
          setPartnerName('ABC Solar Solutions');
        } else {
          setPartnerName('');
        }
      }
    }

    fetchData();
  }, []);

  const shouldPresentValidContent = (isInEditor && preview_valid_content) || !isInEditor;
  const contextValue = {
    partnerName: shouldPresentValidContent ? partnerName : '',
  };

  return (
    <>
      <SbEditable content={props.blok}>
        <PartnerPersonalizationContext.Provider value={contextValue}>
          {contextValue.partnerName !== '' && getComponentsForBlokArray(valid_content)}
          {contextValue.partnerName === '' && getComponentsForBlokArray(invalid_content)}
        </PartnerPersonalizationContext.Provider>
      </SbEditable>
    </>
  );
}
