import React from 'react';
import tw from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentForBlok, getComponentsForBlokArray } from '../NxComponents';

interface BlokProps extends BaseBlok {
  items: BaseBlok[];
  item_separator: BaseBlok[];
}

const Container = tw.div`flex flex-col w-full`;

export default function NxAccordion(props: NxBaseProps<BlokProps>) {
  const { items, item_separator } = props.blok;
  const children = [];

  for (let i = 0; i < items.length; i++) {
    if (i > 0 && item_separator) {
      children.push(...getComponentsForBlokArray(item_separator));
    }

    children.push(getComponentForBlok(items[i]));
  }

  return <Container>{children}</Container>;
}
