import * as yup from 'yup';

import { PhoneRegex } from '../../constants';

const UtilityTypes = ['IOU', 'Municipal', 'CoOp', 'CCA', 'Other'];

interface FormData {
  company: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  description: string;
  acceptTerms: boolean;
  utility_type: string;
}

const InitialValues: FormData = {
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  description: '',
  acceptTerms: false,
  utility_type: '',
};

const ValidationSchema = yup.object().shape({
  company: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phone: yup
    .string()
    .required('Enter your phone number')
    .matches(PhoneRegex, 'Enter your phone number'),
  email: yup
    .string()
    .required('Enter a valid e-mail address')
    .email('Enter a valid e-mail address'),
  utility_type: yup.string().required('Required'),
  acceptTerms: yup
    .bool()
    .required('Please read and agree to the Mutual NDA')
    .oneOf([true], 'Please read and agree to the Mutual NDA'),
});

export { FormData, InitialValues, ValidationSchema, UtilityTypes };
