import { Form } from 'formik';
import tw, { styled } from 'twin.macro';

import { ButtonStyles } from '../NxButtonLink/ButtonContainer';
import { ButtonTheme } from '../NxCommon';

interface ContainerProps {
  textColor: 'light' | 'dark';
}

export const Container = styled.div(({ textColor }: ContainerProps) => [
  textColor === 'light' ? tw`text-white` : tw`text-swell-gray`,
]);

export const StyledForm = styled(Form)(() => [tw`flex flex-col space-y-6`]);
export const InputsContainer = tw.div`flex flex-col -space-y-1`;
export const InputsRow = tw.div`flex flex-row space-x-2`;
export const FieldError = tw.span`font-sans font-bold text-red-800 text-xs`;

interface SubmitButtonProps {
  theme: ButtonTheme;
  disabled: boolean;
}

export const SubmitButton = styled.button(({ theme, disabled }: SubmitButtonProps) =>
  ButtonStyles({
    theme,
    size: 'normal',
    horizontalAlign: 'center',
    width: '280px',
    disabled,
  })
);

export const Disclaimer = tw.div`text-xs text-gray-400 w-72 mx-auto text-justify md:(w-auto max-w-xl)`;
