import { FormikProps } from 'formik';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

interface InputProps {
  isError: boolean;
}

const Input = styled.input<InputProps>((props) => [
  tw`rounded-xl mt-0`,
  props.isError
    ? tw`border border-solid border-red-500`
    : css`
        border: 1px solid rgba(57, 60, 65, 0.3);
      `,
  tw`focus:border-swell-blue hocus:outline-none`,
  tw`disabled:opacity-50`,
]);
const CheckboxLabel = styled.label(() => [tw`flex flex-row items-start m-0 font-sans`]);

interface Props<TFormData> {
  formik: FormikProps<TFormData>;
  id: string;
  name: string;
  required?: boolean;
  value?: string;
  children: React.ReactElement;
}

export default function NxLeadFormCheckbox<TFormData>(props: Props<TFormData>) {
  const { formik, id, name, required, children, value } = props;
  const hasError = Boolean(formik.errors[name]);
  const showError = formik.touched[name] || formik.submitCount > 0;
  const checked =
    typeof formik.values[name] === 'object'
      ? formik.values[name]?.indexOf(value) > -1
      : !!formik.values[name];

  const inputProps = {
    id,
    name,
    checked,
    required,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    isError: hasError && showError,
    disabled: formik.isSubmitting,
    value,
  };

  return (
    <CheckboxLabel htmlFor={id}>
      <Input {...inputProps} type="checkbox" />
      {children}
    </CheckboxLabel>
  );
}
