import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentsForBlokArray } from '../NxComponents';

import { ReactComponent as Arrow } from './assets/arrow.svg';

export interface BlokProps extends BaseBlok {
  header: BaseBlok[];
  content: BaseBlok[];
}

const HeaderContainer = tw.button`flex flex-row w-full items-center hocus:outline-none`;
const HeaderContent = tw.div`flex-grow`;
const HeaderImage = styled.div(() => [tw``]);

interface ExpandArrowProps {
  isExpanded: boolean;
}

const ExpandArrow = styled(Arrow)((props: ExpandArrowProps) => [
  tw`transition-all transform`,
  props.isExpanded ? tw`rotate-180` : '',
]);

interface ContentContainerProps {
  isExpanded: boolean;
  contentHeight: number;
}

const ContentContainer = styled.div((props: ContentContainerProps) => [
  tw`transition-all overflow-hidden`,
  props.isExpanded ? `height: ${props.contentHeight}px;` : tw`h-0`,
]);

export default function NxAccordionItem(props: NxBaseProps<BlokProps>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const measureWrapper = useRef<HTMLDivElement>(null);
  const { header, content } = props.blok;

  useEffect(() => {
    setContentHeight(measureWrapper.current?.clientHeight || 0);
  }, [measureWrapper]);

  return (
    <div>
      <HeaderContainer onClick={() => setIsExpanded(!isExpanded)}>
        <HeaderContent>{header && getComponentsForBlokArray(header)}</HeaderContent>
        <HeaderImage>
          <ExpandArrow isExpanded={isExpanded} />
        </HeaderImage>
      </HeaderContainer>
      <ContentContainer isExpanded={isExpanded} contentHeight={contentHeight}>
        <div ref={measureWrapper}>{content && getComponentsForBlokArray(content)}</div>
      </ContentContainer>
    </div>
  );
}
