import React from 'react';
import SbEditable from 'storyblok-react';
import tw, { css, styled, theme } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentsForBlokArray } from '../NxComponents';
import { StoryBlokAsset } from '../StoryBlokAsset';

interface BlokProps extends BaseBlok {
  content: BaseBlok[];
  image: StoryBlokAsset;
}

const Hero = tw.div`flex flex-col md:flex-row w-full items-center justify-between pl-4 gap-x-4`;
const Content = styled.div(() => [
  css`
    padding-right: 1rem;
    @media (min-width: ${theme`screens.md`}) {
      max-width: 40%;
      padding-right: 0;
    }
  `,
]);
const Image = styled.img(() => [
  tw`hidden md:block rounded-l-3xl`,
  css`
    max-width: 50%;
  `,
]);

export default function NxHeroA(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { content, image } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Hero>
        <div />
        <div />
        <Content>{getComponentsForBlokArray(content)}</Content>
        <Image alt={image.alt} src={image.filename} />
      </Hero>
    </SbEditable>
  );
}
