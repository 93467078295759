import React from 'react';
import tw from 'twin.macro';

import { useEnvironment } from '../../../components/EnvironmentContext';
import { requiredFieldsValidator } from '../../../utils/requiredFieldsValidator';
import { ContentProps } from '../LeadFormTypes';

const Wrapper = tw.div`  mx-auto   flex flex-col max-w-[456px] items-center justify-center px-8 md:px-16 `;
const Text = tw.div` bg-red-400 w-full border border-red-600 text-white px-8 py-10 md:px-8 mt-8 rounded-md`;

export const RequiredFieldsMessage = (props: Partial<ContentProps>) => {
  const { isInEditor } = useEnvironment();

  const fieldErrors = requiredFieldsValidator(props);

  if (!(isInEditor && fieldErrors.length !== 0)) return null;

  return (
    <Wrapper>
      <Text>
        {`Please resolve the following errors :`}
        <br />
        <br />
        {fieldErrors.map((el) => (
          <>
            {el}
            <br />
          </>
        ))}
      </Text>
    </Wrapper>
  );
};
