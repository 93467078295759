import React from 'react';
import SbEditable from 'storyblok-react';
import { styled, theme } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';

interface BlokProps extends BaseBlok {
  buffer_size: number;
  buffer_size_sm?: number;
  buffer_size_md?: number;
  buffer_size_lg?: number;
  buffer_size_xl?: number;
}

const getScreenBuffers = (props: BlokProps) => {
  const result = [`height: ${props.buffer_size}rem;width: 100%;`];

  if (props.buffer_size_sm) {
    result.push(`@media (min-width: 0) { height: ${props.buffer_size_sm}rem;}`);
  }

  if (props.buffer_size_md) {
    result.push(`@media (min-width: ${theme`screens.md`}) { height: ${props.buffer_size_md}rem;}`);
  }

  if (props.buffer_size_lg) {
    result.push(`@media (min-width: ${theme`screens.lg`}) { height: ${props.buffer_size_lg}rem;}`);
  }

  if (props.buffer_size_xl) {
    result.push(`@media (min-width: ${theme`screens.xl`}) { height: ${props.buffer_size_xl}rem;}`);
  }

  return result;
};

const Buffer = styled.div((props: BlokProps) => getScreenBuffers(props));

export default function NxVerticalBuffer(props: NxBaseProps<BlokProps>): React.ReactNode {
  return (
    <SbEditable content={props.blok}>
      <Buffer {...props.blok} />
    </SbEditable>
  );
}
