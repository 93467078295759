import human from 'humanparser';
import React, { useState } from 'react';
import SbEditable from 'storyblok-react';

import { useLeadForm } from '../../hooks/useLeadForm';
import { leads } from '../../services';
import { useEnvironment } from '../EnvironmentContext';
import { NxBaseProps } from '../NxBaseProps';

import { RequiredFieldsMessage } from './components';
import { FormData, BlokProps, SubmitStatus, SubmitType } from './LeadFormTypes';

export default function LeadForm(props: NxBaseProps<BlokProps>) {
  const [status, setStatus] = useState('idle' as SubmitStatus);
  const { storyName, environment } = useEnvironment();

  const {
    enable_journey_flow,
    success_content,
    story_name,
    wave_utility_program_id,
    wave_install_partner_id,
    wave_partner_id,
    wave_utility_id,
    sbx_wave_install_partner_id,
    sbx_wave_partner_id,
    sbx_wave_utility_id,
    sbx_wave_utility_program_id,
  } = props.blok;

  const { page: MyComponent, journeyFlowConfig } = useLeadForm();

  const submitEasyEnrollCustomer = async (values: any, isAlternative = false) => {
    const { firstName, lastName, middleName = '' } = human.parseName(values.name);

    const userInfo = {
      email: values.email,
      firstName: `${firstName} ${middleName}`.trim(),
      lastName,
      phone: values.phone,
      city: values.city,
      state: values.state,
      zip: values.zip,
      address: `${values.streetNumber} ${values.streetAddress}`,
      //---------------------------------------------
      utilityProgramName:
        environment === 'prod' ? wave_utility_program_id : sbx_wave_utility_program_id,
      pageUrl: window.location.origin + window.location.pathname,
      storyblokStory: storyName,
      installationPartnerId:
        environment === 'prod' ? wave_install_partner_id : sbx_wave_install_partner_id,
      salesPartnerId: environment === 'prod' ? wave_partner_id : sbx_wave_partner_id,
      utilityName: environment === 'prod' ? wave_utility_id : sbx_wave_utility_id,
    };

    const res = await leads.upsertGenericLead({
      lead: userInfo,
      ...(isAlternative && { journeyOptions: [{ name: 'sendEmail', value: false }] }),
      ...(isAlternative && { journeyId: SubmitType['SALESFORCE'] }),
      journeyOptions: [],
    });

    return res.data;
  };

  const handleSubmitNoJourneyFlow = async (values: FormData | string) => {
    setStatus('submitting');

    const submitSuccess = await submitEasyEnrollCustomer(values, true);

    const res = await leads.completeGenericLead(submitSuccess.id);
    setStatus(res ? 'success' : 'error');
    return submitSuccess;
  };

  const handleSubmitJourneyFlow = async (values: FormData | string) => {
    setStatus('submitting');

    const res = await submitEasyEnrollCustomer(values);
    setStatus(res ? 'success' : 'error');
    return res;
  };

  const buttonText =
    journeyFlowConfig?.information?.journeyPath !== '3' && status === 'submitting'
      ? props.blok.submitting_button_text
      : props.blok.submit_button_text;

  return (
    <SbEditable content={props.blok}>
      <RequiredFieldsMessage {...props.blok} />

      <MyComponent
        buttonText={buttonText}
        {...props.blok}
        success_content={enable_journey_flow === 'no' ? success_content : undefined}
        handleSubmit={
          enable_journey_flow === 'yes' ? handleSubmitJourneyFlow : handleSubmitNoJourneyFlow
        }
        isError={status === 'error'}
        story_name={story_name}
      />
    </SbEditable>
  );
}
