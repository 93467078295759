import * as yup from 'yup';

import { PhoneRegex } from '../../constants';

const HomeSizes = ['Under 1,000 sf', '1,000-3,000 sf', 'Over 3,000 sf', 'I own a business'];

const ExistingSolar = ['Yes', 'No'];
const IsHomeowner = ['Yes', 'No'];

interface Step1FormData {
  homeSize: string;
  existingSolar: string;
  isHomeowner: string;
}

const Step1InitialValues: Step1FormData = {
  homeSize: '',
  existingSolar: '',
  isHomeowner: '',
};

const Step1ValidationSchema = yup.object().shape({
  homeSize: yup.string().required(' ').oneOf(HomeSizes, ' '),
  existingSolar: yup.string().required(' ').oneOf(ExistingSolar, ' '),
  isHomeowner: yup.string().required(' ').oneOf(IsHomeowner, ' '),
});

interface Step2FormData {
  name: string;
  email: string;
  phone: string;
  zip: string;
}

const Step2InitialValues: Step2FormData = {
  name: '',
  email: '',
  phone: '',
  zip: '',
};

const Step2ValidationSchema = yup.object().shape({
  name: yup.string().required(' '),
  email: yup.string().required(' ').email(' '),
  phone: yup.string().required(' ').matches(PhoneRegex, ' '),
  zip: yup.string().required(' ').matches(/\d{5}/, ' '),
});

export {
  Step1FormData,
  Step1InitialValues,
  Step1ValidationSchema,
  Step2FormData,
  Step2InitialValues,
  Step2ValidationSchema,
  HomeSizes,
  ExistingSolar,
  IsHomeowner,
};
