import React from 'react';
import tw from 'twin.macro';

import { useLeadForm } from '../../../hooks/useLeadForm';
import { getComponentsForBlokArray } from '../../NxComponents';
import { SubmitButton } from '../../NxLeadForm/styles';
import { ContentProps } from '../LeadFormTypes';

import { BackButton } from './BackButton';

const Wrapper = tw.form`bg-white max-w-[548px] rounded-md m-auto px-8 py-10 md:px-16 md:py-20 flex flex-col max-w-[456px] justify-center mt-8 mb-24`;
const ErrorMessage = tw.div`p-2 text-center text-red-500 lg:w-96`;
const InnerWrapper = tw.div`mb-8 max-h-[256px] overflow-y-auto`;
const Title = tw.p`text-swell-gray mb-4`;
const HeadWrapper = tw.div`mb-10 flex  items-center justify-center relative`;

export const ProgramOverview = ({
  isError,
  program_overview_title,
  program_content,
  buttonText,
}: ContentProps) => {
  const { setJourneyFlowConfig } = useLeadForm();

  const onSubmit = () => {
    setJourneyFlowConfig({ type: 'HANDLE_NEXT' });
  };

  return (
    <Wrapper onSubmit={onSubmit}>
      <HeadWrapper>
        <BackButton />
        {getComponentsForBlokArray(program_overview_title)}
      </HeadWrapper>
      <Title>Program Overview</Title>
      <InnerWrapper>{getComponentsForBlokArray(program_content)}</InnerWrapper>

      {isError && (
        <ErrorMessage>We had a problem processing your request. Please try again.</ErrorMessage>
      )}

      <SubmitButton>{buttonText}</SubmitButton>
    </Wrapper>
  );
};
