import React from 'react';
import SbEditable from 'storyblok-react';
import { styled } from 'twin.macro';

import { BackgroundImageProps, getBackgroundStyles } from '../NxBackgroundImage/NxBackgroundImage';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { BackgroundBreakpoint, BorderRounding, TextAlign } from '../NxCommon';
import { getComponentForBlok } from '../NxComponents';
import { StoryBlokColor } from '../StoryBlokColor';

import { Padding, Width } from './PropTypes';
import Section from './Section';

interface BlokProps extends BaseBlok {
  background: BackgroundImageProps[];
  border_enable: boolean;
  border_color: StoryBlokColor;
  border_width: number;
  border_rounding: BorderRounding;
  content: BaseBlok[];
  text_align: TextAlign;
  height: string;
  width: Width;
  horizontal_padding: Padding;
  vertical_padding: Padding;
}

const SectionWrapper = styled.div(
  (props: { background: BackgroundImageProps[]; height: string }) => [
    ...getBackgroundStyles(props.background, props.height),
  ]
);

const backgroundImageSorter = (first: BackgroundImageProps, second: BackgroundImageProps) => {
  const score: Record<BackgroundBreakpoint, number> = {
    default: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
  };

  return score[first.breakpoint] - score[second.breakpoint];
};

export default function NxSection(props: NxBaseProps<BlokProps>): React.ReactNode {
  // Sort the background nodes to make sure styles are applied in the correct order
  const bgSorted = props.blok.background?.sort(backgroundImageSorter) || [];

  return (
    <SbEditable content={props.blok}>
      <SectionWrapper background={bgSorted} height={props.blok.height}>
        <Section
          borderEnable={props.blok.border_enable}
          borderColor={props.blok.border_color?.color || '#ff0000'}
          borderWidth={props.blok.border_width}
          borderRounding={props.blok.border_rounding}
          textAlign={props.blok.text_align}
          horizontalPadding={props.blok.horizontal_padding}
          verticalPadding={props.blok.vertical_padding}
          height={props.blok.height}
          width={props.blok.width}
        >
          {props.blok.content && props.blok.content.map((item) => getComponentForBlok(item))}
        </Section>
      </SectionWrapper>
    </SbEditable>
  );
}
