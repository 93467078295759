import React from 'react';
import tw from 'twin.macro';

import NxAccordion from './NxAccordion/NxAccordion';
import NxAccordionItem from './NxAccordion/NxAccordionItem';
import { BaseBlok } from './NxBaseProps';
import NxButtonCarousel from './NxButtonCarousel/NxButtonCarousel';
import NxButtonLink from './NxButtonLink/NxButtonLink';
import NxColumn from './NxColumns/NxColumn';
import NxColumns from './NxColumns/NxColumns';
import NxHeroA from './NxHeroA/NxHeroA';
import NxHeroB from './NxHeroB/NxHeroB';
import NxHorizontalSeparator from './NxHorizontalSeparator/NxHorizontalSeparator';
import NxImage from './NxImage/NxImage';
import NxImageLink from './NxImageLink/NxImageLink';
import NxJourneyLeadForm from './NxJourneyLeadForm';
import { NxJourneyOption } from './NxJourneyOption/NxJourneyOption';
import NxLeadForm from './NxLeadForm/NxLeadForm';
import NxLeadFormHorizontal from './NxLeadFormHorizontal/NxLeadFormHorizontal';
import NxLeadFormSuccessMessage from './NxLeadFormSuccessMessage/NxLeadFormSuccessMessage';
import NxPage from './NxPage/NxPage';
import NxPartnerLeadForm from './NxPartnerLeadForm/NxPartnerLeadForm';
import NxPartnerPersonalizedContent from './NxPartnerPersonalizedContent/NxPartnerPersonalizedContent';
import { NxQuestionDropdownField } from './NxQuestionDropdownField/NxQuestionDropdownField';
import { NxQuestionFreeTextField } from './NxQuestionFreeTextField/NxQuestionFreeTextField';
import NxResponsiveSelector from './NxResponsiveSelector/NxResponsiveSelector';
import NxRichText from './NxRichText/NxRichText';
import NxScrollTarget from './NxScrollTarget/NxScrollTarget';
import NxSection from './NxSection/NxSection';
import NxUtilityLeadForm from './NxUtilityLeadForm/NxUtilityLeadForm';
import NxVerticalBuffer from './NxVerticalBuffer/NxVerticalBuffer';
import NxVerticalSeparator from './NxVerticalSeparator/NxVerticalSeparator';
import NxWaveGSLeadForm from './NxWaveGSLeadForm/NxWaveGSLeadForm';
import PowerPro from './PowerPro';

const ComponentList = {
  nx_page: NxPage,
  nx_rich_text: NxRichText,
  nx_section: NxSection,
  nx_columns: NxColumns,
  nx_column: NxColumn,
  nx_vertical_buffer: NxVerticalBuffer,
  nx_image: NxImage,
  nx_button_link: NxButtonLink,
  nx_image_link: NxImageLink,
  nx_button_carousel: NxButtonCarousel,
  nx_responsive_selector: NxResponsiveSelector,
  nx_lead_form: NxLeadForm,
  nx_lead_form_horizontal: NxLeadFormHorizontal,
  nx_lead_form_success_message: NxLeadFormSuccessMessage,
  nx_partner_lead_form: NxPartnerLeadForm,
  nx_utility_lead_form: NxUtilityLeadForm,
  nx_wave_gs_lead_form: NxWaveGSLeadForm,
  nx_scroll_target: NxScrollTarget,
  nx_horizontal_separator: NxHorizontalSeparator,
  nx_vertical_separator: NxVerticalSeparator,
  nx_accordion: NxAccordion,
  nx_accordion_item: NxAccordionItem,
  nx_hero_a: NxHeroA,
  nx_hero_b: NxHeroB,
  nx_partner_personalized_content: NxPartnerPersonalizedContent,
  nx_journey_lead_form: NxJourneyLeadForm,
  nx_journey_option: NxJourneyOption,
  nx_question_dropdown_field: NxQuestionDropdownField,
  nx_question_free_text_field: NxQuestionFreeTextField,
  power_pro: PowerPro,
};

export const getComponentsForBlokArray = (items: BaseBlok[]) => {
  return items.map((item) => getComponentForBlok(item));
};

export const getComponentForBlok = (blok: BaseBlok) => {
  const { component, _uid } = blok;

  if (typeof ComponentList[component] === 'undefined') {
    return (
      <div key={_uid} css={[tw`bg-red-400 m-4 border border-red-600 text-white p-4`]}>
        The block {component} does not support NxPage yet
      </div>
    );
  }

  return React.createElement(ComponentList[component], { key: _uid, blok: blok });
};
