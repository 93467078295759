import { css, theme } from 'twin.macro';

import { BaseBlok } from '../NxBaseProps';
import {
  BackgroundBreakpoint,
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize,
} from '../NxCommon';
import { StoryBlokAsset } from '../StoryBlokAsset';
import { isColorSet, StoryBlokColor } from '../StoryBlokColor';

export interface BackgroundImageProps extends BaseBlok {
  breakpoint: BackgroundBreakpoint;
  background_color: StoryBlokColor;
  background_image: StoryBlokAsset;
  background_image_position: BackgroundPosition;
  background_image_repeat: BackgroundRepeat;
  background_image_size: BackgroundSize;
  force_element_to_fit?: boolean;
  clamp_to_breakpoint?: boolean;
}

const getBackgroundStyle = (background: BackgroundImageProps, requestedHeight: string) => {
  const result = [];

  if (background.background_image && background.background_image.filename) {
    if (background.force_element_to_fit) {
      const [, width, height] = /https?:\/\/\w\.storyblok\.com\/f\/\d+\/(\d+)x(\d+)\/.*/g.exec(
        background.background_image.filename
      );

      if (width && height) {
        const ratioMultiplier = Number(height) / Number(width);
        result.push(`width: 100%;`);
        result.push(`height: calc(100vw * ${ratioMultiplier});`);
      }
    }

    result.push(`background-position: ${background.background_image_position};`);
    result.push(`background-repeat: ${background.background_image_repeat};`);
    result.push(`background-size: ${background.background_image_size};`);
    result.push(`background-image: url('${background.background_image.filename}');`);
  }

  if (requestedHeight && !background.force_element_to_fit) {
    result.push(`height: ${requestedHeight};`);
  }

  if (isColorSet(background.background_color)) {
    result.push(`background-color: ${background.background_color.color};`);
  }

  return result;
};

const getBreakpointMinWidth = (breakpoint: BackgroundBreakpoint): string => {
  switch (breakpoint) {
    case 'default':
      return '0';
    case 'md':
      return theme`screens.md`;
    case 'lg':
      return theme`screens.lg`;
    case 'xl':
      return theme`screens.xl`;
  }
};

export const getBackgroundStyles = (backgrounds: BackgroundImageProps[], height: string) => {
  if (backgrounds && backgrounds.length > 0) {
    return backgrounds.map(
      (bg) =>
        css`
          @media (min-width: ${getBreakpointMinWidth(bg.breakpoint)}) {
            ${getBackgroundStyle(bg, height)}
          }
        `
    );
  } else if (backgrounds?.length === 0 && height) {
    return [
      css`
        height: ${height};
      `,
    ];
  }

  return [];
};
