import { graphql, Link, StaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import tw, { styled } from 'twin.macro';

import {
  DESIGN_MY_SYSTEM_TEXT,
  getFooterPowerProAndLearningCenter,
  LEARNING_CENTER_TEXT,
} from '../../utils/getPowerProFields';
import { QueryResult } from '../NxGraphQL';
import withLinkWrap from '../withLinkWrap';

import { ReactComponent as Blog } from './assets/blog.svg';
import { ReactComponent as Facebook } from './assets/facebook.svg';
import { ReactComponent as Twitter } from './assets/twitter.svg';
import { NavItemBlokProps } from './NxMenu';
import { PowerProTypes } from './NxPage';

const FooterContainer = tw.div`bg-swell-gray`;
const Footer = tw.footer`container flex flex-col mx-auto bg-transparent py-24 lg:py-32 px-4 md:px-8 lg:px-16 xl:px-28 2xl:px-48`;
const FooterLinkGroups = tw.div`flex flex-col justify-between space-y-16 md:(flex-row flex-wrap space-y-0) xl:flex-nowrap`;
const FooterLinkGroup = tw.div`flex flex-col items-start space-y-4 w-full pr-4 md:(w-1/2 mb-14) xl:(mb-0 w-full)`;
const GroupTitle = tw.span`text-white font-heading text-lg font-bold`;
const SubgroupTitle = tw.span`text-white text-sm font-bold leading-loose`;
const SubgroupDescription = tw.span`text-gray-400 text-xs leading-loose`;
const FooterLegal = tw.div`flex flex-col space-y-6 items-center mt-24 lg:(flex-row-reverse space-y-0 justify-between)`;
const SocialWrapper = tw.div`flex flex-col space-y-5 items-center lg:(flex-row space-y-0 space-x-5)`;
const SocialChiclets = tw.div`flex flex-row space-x-4`;
const BBB = tw.div`bg-white p-px rounded`;
const BlueLinkStyle = tw`text-swell-blue text-xs no-underline hover:underline mt-4 font-bold inline-block`;
const BlueLink = withLinkWrap(styled(Link)(BlueLinkStyle), styled(OutboundLink)(BlueLinkStyle));
const WhiteLinkStyle = tw`text-gray-400 text-xs no-underline hover:(underline text-white) leading-loose`;
const WhiteLink = withLinkWrap(styled(Link)(WhiteLinkStyle), styled(OutboundLink)(WhiteLinkStyle));
const Subgroup = tw.div`flex flex-col items-start pb-8 last:pb-0`;
const Copyright = tw.div`text-xs text-gray-400`;

interface PressReleaseEntry {
  id: string;
  field_component: 'press_release';
  field_title_string: string;
  full_slug: string;
}

interface PressNewsMentionEntry {
  id: string;
  field_component: 'press_news_mention';
  field_title_string: string;
  field_link_url_string: string;
}

const query = graphql`
  {
    allStoryblokEntry(
      limit: 3
      filter: { field_component: { regex: "/(press_release)|(press_news_mention)/" } }
      sort: { fields: field_publish_date_string, order: DESC }
    ) {
      edges {
        node {
          id
          field_component
          field_title_string
          full_slug
          field_link_url_string
        }
      }
    }
  }
`;

function NxFooter(
  props: {
    data: QueryResult<PressReleaseEntry | PressNewsMentionEntry>;
    navItems: NavItemBlokProps[];
  } & PowerProTypes
): React.ReactElement {
  const {
    show_design_my_system,
    design_my_system_url,
    show_learning_center,
    learning_center_url,
    sbx_design_my_system_url,
    sbx_learning_center_url,
    sbx_show_design_my_system,
    sbx_show_learning_center,
    navItems,
  } = props;

  const designMySystem = getFooterPowerProAndLearningCenter(
    {
      show_design_my_system,
      design_my_system_url,
      show_learning_center,
      learning_center_url,
      sbx_design_my_system_url,
      sbx_learning_center_url,
      sbx_show_design_my_system,
      sbx_show_learning_center,
      navItems,
    },
    DESIGN_MY_SYSTEM_TEXT
  );

  const learningCenter = getFooterPowerProAndLearningCenter(
    {
      show_design_my_system,
      design_my_system_url,
      show_learning_center,
      learning_center_url,
      sbx_design_my_system_url,
      sbx_learning_center_url,
      sbx_show_design_my_system,
      sbx_show_learning_center,
      navItems,
    },
    LEARNING_CENTER_TEXT
  );

  return (
    <>
      <FooterContainer>
        <Footer>
          <FooterLinkGroups>
            <FooterLinkGroup>
              <GroupTitle>Company</GroupTitle>
              <WhiteLink to="/about-us">About us</WhiteLink>
              <WhiteLink to="/how-home-batteries-work">How home batteries work</WhiteLink>
              <WhiteLink to="/how-to-store-solar-energy">How to store solar energy</WhiteLink>
              <WhiteLink to="/jobs">Careers at Swell Energy</WhiteLink>
              <WhiteLink to="https://swell-energy.typeform.com/to/rmtutYkq">Subscribe</WhiteLink>
            </FooterLinkGroup>
            <FooterLinkGroup>
              <GroupTitle>Resources</GroupTitle>
              {designMySystem && (
                <WhiteLink to={designMySystem.link.url}>Design my system with PowerPro</WhiteLink>
              )}
              <WhiteLink to="/faq">FAQ</WhiteLink>
              {learningCenter && (
                <WhiteLink to={learningCenter.link.url}>Learning Center</WhiteLink>
              )}
              <WhiteLink to="https://compass.swellenergy.com/">Login</WhiteLink>

              <WhiteLink to="/press">Press room</WhiteLink>
              <WhiteLink to="/technology">Technology</WhiteLink>
            </FooterLinkGroup>
            <FooterLinkGroup>
              <GroupTitle>Join Swell Energy</GroupTitle>
              <Subgroup>
                <SubgroupTitle>Partners</SubgroupTitle>
                <SubgroupDescription>
                  Our preferred partner network creates industry-wide opportunities through
                  installation partnerships, VPP Co-Development, financing programs, and technology.
                </SubgroupDescription>
                <BlueLink to="/partners">Learn More →</BlueLink>
              </Subgroup>
              <Subgroup>
                <SubgroupTitle>Utilities</SubgroupTitle>
                <SubgroupDescription>
                  Swell Energy partners with Investor-owned Utilities, Municipal Utilities, Utility
                  Cooperatives, Retail Energy Providers, and Community Choice Aggregators (CCAs).
                </SubgroupDescription>
                <BlueLink to="/utilities">Learn More →</BlueLink>
              </Subgroup>
            </FooterLinkGroup>
            <FooterLinkGroup>
              <GroupTitle>Latest news</GroupTitle>
              {props.data &&
                props.data.allStoryblokEntry &&
                props.data.allStoryblokEntry.edges &&
                props.data.allStoryblokEntry.edges.map((v, idx: number) => (
                  <WhiteLink
                    to={
                      v.node.field_component === 'press_news_mention'
                        ? v.node.field_link_url_string
                        : '/' + v.node.full_slug
                    }
                    key={`news-item-${idx}`}
                  >
                    {v.node.field_title_string}
                  </WhiteLink>
                ))}
            </FooterLinkGroup>
          </FooterLinkGroups>
          <FooterLegal>
            <SocialWrapper>
              <SocialChiclets>
                <WhiteLink
                  to="https://www.facebook.com/swellenergy"
                  openInNewWindow
                  aria-label="Facebook"
                  rel="noopener"
                >
                  <Facebook />
                </WhiteLink>
                <WhiteLink
                  to="https://twitter.com/Swell_Energy"
                  openInNewWindow
                  aria-label="Twitter"
                  rel="noopener"
                >
                  <Twitter />
                </WhiteLink>
                <WhiteLink to="/blog" aria-label="Blog">
                  <Blog />
                </WhiteLink>
              </SocialChiclets>
              <BBB>
                <iframe
                  title="Swell Energy BBB Seal"
                  src="https://seal-sanjose.bbb.org/logo/ruhzbus/iframe/swell-services-679359.html"
                  width="100"
                  height="45"
                  frameBorder="0"
                  marginWidth={0}
                  marginHeight={0}
                  scrolling="no"
                />
              </BBB>
            </SocialWrapper>
            <Copyright>
              Swell Energy &copy; 2021 | CSLB Lic. # 1021183 |{' '}
              <WhiteLink to="/privacy-policy">Privacy &amp; Legal</WhiteLink>
            </Copyright>
          </FooterLegal>
        </Footer>
      </FooterContainer>
    </>
  );
}

// eslint-disable-next-line react/display-name
export default (props: PowerProTypes & { navItems: NavItemBlokProps[] }) => (
  <StaticQuery query={query} render={(data) => <NxFooter data={data} {...props} />} />
);
