import {
  QuestionProps,
  ContentProps,
  JourneyOptionProps,
} from '../components/NxJourneyLeadForm/LeadFormTypes';

export const requiredFieldsValidator = ({
  enable_journey_flow,
  journey_option,
  t_c_title,
  t_c_content,
  t_c_legal_verbiage,
  email_subject,
  email_body_template,
  journey_start_title,
}: Partial<ContentProps>) => {
  const errors = [];

  // validate email
  if (
    enable_journey_flow === 'yes' &&
    (journey_option as JourneyOptionProps[]).find((el) => el?.send_email)
  ) {
    if (!email_subject) errors.push(`Email Subject is required.`);
    const _email_body_template = email_body_template as unknown as any;
    if (
      _email_body_template.type === 'doc' &&
      !_email_body_template.content.some((el) => !!el.content)
    )
      errors.push(`Email Body Template is required.`);
  }

  // validate terms&conditions
  if (
    enable_journey_flow === 'yes' &&
    (journey_option as JourneyOptionProps[]).find((el) => el?.show_terms_and_conditions)
  ) {
    const _t_c_title = t_c_title as unknown as any;
    const _t_c_content = t_c_content as unknown as any;
    const _t_c_legal_verbiage = t_c_legal_verbiage as unknown as any;

    if (
      t_c_title?.length === 0 ||
      _t_c_title?.[0]?.content === '` `' ||
      _t_c_title?.[0]?.content === ''
    )
      errors.push(`T C Title is required.`);
    if (
      t_c_content?.length === 0 ||
      _t_c_content?.[0]?.content === '` `' ||
      _t_c_content?.[0]?.content === ''
    )
      errors.push(`T C Content is required.`);
    if (
      t_c_legal_verbiage?.length === 0 ||
      _t_c_legal_verbiage?.[0]?.content === '` `' ||
      _t_c_legal_verbiage?.[0]?.content === ''
    )
      errors.push(`T C Legal Verbiage is required.`);
  }

  // validate journey tab

  if (enable_journey_flow === 'yes') {
    const _journey_start_title = journey_start_title as unknown as any;

    if (
      journey_start_title?.length === 0 ||
      _journey_start_title?.[0]?.content === '` `' ||
      _journey_start_title?.[0]?.content === ''
    ) {
      errors.push(`Journey Start Title is required.`);
    }
  }

  // validating journey options
  if (enable_journey_flow === 'yes' && (!journey_option || journey_option?.length === 0)) {
    errors.push(`At least one Journey Option is required.`);
  }

  const journeyWithoutUrlQuestion = (journey_option as JourneyOptionProps[]).filter(
    (el) =>
      el?.question_type_options &&
      el?.question_type_options.length !== 0 &&
      (el?.question_type_options as QuestionProps[])?.[0]?.enable_external_link
  );

  journeyWithoutUrlQuestion.forEach((journeyOption) => {
    const getQuestionLinkUrl = (fieldName) =>
      (journeyOption?.question_type_options as QuestionProps[])?.[0]?.external_link_url?.[
        fieldName
      ] === '';

    const getQuestionLinkLabel = () =>
      (journeyOption?.question_type_options as QuestionProps[])?.[0]?.external_link_label === '';

    if (
      enable_journey_flow === 'yes' &&
      journey_option?.length !== 0 &&
      getQuestionLinkUrl('url') &&
      getQuestionLinkUrl('cached_url')
    ) {
      errors.push(
        `External Link Url in Journey Option ${journeyOption?.journey_option_title} is required`
      );
    }
    if (enable_journey_flow === 'yes' && journey_option?.length !== 0 && getQuestionLinkLabel()) {
      errors.push(
        `External Link Label in Journey Option ${journeyOption?.journey_option_title} is required`
      );
    }
  });

  const journeyWithQuestion = (journey_option as JourneyOptionProps[]).filter(
    (el) => el?.question_type_options && el?.question_type_options.length !== 0
  );

  journeyWithQuestion.forEach((journeyOption) => {
    const getQuestionDropdownValues = () =>
      (journeyOption?.question_type_options as QuestionProps[])?.[0]?.values?.split(';')?.[0] ===
      '';

    if (
      enable_journey_flow === 'yes' &&
      journey_option?.length !== 0 &&
      getQuestionDropdownValues()
    ) {
      errors.push(`Values in Journey Option ${journeyOption?.journey_option_title} is required`);
    }
  });

  return errors;
};
