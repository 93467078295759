import React from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { getComponentsForBlokArray } from '../NxComponents';

interface BlokProps extends BaseBlok {
  default_items: BaseBlok[];
  md_items?: BaseBlok[];
  lg_items?: BaseBlok[];
  xl_items?: BaseBlok[];
}

interface StyledProps {
  hasMd: boolean;
  hasLg: boolean;
  hasXl: boolean;
}

const Container = tw.div`h-full w-full`;

const Default = styled.div((props: StyledProps) => [
  tw`w-full h-full`,
  props.hasMd ? tw`md:hidden` : '',
  props.hasLg ? tw`lg:hidden` : '',
  props.hasXl ? tw`xl:hidden` : '',
]);

const Md = styled.div((props: StyledProps) => [
  tw`hidden w-full h-full md:block`,
  props.hasLg ? tw`lg:hidden` : '',
  props.hasXl ? tw`xl:hidden` : '',
]);

const Lg = styled.div((props: StyledProps) => [
  tw`hidden w-full h-full lg:block`,
  props.hasXl ? tw`xl:hidden` : '',
]);

const Xl = styled.div(() => [tw`hidden w-full h-full xl:block`]);

export default function NxResponsiveSelector(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { default_items, md_items, lg_items, xl_items } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Container>
        <Default
          hasMd={md_items?.length > 0}
          hasLg={lg_items?.length > 0}
          hasXl={xl_items?.length > 0}
        >
          {default_items?.length > 0 && getComponentsForBlokArray(default_items)}
        </Default>
        <Md hasMd={md_items?.length > 0} hasLg={lg_items?.length > 0} hasXl={xl_items?.length > 0}>
          {md_items?.length > 0 && getComponentsForBlokArray(md_items)}
        </Md>
        <Lg hasMd={md_items?.length > 0} hasLg={lg_items?.length > 0} hasXl={xl_items?.length > 0}>
          {lg_items?.length > 0 && getComponentsForBlokArray(lg_items)}
        </Lg>
        <Xl>{xl_items?.length > 0 && getComponentsForBlokArray(xl_items)}</Xl>
      </Container>
    </SbEditable>
  );
}
