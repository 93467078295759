import React from 'react';
import tw from 'twin.macro';

import { getComponentsForBlokArray } from '../../NxComponents';
import { ContentProps } from '../LeadFormTypes';

import { BackButton } from './BackButton';

const Wrapper = tw.form`bg-white max-w-[548px] rounded-md m-auto px-8 py-10 md:px-16 md:py-20 flex flex-col max-w-[456px] justify-center mt-8 mb-24`;
const HeadWrapper = tw.div`mb-14 flex  items-center justify-center relative`;
const Text = tw.p`text-gray-600 text-sm mt-1`;
const Row = tw.div`w-full max-h-[256px] overflow-y-auto`;

export const JourneyStart = ({
  journey_start_title,
  customer_type,
  journey_option,
}: ContentProps) => {
  return (
    <Wrapper>
      <HeadWrapper>
        <BackButton />
        {!!journey_start_title && getComponentsForBlokArray(journey_start_title)}
      </HeadWrapper>
      {!!customer_type && getComponentsForBlokArray(customer_type)}
      <Text>Select one of the following</Text>

      <Row>{!!journey_option && getComponentsForBlokArray(journey_option)}</Row>
    </Wrapper>
  );
};
