import tw, { styled } from 'twin.macro';

interface Props {
  active: boolean;
}

export const SlideContent = styled.div<Props>((props) => [
  tw`transition-all top-0 absolute w-full`,
  props.active ? tw`opacity-100` : tw`opacity-0`,
]);
