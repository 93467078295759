/* eslint-disable react/no-unknown-property */
import * as Sentry from '@sentry/gatsby';
import queryString from 'query-string';
import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import tw from 'twin.macro';

import { leads, metrics, programs } from '../../services';
import { useEnvironment } from '../EnvironmentContext';
import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ButtonTheme } from '../NxCommon';
import { getComponentsForBlokArray } from '../NxComponents';
import { NameValue } from '../NxLeadFormComponents/NxLeadFormSelect';

import LeadFormContent from './LeadFormContent';
import { FormData } from './LeadFormTypes';
import { Container } from './styles';

export enum CustomerNotesOrUTMReferrer {
  UTM_Referrer = 'UTM Referrer',
  Customer_Notes = 'Customer Notes',
}
interface BlokProps extends BaseBlok {
  // General
  success_content: BaseBlok[];
  preview_success_content: boolean;
  flow: undefined | 'normal' | 'program-eligibility';
  program_eligibility_failure_content: BaseBlok[];
  preview_program_eligibility_failure_content: boolean;
  i_own_my_home: boolean;
  additional_information: boolean;

  // Style
  theme: 'dark' | 'light';
  button_theme: ButtonTheme;
  submit_button_text: string;
  submitting_button_text: string;

  // Fields
  fields_powerwall: boolean;
  fields_interest: boolean;
  fields_interest_required: boolean;
  fields_original_installer: boolean;
  fields_original_installer_options: string;

  // Campaign
  integration_content: string;
  lead_source_account: string;
  promotion: string;
  customer_type: 'residential' | 'cx';
  campaign_set_lead_source_account_from_partner: boolean;
  campaign_set_owner_from_partner: boolean;

  // Analytics
  analytics_bing_event: boolean;
  analytics_fb_event: boolean;
  analytics_gtag_event: boolean;
  analytics_gtag_target: string;

  additional_information_input_placeholder_text: string;
  additional_information_input_title_text: string;
  customer_notes_or_utm_referrer: CustomerNotesOrUTMReferrer;
}

type SubmitStatus = 'idle' | 'submitting' | 'success' | 'error' | 'zip-ineligible';

export default function NxLeadForm(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { isInEditor, environment } = useEnvironment();
  const [status, setStatus] = useState('idle' as SubmitStatus);
  const flow = props.blok.flow || 'normal';

  const submitLead = async (values: FormData): Promise<boolean> => {
    const qs = queryString.parse(window.location.search);
    const queryStringPartner = qs && typeof qs.partnerId === 'string' ? qs.partnerId : undefined;

    const getPromotion = () => {
      if (!props.blok.promotion) return '';

      const promotions = JSON.parse(props.blok.promotion);
      return environment === 'prod' ? promotions.prod : promotions.dev;
    };

    const getLeadSourceAccount = () => {
      const leadSourceAccounts = props.blok.lead_source_account
        ? JSON.parse(props.blok.lead_source_account)
        : { dev: '0016100000fehkAAAQ', prod: '0014M00001k8A7fQAE' };

      if (props.blok.campaign_set_lead_source_account_from_partner && queryStringPartner) {
        return queryStringPartner;
      }

      return environment === 'prod' ? leadSourceAccounts.prod : leadSourceAccounts.dev;
    };

    const getOwner = () => {
      if (props.blok.campaign_set_owner_from_partner && queryStringPartner) {
        return queryStringPartner;
      }

      return '';
    };

    const getAdditionalInformation = (additionalInformation) => {
      if (additionalInformation)
        return `${
          props.blok.customer_notes_or_utm_referrer === CustomerNotesOrUTMReferrer.Customer_Notes
            ? 'Additional Information:'
            : ''
        } ${additionalInformation}`;
      else if (!values.numberOfPowerwalls) return '';

      return `How many batteries do you currently have installed? ${values.numberOfPowerwalls}`;
    };

    try {
      await leads.submitLead({
        ...values,
        ...{ isHomeowner: props.blok.i_own_my_home ? values.isHomeowner : false },
        utmContent: props.blok.integration_content || '',
        promotion: getPromotion(),
        leadSourceAccount: getLeadSourceAccount(),
        owner: getOwner(),
        notes: getAdditionalInformation(values.additionalInformation),
        customerType: props.blok.customer_type,
        originalInstaller: values.originalInstaller === '-' ? '' : values.originalInstaller,
        customerNotesOrUtmReferrer: props.blok.customer_notes_or_utm_referrer,
      });

      metrics.trackLeadFormSubmit(null, 'NxLeadForm', props.blok.integration_content);

      if (props.blok.analytics_bing_event) metrics.trackBingLeadEvent();
      if (props.blok.analytics_fb_event) metrics.trackFacebookLeadEvent();
      if (props.blok.analytics_gtag_event && props.blok.analytics_gtag_target)
        metrics.trackGoogleTagConversion(props.blok.analytics_gtag_target);

      return true;
    } catch (err) {
      Sentry.captureException(err);
      return false;
    }
  };

  const handleSubmitProgramEligibility = async (values: FormData) => {
    setStatus('submitting');

    const hasEligibleProgram = await programs.getIsZipInProgramTerritory(values.zip);
    const submitSuccess = await submitLead(values);

    if (!submitSuccess) {
      setStatus('error');
      return;
    }

    if (!hasEligibleProgram) {
      setStatus('zip-ineligible');
      return;
    }

    setStatus('success');
  };

  const handleSubmitNormal = async (values: FormData) => {
    setStatus('submitting');

    const success = await submitLead(values);
    setStatus(success ? 'success' : 'error');
  };

  const buttonText =
    status === 'submitting' ? props.blok.submitting_button_text : props.blok.submit_button_text;
  const showSuccess = status === 'success' || (isInEditor && props.blok.preview_success_content);
  const showZipFailure =
    status === 'zip-ineligible' ||
    (isInEditor && props.blok.preview_program_eligibility_failure_content);

  const showLeadForm = !(showSuccess || showZipFailure);

  const installerOptions: NameValue[] = [];

  if (props.blok.fields_original_installer_options) {
    const optionsArray: any[] = JSON.parse(props.blok.fields_original_installer_options || '[]');

    installerOptions.push(
      ...optionsArray.map((v) => {
        return {
          name: v.name,
          value: environment === 'prod' ? v.prod : v.dev,
        };
      })
    );

    installerOptions.push({ name: 'Another Company', value: '-' });
  }

  return (
    <SbEditable content={props.blok}>
      <Container textColor={props.blok.theme}>
        {isInEditor &&
          flow === 'program-eligibility' &&
          props.blok.program_eligibility_failure_content?.length < 1 && (
            <div css={[tw`bg-red-400 m-4 border border-red-600 text-white p-4`]}>
              When using Program Eligibility Flow for lead form, &quot;Program Eligibility -
              Ineligible Zip Content&quot; cannot be empty.
            </div>
          )}
        {showSuccess && <>{getComponentsForBlokArray(props.blok.success_content)}</>}
        {showZipFailure && (
          <>{getComponentsForBlokArray(props.blok.program_eligibility_failure_content)}</>
        )}
        {showLeadForm && (
          <LeadFormContent
            additionalInformation={props.blok.additional_information}
            iOwnMyHome={props.blok.i_own_my_home}
            theme={props.blok.theme}
            buttonTheme={props.blok.button_theme}
            buttonText={buttonText}
            showInterestField={props.blok.fields_interest}
            isInterestRequired={props.blok.fields_interest_required}
            showPowerwallField={props.blok.fields_powerwall}
            showOriginalInstallerField={props.blok.fields_original_installer}
            additionalInformationInputPlaceholderText={
              props.blok.additional_information_input_placeholder_text
            }
            additionalInformationInputTitleText={props.blok.additional_information_input_title_text}
            installerOptions={installerOptions}
            handleSubmit={flow === 'normal' ? handleSubmitNormal : handleSubmitProgramEligibility}
            isError={status === 'error'}
          />
        )}
      </Container>
    </SbEditable>
  );
}
