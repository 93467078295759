import React, { useContext } from 'react';

export interface PageStylesValues {
  primaryColor?: string;
  errorColor?: string;
  disabledFillColor?: string;
  disabledFontColor?: string;
  baseFillColor?: string;
  fontColor?: string;
}
interface EnvironmentContextValues {
  isInEditor: boolean;
  environment: 'dev' | 'prod';
  storyName: string;
  pageStyles?: PageStylesValues;
}

export const EnvironmentContext = React.createContext<EnvironmentContextValues>({
  isInEditor: false,
  environment: 'prod',
  storyName: '',
  pageStyles: null,
});

export function useEnvironment() {
  return useContext(EnvironmentContext);
}
