const ccpa = {
  async submitDataRequest({
    firstName,
    lastName,
    email,
    requesterType,
    state,
    tellMe,
    doNotSell,
    deleteInfo,
    sendMe,
    other,
    otherText,
  }) {
    const request = {
      firstName,
      lastName,
      emailAddress: email,
      requesterType,
      state,
      requests: {
        show: tellMe ? 'Yes' : 'No',
        doNotSell: doNotSell ? 'Yes' : 'No',
        removeInfo: deleteInfo ? 'Yes' : 'No',
        sendInfo: sendMe ? 'Yes' : 'No',
        other: other ? 'Yes: ' + otherText : 'No',
      },
    };

    const isProd =
      window.location.origin.indexOf('swellenergy.com') > -1 &&
      window.location.origin.indexOf('sbx') === -1;
    const url = isProd
      ? 'https://sb7ji7t134.execute-api.us-west-1.amazonaws.com/default/ccpa-data-request-prod'
      : 'https://7o7gkhzzj2.execute-api.us-east-1.amazonaws.com/default/ccpa-data-request-dev';

    const requestArgs = {
      headers: { 'content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(request),
      method: 'POST',
      mode: 'cors',
    };

    await fetch(url, requestArgs);
  },
};

export default ccpa;
