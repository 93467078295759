import ReactMarkdown from 'react-markdown';
import tw, { css, styled } from 'twin.macro';

import { TextAlign, TextAlignMap } from '../NxCommon';
import { isColorSet, StoryBlokColor } from '../StoryBlokColor';

import NxLineHeightMap, { NxRichTextLineHeight } from './NxRichTextLineHeight';

export type FontSizeSet = 'normal' | 'smaller' | 'larger';

interface RichTextContainerProps {
  baseTextColor?: StoryBlokColor;
  strongTextColor?: StoryBlokColor;
  lineHeight: NxRichTextLineHeight;
  textAlign: TextAlign;
  fontSizeSet: FontSizeSet;
  primaryColor?: string;
}

export const RichTextContainer = styled(ReactMarkdown)((props: RichTextContainerProps) => [
  isColorSet(props.baseTextColor) ? `color: ${props.baseTextColor.color};` : tw`text-swell-gray`,
  NxLineHeightMap[props.lineHeight],
  css`
    -webkit-font-smoothing: antialiased;
    word-break: break-word;
  `,
  css`
    h1 {
      ${tw`font-heading my-0`}
      ${props.fontSizeSet === 'normal' ? tw`text-4xl lg:text-5xl 2xl:text-6xl` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-xl lg:text-3xl xl:text-4xl` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-5xl lg:text-6xl xl:text-7xl` : ''}
      ${NxLineHeightMap[props.lineHeight]}
      ${TextAlignMap[props.textAlign]}
    }
  `,
  css`
    h2 {
      ${tw`font-heading my-0`}
      ${props.fontSizeSet === 'normal' ? tw`text-3xl lg:text-4xl` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-2xl lg:text-3xl` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-4xl lg:text-5xl` : ''}
      ${NxLineHeightMap[props.lineHeight]}
      ${TextAlignMap[props.textAlign]}
    }
  `,
  css`
    h3 {
      ${tw`font-heading my-0`}
      ${props.fontSizeSet === 'normal' ? tw`text-2xl lg:text-3xl` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-xl lg:text-2xl` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-3xl lg:text-4xl` : ''}
      ${NxLineHeightMap[props.lineHeight]}
      ${TextAlignMap[props.textAlign]}
    }
  `,
  css`
    h4 {
      ${tw`font-heading my-0`}
      ${props.fontSizeSet === 'normal' ? tw`text-base md:text-xl` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-sm md:text-base` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-xl md:text-2xl` : ''}
      ${NxLineHeightMap[props.lineHeight]}
      ${TextAlignMap[props.textAlign]}
    }
  `,
  css`
    h5 {
      ${tw`font-heading my-0`}
      ${props.fontSizeSet === 'normal' ? tw`text-base md:text-lg` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-sm md:text-tiny` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-xl md:text-xl` : ''}
      ${NxLineHeightMap[props.lineHeight]}
      ${TextAlignMap[props.textAlign]}
    }
  `,
  css`
    h6 {
      ${tw`font-heading my-0`}
      ${props.fontSizeSet === 'normal' ? tw`text-base md:text-base` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-sm md:text-sm` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-xl md:text-lg` : ''}
      ${TextAlignMap[props.textAlign]}
      ${NxLineHeightMap[props.lineHeight]}
    }
  `,
  css`
    p,
    li {
      ${tw`font-sans my-0 font-extralight`}
      ${props.fontSizeSet === 'normal' ? tw`text-xs lg:text-sm` : ''}
      ${props.fontSizeSet === 'smaller' ? tw`text-xs` : ''}
      ${props.fontSizeSet === 'larger' ? tw`text-sm md:text-base` : ''}
      ${NxLineHeightMap[props.lineHeight]}
      ${TextAlignMap[props.textAlign]}
    }
  `,
  css`
    p {
      ${tw`pb-4 last:pb-0`}
    }
  `,
  css`
    ul {
      ${tw`list-disc my-0`}
      list-style-position: inside;
    }
    ol {
      list-style: auto;

      ${tw`my-0`}
      list-style-position: inside;
    }
  `,
  css`
    a {
      ${tw`text-swell-blue cursor-pointer transition-colors hover:(text-swell-blue-light underline)`}
      color: ${props.primaryColor};
    }
  `,
  css`
    strong {
      ${tw`font-bold text-[1.2em]`}
      ${isColorSet(props.strongTextColor)
        ? `color: ${props.strongTextColor.color};`
        : tw`text-black `},
    }
  `,
  css`
    blockquote {
      border-left: 3px solid #dfe3e8;
      font-size: 1.4rem;
      font-style: italic;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0.4em;
      margin: 0px;
      ${tw`pb-4 last:pb-0`}
    }
  `,
]);
