import ReactMarkdown from 'react-markdown';
import tw, { css, styled } from 'twin.macro';

export const AgreementTextContainer = styled(ReactMarkdown)(() => [
  tw`text-swell-gray w-full overflow-x-hidden p-2`,
  'height: fit-content;',
  css`
    -webkit-font-smoothing: antialiased;
  `,
  css`
    h1 {
      ${tw`font-heading my-0 text-xl lg:text-2xl`}
    }
  `,
  css`
    h2 {
      ${tw`font-heading my-0 text-xl lg:text-2xl`}
    }
  `,
  css`
    h3 {
      ${tw`font-heading my-0 text-xl lg:text-2xl`}
    }
  `,
  css`
    h4 {
      ${tw`font-heading my-0 text-xl lg:text-2xl`}
    }
  `,
  css`
    p,
    li {
      ${tw`font-sans mb-2 font-extralight text-xs md:text-sm`}
    }
  `,
  css`
    p {
      ${tw`pb-4 last:pb-0`}
    }
  `,
  css`
    ul {
      ${tw`list-none ml-6 my-0`}
    }
  `,
  css`
    ol {
      ${tw`list-decimal ml-6 my-0`}
    }
  `,
  css`
    a {
      ${tw`text-swell-blue cursor-pointer transition-colors hover:(text-swell-blue-light underline)`}
    }
  `,
  css`
    strong {
      ${tw`font-bold text-black`}
    }
  `,
]);
