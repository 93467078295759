import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import { AlertCircle } from 'react-feather';
import tw, { styled, css } from 'twin.macro';
import * as yup from 'yup';

import { useLeadForm } from '../../hooks/useLeadForm';
import { getSourceSystemValues } from '../../utils/getSourceSystemValues';
import { useEnvironment } from '../EnvironmentContext';
import { NxBaseProps } from '../NxBaseProps';
import { QuestionProps } from '../NxJourneyLeadForm/LeadFormTypes';
import { SubmitButton } from '../NxLeadForm/styles';
import NxLeadFormInput from '../NxLeadFormComponents/NxLeadFormInput';

const ValidationSchemaShape = {
  answer: yup.string().required('Please enter your answer'),
};

const initialValues = {
  answer: '',
};

const Link = styled.a(({ primaryColor }: { primaryColor?: string }) => [
  tw` text-xs flex items-center mb-8 hover:text-current hover:no-underline`,
  css`
    color: ${primaryColor || '#007bff'};
  `,
]);

const Wrapper = tw.form`bg-white  rounded-md  flex flex-col justify-center mt-2 md:mb-24`;
const AlertCircleIcon = styled(AlertCircle)(() => [tw`mr-1`]);

const validationObjectShape: any = { ...ValidationSchemaShape };

const validationSchema = yup.object().shape(validationObjectShape);

export const NxQuestionFreeTextField = (props: NxBaseProps<QuestionProps>) => {
  const {
    label,
    external_link_url,
    enable_external_link,
    external_link_label,
    map_value_to_source_system,
    source,
    source_field,
    sbx_gateway_asset_product_id,
    prod_gateway_asset_product_id,
  } = props.blok;

  const {
    setJourneyFlowConfig,
    journeyFlowConfig,
    pageStyles: { primaryColor },
  } = useLeadForm();

  const { environment } = useEnvironment();

  const gateway_asset_product_id =
    environment === 'prod' ? prod_gateway_asset_product_id : sbx_gateway_asset_product_id;

  const formik = useFormik({
    initialValues: journeyFlowConfig?.information?.question
      ? {
          answer: journeyFlowConfig?.information?.question.answer,
        }
      : initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (formValues, formikHelpers: FormikHelpers<any>) => {
      setJourneyFlowConfig({
        payload: {
          type: 'QUESTION',
          payload: { answer: formValues.answer, question: label, type: 'TextField' },
        },
        type: 'INFORMATION',
      });

      setJourneyFlowConfig({
        payload: {
          journeyOptions: [
            ...journeyFlowConfig.journeyOptions,
            ...(await getSourceSystemValues({
              label,
              answer: formValues.answer,
              map_value_to_source_system,
              source,
              source_field,
              gateway_asset_product_id,
              isProd: environment === 'prod',
            })),
          ],
        },
        type: 'UPDATE',
      });

      setJourneyFlowConfig({ type: 'HANDLE_NEXT' });
      formikHelpers.setSubmitting(false);
    },
  });

  return (
    <Wrapper onSubmit={formik.handleSubmit}>
      <NxLeadFormInput id="answer" name="answer" label={label} formik={formik} required />
      {enable_external_link && (
        <Link
          primaryColor={primaryColor}
          href={external_link_url.url || `/${external_link_url.cached_url}`}
          target="_blank"
        >
          <AlertCircleIcon size="1rem" />
          {external_link_label}
        </Link>
      )}
      <SubmitButton type="submit" disabled={formik.isSubmitting || !formik.isValid}>
        Next
      </SubmitButton>
    </Wrapper>
  );
};
