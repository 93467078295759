import { BaseBlok } from '../NxBaseProps';
import { StoryBlokAsset } from '../StoryBlokAsset';
import { StoryBlokLink } from '../StoryBlokLink';

export type SubmitStatus = 'idle' | 'submitting' | 'success' | 'error';

export interface BlokProps extends BaseBlok {
  success_content?: BaseBlok[];
  enable_journey_flow: 'yes' | 'no';
  title: BaseBlok[];
  legal_verbiage: BaseBlok[];
  submit_button_text: string;
  submitting_button_text: string;
  journey_start_title: BaseBlok[];
  customer_type: BaseBlok[];
  t_c_content: BaseBlok[];
  t_c_legal_verbiage: BaseBlok[];
  t_c_title: BaseBlok[];
  program_overview_title: BaseBlok[];
  program_content: BaseBlok[];
  body_of_dupe_check_message: BaseBlok[];
  dupe_check_title: BaseBlok[];
  email_body_template: StoryBlokAsset;
  email_subject: string;
  story_name?: string;
  journey_option: BaseBlok[];
  wave_install_partner_id: string;
  wave_partner_id: string;
  wave_utility_id: string;
  wave_utility_program_id: string;
  integration_content?: string;
  email_from?: string;
  email_bcc: string;
  email_cc: string;
  t_c_agreement_pdf: StoryBlokAsset;
  sbx_wave_install_partner_id: string;
  sbx_wave_partner_id: string;
  sbx_wave_utility_id: string;
  sbx_wave_utility_program_id: string;
  gs_opportunity_type: string;
}
export enum SubmitType {
  NONE = 'unassignedLead',
  WAVE = 'waveGsLead',
  SALESFORCE = 'salesforceLead',
}

export enum ExternalResourceType {
  WAVEGS = 'waveGs',
  SALESFORCE = 'salesforce',
}

export enum SourceField {
  GATEWAY_ASSET_SERIAL_NUMBER = 'Installed_Products.gateway_serial_number',
  GATEWAY_ASSET_PRODUCT_ID = 'Installed_Products.gateway_product_id',
}

export interface JourneyOptionProps {
  success_message_title?: BaseBlok[];
  success_message_body?: BaseBlok[];
  show_program_overview?: boolean;
  show_terms_and_conditions?: boolean;
  send_email?: boolean;
  submit_type?: SubmitType;
  journey_option_title?: string;
  question_type_options?: BaseBlok[];
  _uid: string;
  question_title?: BaseBlok[];
  question_input_title?: BaseBlok[];
}

export interface JourneyOptionBlokProps extends BaseBlok, JourneyOptionProps {}

export interface QuestionProps extends BaseBlok {
  label: string;
  values?: string;
  external_link_url?: StoryBlokLink;
  external_link_label?: string;
  enable_external_link?: boolean;
  map_value_to_source_system?: boolean;
  source?: ExternalResourceType;
  source_field?: string;
  sbx_gateway_asset_product_id: string;
  prod_gateway_asset_product_id: string;
}

export interface journeyResult {
  id: string;
}

export type LeadFormResult = any; //TODO  UpsertSmudCustomerEnrollmentResponseDto | undefined

export interface ContentProps extends BlokProps {
  legal_verbiage: BaseBlok[];
  buttonText: string;
  handleSubmit: (values: FormData | string) => Promise<LeadFormResult>;
  isError: boolean;
}

export interface UserAddress {
  address: string;
  appt?: string | null;
  city?: string;
  state?: string;
  zip?: number | null;
  country?: string;
  lat: number;
  lng: number;
  streetNumber: string;
  unit: string;
}

export interface FormData {
  name: string;
  email: string;
  phone: string;
  address: string;
  streetAddress: string;
  streetNumber: string;
  city: string;
  state: string;
  zip: string;
  unit: string;
}
