import * as yup from 'yup';

import { PhoneRegex } from '../../constants';

const InterestOptions = [
  { name: 'Home Battery Only', value: 'Battery Only' },
  { name: 'Home Battery with Existing Solar', value: 'Battery with Existing Solar' },
  { name: 'Solar Power Only', value: 'Solar Only' },
  { name: 'Virtual Power Plant', value: 'Virtual Power Plant' },
  { name: 'Something Else', value: 'Something Else' },
];

const NumberOfPowerwallsOptions = [
  { name: '0', value: '0' },
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3+', value: '3+' },
];

interface FormData {
  name: string;
  email: string;
  phone: string;
  zip: string;
  interest?: string;
  isHomeowner?: boolean;
  numberOfPowerwalls?: string | undefined;
  originalInstaller?: string | undefined;
  additionalInformation?: string;
}

const InitialValues: FormData = {
  name: '',
  email: '',
  phone: '',
  zip: '',
  isHomeowner: false,
  additionalInformation: '',
};

const ValidationSchemaShape = {
  name: yup
    .string()
    .required('Please enter your name.')
    .test(
      'name',
      'Please enter first and last name',
      (value) => value?.trim().split(/\s+/).length > 1
    ),
  email: yup
    .string()
    .required('Please enter a valid email address.')
    .email('Please enter a valid email address.'),
  phone: yup
    .string()
    .required('Please enter a valid phone number.')
    .matches(PhoneRegex, 'Please enter a valid phone number.'),
  zip: yup
    .string()
    .required('Please enter a valid zip code.')
    .matches(/\d{5}/, 'Please enter a valid zip code.'),
};

export {
  InterestOptions,
  NumberOfPowerwallsOptions,
  FormData,
  InitialValues,
  ValidationSchemaShape,
};
