import React from 'react';

import { useLeadForm } from '../../hooks/useLeadForm';
import { GenericLeadJourneyOption } from '../../services/leads';
import { NxBaseProps } from '../NxBaseProps';
import { ButtonItem } from '../NxJourneyLeadForm/components/ButtonItem';
import {
  ExternalResourceType,
  JourneyOptionBlokProps,
  SubmitType,
} from '../NxJourneyLeadForm/LeadFormTypes';

export const NxJourneyOption = (props: NxBaseProps<JourneyOptionBlokProps>) => {
  const {
    journey_option_title,
    send_email,
    show_program_overview,
    show_terms_and_conditions,
    submit_type,
  } = props.blok;

  const {
    setJourneyFlowConfig,
    journeyFlowConfig: {
      integration_content,
      email_from,
      email_bcc,
      email_cc,
      email_subject,
      t_c_agreement_pdf,
      gs_opportunity_type,
    },
  } = useLeadForm();

  const onSubmit = async () => {
    const journeyOptions: GenericLeadJourneyOption[] = [
      {
        name: 'journeyOptionTitle',
        label: journey_option_title,
        value: true,
      },
      {
        name: 'sendEmail',
        value: send_email,
      },
      {
        name: 'showProgramOverview',
        value: show_program_overview,
      },
      {
        name: 'showTermsAndConditions',
        value: show_terms_and_conditions,
      },
      {
        name: 'submitType',
        value: submit_type,
      },
    ];

    if (integration_content)
      journeyOptions.push({
        name: `${ExternalResourceType.SALESFORCE}:utmContent__c`,
        label: 'Integration Content',
        value: integration_content,
      });

    if (send_email) {
      journeyOptions.push({
        name: 'email:subject',
        value: email_subject ?? '',
      });
      journeyOptions.push({
        name: 'email:attachmentUrl',
        value: t_c_agreement_pdf ?? '',
      });
      journeyOptions.push({
        name: 'email:bcc',
        value: email_bcc ?? '',
      });
      journeyOptions.push({
        name: 'email:cc',
        value: email_cc ?? '',
      });
      journeyOptions.push({
        name: 'email:from',
        value: email_from ?? '',
      });
    }

    if (submit_type === SubmitType.WAVE) {
      journeyOptions.push({
        name: `${ExternalResourceType.WAVEGS}:type`,
        value: gs_opportunity_type,
      });
    }

    setJourneyFlowConfig({
      payload: {
        ...props.blok,
        journeyOptions,
      },
      type: 'UPDATE',
    });

    if (journey_option_title && journey_option_title !== journey_option_title) {
      //if a new value is selected question input value will reset
      setJourneyFlowConfig({
        payload: {
          type: 'QUESTION',
          payload: { answer: '', question: '', type: 'TextField' },
        },
        type: 'INFORMATION',
      });
    }
    setJourneyFlowConfig({ payload: props.blok, type: 'HANDLE_NEXT' });
  };

  return (
    <div className="w-full">
      <ButtonItem onClick={() => onSubmit()}>{journey_option_title}</ButtonItem>
    </div>
  );
};
