import React from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { StoryBlokAsset } from '../StoryBlokAsset';

interface BlokProps extends BaseBlok {
  asset: StoryBlokAsset;
  center_h?: boolean;
  clamp_width?: boolean;
  lazy_load?: boolean;
}

interface ImageProps {
  centerH: boolean;
  clampWidth: boolean;
  width: string;
  height: string;
}

const Picture = styled.picture<ImageProps>((props) => [
  props.centerH ? tw`mx-auto block` : '',
  props.clampWidth ? tw`max-w-full h-auto` : `width: ${props.width};height: ${props.height};`,
]);

const Image = styled.img<ImageProps>((props) => [
  props.centerH ? tw`mx-auto block` : '',
  props.clampWidth ? tw`max-w-full h-auto` : `width: ${props.width};height: ${props.height};`,
]);

export default function NxImage(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { asset, center_h, clamp_width, lazy_load } = props.blok;

  const imageSize = /https:\/\/a.storyblok.com\/f\/\d+\/(\d+)x(\d+).*/g.exec(asset?.filename);
  const imageSourceMatch = /https:\/\/a\.storyblok\.com\/(.*)/g.exec(asset?.filename);
  const width = imageSize?.length > 2 ? `${imageSize[1]}px` : 'auto';
  const height = imageSize?.length > 2 ? `${imageSize[2]}px` : 'auto';

  if (imageSourceMatch?.length > 1) {
    return (
      <SbEditable content={props.blok}>
        <Picture centerH={!!center_h} clampWidth={!!clamp_width} width={width} height={height}>
          <source
            srcSet={`https://img2.storyblok.com/0x0/filters:format(webp)/${imageSourceMatch[1]}`}
            type="image/webp"
          />
          <source srcSet={asset.filename} type="image/jpeg" />
          <Image
            src={asset.filename}
            centerH={!!center_h}
            clampWidth={!!clamp_width}
            loading={lazy_load ? 'lazy' : 'eager'}
            width={width}
            height={height}
            alt={asset.alt || '(no description)'}
          />
        </Picture>
      </SbEditable>
    );
  } else {
    return (
      <SbEditable content={props.blok}>
        <Image
          src={asset.filename}
          centerH={!!center_h}
          clampWidth={!!clamp_width}
          loading={lazy_load ? 'lazy' : 'eager'}
          width={width}
          height={height}
        />
      </SbEditable>
    );
  }
}
