import tw, { theme, TwStyle } from 'twin.macro';

export type BackgroundBreakpoint = 'default' | 'sm' | 'md' | 'lg' | 'xl';
export type BackgroundPosition =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'left top'
  | 'left bottom'
  | 'right'
  | 'right top'
  | 'right bottom';
export type BackgroundRepeat = 'repeat' | 'no-repeat' | 'repeat-x' | 'repeat-y';
export type BackgroundSize = 'auto' | 'cover' | 'contain';
export type WrapBreakpoint = 'sm' | 'md' | 'lg' | 'xl';
export type ButtonTheme = 'blue' | 'white';
export type ButtonSize = 'smaller' | 'normal';

export type ContainerItemsBehavior = 'start' | 'end' | 'stretch' | 'center';
export const ContainerItemsBehaviorMap: Record<ContainerItemsBehavior, TwStyle> = {
  start: tw`items-start`,
  end: tw`items-end`,
  stretch: tw`items-stretch`,
  center: tw`items-center`,
};

export type BorderRounding = 'none' | 'sm' | 'normal' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';
export const BorderRoundingMap: Record<BorderRounding, TwStyle> = {
  none: tw`rounded-none`,
  sm: tw`rounded-sm`,
  normal: tw`rounded`,
  md: tw`rounded-md`,
  lg: tw`rounded-lg`,
  xl: tw`rounded-xl`,
  '2xl': tw`rounded-2xl`,
  '3xl': tw`rounded-3xl`,
  full: tw`rounded-full`,
};

export type TextAlign = 'left' | 'center' | 'right' | 'justify';
export const TextAlignMap: Record<TextAlign, TwStyle> = {
  left: tw`text-left`,
  center: tw`text-center`,
  right: tw`text-right`,
  justify: tw`text-justify`,
};

export type ElementHorizontalPosition = 'left' | 'center' | 'right';
export const ElementHorizontalPositionMap: Record<ElementHorizontalPosition, TwStyle> = {
  left: tw`mr-auto`,
  center: tw`mx-auto`,
  right: tw`ml-auto`,
};

export type ElementVerticalPosition = 'top' | 'center' | 'bottom';
export const ElementVerticalPositionMap: Record<ElementVerticalPosition, TwStyle> = {
  top: tw`mb-auto`,
  center: tw`my-auto`,
  bottom: tw`mt-auto`,
};

export type GutterSize =
  | '0'
  | '1'
  | '1.5'
  | '2'
  | '2.5'
  | '3'
  | '3.5'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '14'
  | '16'
  | '20'
  | '24'
  | '28'
  | '32'
  | '36'
  | '40'
  | '44'
  | '48'
  | '52'
  | '56'
  | '60'
  | '64'
  | '72'
  | '80'
  | '96';
export const GutterSizeHorizontalMap: Record<GutterSize, TwStyle> = {
  ['0']: tw`space-x-0`,
  ['1']: tw`space-x-1`,
  ['1.5']: tw`space-x-1.5`,
  ['2']: tw`space-x-2`,
  ['2.5']: tw`space-x-2.5`,
  ['3']: tw`space-x-3`,
  ['3.5']: tw`space-x-3.5`,
  ['4']: tw`space-x-4`,
  ['5']: tw`space-x-5`,
  ['6']: tw`space-x-6`,
  ['7']: tw`space-x-7`,
  ['8']: tw`space-x-8`,
  ['9']: tw`space-x-9`,
  ['10']: tw`space-x-10`,
  ['11']: tw`space-x-11`,
  ['12']: tw`space-x-12`,
  ['14']: tw`space-x-14`,
  ['16']: tw`space-x-16`,
  ['20']: tw`space-x-20`,
  ['24']: tw`space-x-24`,
  ['28']: tw`space-x-28`,
  ['32']: tw`space-x-32`,
  ['36']: tw`space-x-36`,
  ['40']: tw`space-x-40`,
  ['44']: tw`space-x-44`,
  ['48']: tw`space-x-48`,
  ['52']: tw`space-x-52`,
  ['56']: tw`space-x-56`,
  ['60']: tw`space-x-60`,
  ['64']: tw`space-x-64`,
  ['72']: tw`space-x-72`,
  ['80']: tw`space-x-80`,
  ['96']: tw`space-x-96`,
};
export const GutterSizeVerticalMap: Record<GutterSize, TwStyle> = {
  ['0']: tw`space-y-0`,
  ['1']: tw`space-y-1`,
  ['1.5']: tw`space-y-1.5`,
  ['2']: tw`space-y-2`,
  ['2.5']: tw`space-y-2.5`,
  ['3']: tw`space-y-3`,
  ['3.5']: tw`space-y-3.5`,
  ['4']: tw`space-y-4`,
  ['5']: tw`space-y-5`,
  ['6']: tw`space-y-6`,
  ['7']: tw`space-y-7`,
  ['8']: tw`space-y-8`,
  ['9']: tw`space-y-9`,
  ['10']: tw`space-y-10`,
  ['11']: tw`space-y-11`,
  ['12']: tw`space-y-12`,
  ['14']: tw`space-y-14`,
  ['16']: tw`space-y-16`,
  ['20']: tw`space-y-20`,
  ['24']: tw`space-y-24`,
  ['28']: tw`space-y-28`,
  ['32']: tw`space-y-32`,
  ['36']: tw`space-y-36`,
  ['40']: tw`space-y-40`,
  ['44']: tw`space-y-44`,
  ['48']: tw`space-y-48`,
  ['52']: tw`space-y-52`,
  ['56']: tw`space-y-56`,
  ['60']: tw`space-y-60`,
  ['64']: tw`space-y-64`,
  ['72']: tw`space-y-72`,
  ['80']: tw`space-y-80`,
  ['96']: tw`space-y-96`,
};

export interface ScreenWidths {
  width?: string;
  width_sm?: string;
  width_md?: string;
  width_lg?: string;
  width_xl?: string;
}

export const getScreenWidths = (props: ScreenWidths) => {
  const result = [];

  if (props.width) {
    result.push(`width: ${props.width};`);
  }

  if (props.width_sm) {
    result.push(`@media (min-width: 0) { width: ${props.width_sm};}`);
  }

  if (props.width_md) {
    result.push(`@media (min-width: ${theme`screens.md`}) { width: ${props.width_md};}`);
  }

  if (props.width_lg) {
    result.push(`@media (min-width: ${theme`screens.lg`}) { width: ${props.width_lg};}`);
  }

  if (props.width_xl) {
    result.push(`@media (min-width: ${theme`screens.xl`}) { width: ${props.width_xl};}`);
  }

  return result;
};

export interface ScreenHeights {
  height?: string;
  height_sm?: string;
  height_md?: string;
  height_lg?: string;
  height_xl?: string;
}

export const getScreenHeights = (props: ScreenHeights) => {
  const result = [];

  if (props.height) {
    result.push(`height: ${props.height};`);
  }

  if (props.height_sm) {
    result.push(`@media (min-width: 0) { height: ${props.height_sm};}`);
  }

  if (props.height_md) {
    result.push(`@media (min-width: ${theme`screens.md`}) { height: ${props.height_md};}`);
  }

  if (props.height_lg) {
    result.push(`@media (min-width: ${theme`screens.lg`}) { height: ${props.height_lg};}`);
  }

  if (props.height_xl) {
    result.push(`@media (min-width: ${theme`screens.xl`}) { height: ${props.height_xl};}`);
  }

  return result;
};

export interface EnvironmentValue {
  dev: string;
  prod: string;
}
