import * as yup from 'yup';

import { PhoneRegex } from '../../constants';
import { LabelValue } from '../NxLeadFormComponents/NxLeadFormSelectMulti';

interface FormData {
  company: string;
  website: string;
  address: string;
  zip: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  description: string;
  acceptTerms: boolean;
  interests: string[];
  serviceArea: string;
}

const InitialValues: FormData = {
  company: '',
  website: '',
  address: '',
  zip: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  description: '',
  acceptTerms: false,
  interests: [],
  serviceArea: '',
};

const ServiceAreaOptions: LabelValue[] = [
  { label: 'Alabama', value: 'Ala.' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

const ValidationShape = {
  company: yup.string().required('Required'),
  website: yup.string().required('Required'),
  address: yup.string().required('Required'),
  zip: yup.string().required('Enter your ZIP code').matches(/\d{5}/, 'Enter your ZIP code'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phone: yup
    .string()
    .required('Enter your phone number')
    .matches(PhoneRegex, 'Enter your phone number'),
  email: yup
    .string()
    .required('Enter a valid e-mail address')
    .email('Enter a valid e-mail address'),
};

export { ServiceAreaOptions, FormData, InitialValues, ValidationShape };
