import React, { useEffect, useState } from 'react';
import SbEditable from 'storyblok-react';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { ElementHorizontalPosition } from '../NxCommon';
import { StoryBlokAsset } from '../StoryBlokAsset';
import withLinkWrap from '../withLinkWrap';

import { ImageLinkProps, InternalLink, ExternalLink } from './ImageContainer';

interface BlokProps extends BaseBlok {
  target: string;
  normal_image: StoryBlokAsset;
  hover_image: StoryBlokAsset;
  active_image: StoryBlokAsset;
  horizontal_align: ElementHorizontalPosition;
  width: string;
}

const ImageButton = withLinkWrap<ImageLinkProps & React.DOMAttributes<HTMLAnchorElement>>(
  InternalLink,
  ExternalLink
);

export default function NxImageLink(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { target, normal_image, hover_image, horizontal_align, width } = props.blok;

  const [isHover, setHover] = useState(false);

  useEffect(() => {
    if (normal_image?.filename) {
      const img = new Image();
      img.src = normal_image?.filename;
    }
  }, [normal_image]);

  useEffect(() => {
    if (hover_image?.filename) {
      const img = new Image();
      img.src = hover_image?.filename;
    }
  }, [hover_image]);

  const targetImage =
    isHover && hover_image?.filename ? hover_image.filename : normal_image?.filename;
  const targetImageAlt =
    (isHover && hover_image?.alt ? hover_image.alt : normal_image?.alt) || 'Link';

  return (
    <SbEditable content={props.blok}>
      <ImageButton
        to={target}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        horizontalAlign={horizontal_align}
        width={width}
      >
        {!targetImage && <span>Image not set</span>}
        <img src={targetImage} alt={targetImageAlt} />
      </ImageButton>
    </SbEditable>
  );
}
