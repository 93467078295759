import React from 'react';
import tw from 'twin.macro';

import { BaseBlok } from '../../NxBaseProps';
import { getComponentsForBlokArray } from '../../NxComponents';

const Wrapper = tw.div`bg-white max-w-[548px] rounded-md m-auto px-8 py-10 md:px-16 md:py-20 flex flex-col max-w-[456px] items-center justify-center mt-8 mb-32`;

export const AlternativeFlow = ({ succesContent }: { succesContent?: BaseBlok[] }) => {
  return <Wrapper>{getComponentsForBlokArray(succesContent)}</Wrapper>;
};
