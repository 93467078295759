import * as yup from 'yup';

import { PhoneRegex } from '../../constants';

interface FormData {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  cellPhone: string;
}

const InitialValues: FormData = {
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  cellPhone: '',
};

const ValidationSchemaShape = {
  name: yup
    .string()
    .required('Please enter first and last name')
    .matches(/\w+ \w+/, 'Please enter first and last name.'),
  address: yup.string().required('Please enter an address.'),
  city: yup.string().required('Please enter a city.'),
  state: yup.string().required('Please enter a state.'),
  zip: yup
    .string()
    .required('Please enter a valid zip code.')
    .matches(/\d{5}/, 'Please enter a valid zip code.'),
  email: yup
    .string()
    .required('Please enter a valid email address.')
    .email('Please enter a valid email address.'),
  cellPhone: yup
    .string()
    .required('Please enter a valid phone number.')
    .matches(PhoneRegex, 'Please enter a valid phone number.'),
};

export { FormData, InitialValues, ValidationSchemaShape };
