import React from 'react';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';

import { BaseBlok, NxBaseProps } from '../NxBaseProps';
import { StoryBlokColor } from '../StoryBlokColor';

interface BlokProps extends BaseBlok {
  color: StoryBlokColor;
  thickness: number;
}

interface StyleProps {
  color: string;
  thickness: number;
}

const Separator = styled.hr((props: StyleProps) => [
  `border-color: ${props.color};`,
  `border-top-width: ${props.thickness}px;`,
  tw`my-0`,
]);

export default function NxHorizontalSeparator(props: NxBaseProps<BlokProps>): React.ReactNode {
  const { color, thickness } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Separator color={color.color} thickness={thickness} />
    </SbEditable>
  );
}
